<template>
  <Header chose_index="0"></Header>
  <article class="container">
    <div class="left_content">
      <img class="left_content_img" :src="login_scene">
    </div>
    <div class="right_content">
      <div style="text-align: left;">
        <span style="margin-right: 20px;font-size: 24px;color: red;">手机注册</span>
      </div>
      <div style="margin-top: 50px;">
        <div>
          <el-input class="input" placeholder="手机号" v-model="form.phone">
            <template #prefix>
              <i class="el-input__icon el-icon-user"></i>
            </template>
          </el-input>
        </div>
        <div style="margin-top: 30px;">
          <el-input class="input" type="password" placeholder="验证码" v-model="form.captcha">
            <template #prefix>
              <i class="el-input__icon el-icon-picture-outline"></i>
            </template>
            <template #append>
              <span style="cursor: pointer" @click="send_code">{{ send_msg }}</span>
            </template>
          </el-input>
        </div>
        <div style="margin-top: 10px;text-align: left;color: #B6B6B6;font-size: 12px;cursor: pointer"
             @click="go_to_login">
          已注册用户点击这里进行登录
        </div>
        <div style="margin-top: 30px;">
          <el-button type="danger" style="width: 100%" @click="onSubmit">注册</el-button>
        </div>
        <div style="margin-top: 30px;display: flex;height: 40px;">
          <span style="display: inline-block;margin-right: 40px;height: 50px;line-height: 50px;">第三方登录:</span>
          <img :src="qq" style="height: 50px;width: 50px;margin-right: 20px;">
          <img :src="zhifubao" style="height: 50px;width: 50px;margin-right: 20px;">
          <img :src="wechat" style="height: 50px;width: 50px;">
        </div>
      </div>
    </div>
  </article>
  <footer style="background-color: #F8F8F8;width:100%;height:167px;margin-top: 85px;">
    <div style="color: #999999 ;text-align: center;padding-top: 60px;">
      Copyright @ 2011-2021 茗硕择嘉（河北）教育科技有限公司 All Rights Reserved. 版权所有<br><br>
      备案号：冀ICP备17013465号-1
    </div>
  </footer>
</template>

<script>
// @ is an alias to /src
import http from '@/utils/http'
import api from '@/api'
import axios from "axios";
import logo from '@/assets/logo.png';
import login_scene from '@/assets/login_scene.png';
import third_party from '@/assets/third_party.png';
import qq from '@/assets/qq.png';
import zhifubao from '@/assets/zhifubao.png';
import wechat from '@/assets/wechat.png';
import Header from "../components/Header";

export default {
  name: 'Register',
  components: {Header},
  data() {
    return {
      send_msg: '获取验证码',
      logo: logo,
      disabled_click: false,
      time_limit: 60,
      qq: qq,
      zhifubao: zhifubao,
      wechat: wechat,
      login_scene: login_scene,
      third_party: third_party,
      form: {
        phone: '',
        captcha: ''
      }
    }
  },
  mounted() {
  },
  methods: {
    send_code() {
      http.post(api.user.send_code, {
        'phone': this.form.phone,
        'pass': this.form.pass,
        'captcha': this.form.captcha
      }).then(data => {
        console.log(data)
        if (data.code !== 10000) {
          this.$notify.error({
            title: '错误',
            message: data.data.msg,
              offset: 50,
          });
        } else {

        }
      }).catch(err => {
        let data = err.response.data.data
        let str = '';
        for (let i in data) {
          str += data[i].toString() + "; ";
        }
        this.$notify.error({
          title: '错误',
          message: str,
            offset: 50,
        });
      })
    },
    //发送手机验证码倒计时
    timer() {
      if (this.time > 0) {
        this.disabled_click = true;
        this.time--;
        this.btntxt = this.time + "秒";
        setTimeout(this.timer, 1000);
      } else {
        this.time = 0;
        this.btntxt = "发送验证码";
        this.disabled_click = false;
      }
    },
    go_to_login() {
      this.$router.replace('/login')
    },
    sign_out() {
      localStorage.clear()
      this.$router.push('/')
    },
    onSubmit() {
      let that = this
      http.post(api.user.register, {
        'phone': this.form.phone,
        'captcha': this.form.captcha
      }).then(data => {
        console.log(data)
        if (data.code !== 10000) {
          this.$notify.error({
            title: '错误',
            message: data.data.msg,
              offset: 50,
          });
        } else {
          localStorage.setItem('group_id', data.data.group_id)
          localStorage.setItem('group_name', data.data.group_name)
          localStorage.setItem('name', data.data.name)
          localStorage.setItem('jwt', data.data.jwt)
          localStorage.setItem('base_info', JSON.stringify(data.data.base_info))
          localStorage.setItem('branch', JSON.stringify(data.data.branch))
          that.$router.push('/index')
        }
      }).catch(err => {
        let data = err.response.data.data
        let str = '';
        for (let i in data) {
          // str += i + ':' + data[i].toString() + '; ';
          str += data[i].toString() + "; ";
        }
        this.$notify.error({
          title: '错误',
          message: str,
            offset: 50,
        });
      })
    }
  }
}
</script>

<style scoped="scoped">
* {
  font-family: 'Helvetica Neue', Helvetica, 'PingFang SC', 'Hiragino Sans GB',
  'Microsoft YaHei', '微软雅黑', Arial, sans-serif;
  margin: 0;
  padding: 0;
}

.header {
  width: 100%;
  height: 100px;
  text-align: center;
  margin: 0 auto;
  padding: 0;
}

.container {
  background-color: #FFFFFF;
  text-align: center;
  margin: 0 auto;
  width: 64%;
  height: 600px;
}

.left_content {
  float: left;
  width: 55%;
}

.left_content_img {
  width: 100%;
  margin-top: 124px;
}

.right_content {
  float: right;
  width: 40%;
  margin-top: 124px;
}

.header_info {
  text-align: center;
  margin: 0 auto;
  width: 64%;
  display: flex;
  display: -webkit-flex;
  height: 100px;
  line-height: 100px;
}

.div_logo {
  justify-content: flex-start;
  display: flex;
  display: -webkit-flex;
  align-items: center;
}

.img_logo {
  height: 100px;
}

.name_logo {
  height: 100px;
  line-height: 100px;
  margin-left: 20px;
  font-weight: bold;
  font-size: 30px;
}

.div_nav {
  flex: 1;
  justify-content: flex-end;
  display: flex;
  display: -webkit-flex;
  align-items: center;
}

.div_nav_a {
  text-decoration: none;
  cursor: pointer;
}

.div_nav a {
  margin-left: 50px;
}

</style>