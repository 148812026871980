<template>
    <Header chose_index="2" v-if="wechat === false"></Header>
    <article class="container">
        <div style="margin-top: 60px;margin-bottom: 60px;font-family: 'Microsoft YaHei';" v-if="wechat === false">
            我的报告:
            <el-button @click="downloadPDF('#myReport', '我的志愿规划报告')" style="margin-left: 10px;display:none;" size="mini"
                       type="danger">
                下载PDF
            </el-button>
            <el-button @click="download_test" style="margin-left: 10px;" size="mini" type="danger">
                下载PDF
            </el-button>
            <el-button style="margin-left:10px;" @click="button_click" size="mini" type="warning">打印
            </el-button>
        </div>
    </article>
    <article class="container">
        <div id="myReport" style="margin:0 auto;min-width: 1240px;">
            <div
                :class="{'report_content show_red' : show_background === true, 'report_content show_white': show_background === false}"
                style="width:1240px;height:1752px;background-color: #FFFFFF;position:relative;">
                <div
                    style="margin-top: 180px;background-color: #A72505;height:216px;width:810px;border-radius: 0 15px 15px 0 ;">
                    <div style="background-color: #BF2D09;width:96%;height:100%;border-radius: 0 40px 40px 0;">
                        <div
                            style="height:216px;line-height: 216px;font-size:80px;color: white;margin-left: 137px;letter-spacing: 20px;font-weight: bold;">
                            志愿规划报告
                        </div>
                    </div>
                </div>
                <div style="width:100%;margin-top: 150px;">
                    <img alt="" :src="cover_content" style="float: right;"/>
                </div>
                <div :style="{backgroundImage:'url(' + cover_footer + ')',backgroundRepeat:'no-repeat'}"
                     class="cover_footer">
                    <div style="margin-top: 150px;margin-left: 140px;color: white;height: 30px;display: flex;">
						<span
                            style="height:30px;line-height:30px;margin-right: 10px;font-size: 25px;font-weight: bold;">姓名</span>
                        <div
                            style="width: 300px;height:30px;line-height:30px;border-bottom: white 1px solid;display: inline-block;font-size:18px;">
                            &nbsp;&nbsp;&nbsp;&nbsp;{{ name }}
                        </div>
                    </div>
                    <div style="margin-top: 40px;margin-left: 140px;color: white;height: 30px;display: flex;">
						<span
                            style="height:30px;line-height:30px;margin-right: 10px;font-size: 25px;font-weight: bold;">选科</span>
                        <div
                            style="width: 300px;height:30px;line-height:30px;border-bottom: white 1px solid;display: inline-block;font-size:18px;">
                            &nbsp;&nbsp;&nbsp;&nbsp;{{ subjects }}
                        </div>
                    </div>
                    <div style="margin-top: 40px;margin-left: 140px;color: white;height: 30px;display: flex;">
						<span
                            style="height:30px;line-height:30px;margin-right: 10px;font-size: 25px;font-weight: bold;">成绩</span>
                        <div
                            style="width: 300px;height:30px;line-height:30px;border-bottom: white 1px solid;display: inline-block;font-size:18px;">
                            &nbsp;&nbsp;&nbsp;&nbsp;{{ score }}
                        </div>
                    </div>
                    <div class="cover_logo"> <!--:style="{backgroundImage:'url(' + cover_logo + ')',backgroundRepeat:'no-repeat'}"-->
                    </div>
                </div>
            </div>
            <div
                :class="{'report_content show_red' : show_background === true, 'report_content show_white': show_background === false}"
                style="width:1240px;height:1752px;background-color: #FFFFFF;margin-top:100px;position: relative">
                <div class="proposal_left">
                    <span>MINGSHUOZEJIA</span>
                </div>
                <div style="float:right;width:1155px;">
                    <div style="margin-left: 110px;">
                        <div
                            style="margin-top: 72px;margin-left: 780px;font-size: 25px;color: #D9261B;font-weight: bold;display: flex;align-items:center">
                            <div style="border-bottom: #D9261B 1px solid;width:50px;display: inline-block;"></div>
                            <div style="display: inline-block;margin-left: 10px;">我的报告</div>
                        </div>
                        <div style="margin-top: 200px;">
                            <div style="border-bottom: #D9261B 5px solid;width:50px;display: inline-block;"></div>
                        </div>
                        <div style="margin-top: 15px;position: relative">
                            <div style="font-size: 50px;color: #D22B01;font-weight: bold;">
                                总体建议
                            </div>
                            <div
                                style="font-size: 50px;color: #D22B01;font-weight: bold;opacity:0.1;font-style: oblique;position: absolute;top:20px;">
                                SUGGESTIONS
                            </div>
                        </div>
                        <div style="margin-top: 3px;">
                            <div style="border-bottom: #D9261B 5px solid;width:50px;display: inline-block;"></div>
                        </div>
                        <div style="width: 100%;margin-top: 30px;">
                            <div style="margin-right: 200px;border-top: #DCDDDD 2px dashed;padding-top: 30px;">
                                <span style="font-weight: bold">您的高考总分为【{{ score }}】分</span>
                                <br>
                                <div style="text-indent: 20px;line-height: 1.8;margin-top: 20px;">
                                    <div v-html="report.report_suggest"></div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                <div :style="{backgroundImage:'url(' + suggest_footer + ')',backgroundRepeat:'no-repeat'}"
                     class="suggest_footer"></div>
            </div>
            <div
                :class="{'report_content show_red' : show_background === true, 'report_content show_white': show_background === false}"
                style="width:1240px;height:1752px;background-color: #FFFFFF;margin-top:100px;position: relative">
                <div :style="{backgroundImage:'url(' + list_header + ')',backgroundRepeat:'no-repeat'}"
                     class="list_header"></div>
                <div
                    style="margin-top: 72px;margin-left: 980px;font-size: 25px;color: #D9261B;font-weight: bold;display: flex;align-items:center;position: relative">
                    <div style="border-bottom: #D9261B 1px solid;width:50px;display: inline-block;"></div>
                    <div style="display: inline-block;margin-left: 10px;">我的报告</div>
                </div>
                <div
                    style="margin-left: 135px;margin-right:135px;position: relative;z-index: 999;height:1550px;overflow:hidden;">
                    <div style="margin-top: 100px;">
                        <div style="border-bottom: #D9261B 5px solid;width:50px;display: inline-block;"></div>
                    </div>
                    <div style="margin-top: 15px;position: relative">
                        <div style="font-size: 50px;color: #D22B01;font-weight: bold;">风险分析</div>
                        <div
                            style="font-size: 50px;color: #D22B01;font-weight: bold;opacity:0.1;font-style: oblique;position: absolute;top:20px;">
                            VOLUNTEER
                        </div>
                    </div>
                    <div style="margin-top: 3px;">
                        <div style="border-bottom: #D9261B 5px solid;width:50px;display: inline-block;"></div>
                    </div>
                    <!-- content -->
                    <div
                        style="width: 100%;height:400px;line-height:400px;position: relative;display:flex;justify-content: center;align-items: center">
                        <div style="margin: 0 auto;margin-top: 280px;">
                            <img v-if="report.type === 1" style="width:400px;height:400px;" :src="success"/>
                            <img v-else-if="report.type === 2" style="width:400px;height:400px;" :src="error"/>
                            <img v-else style="width:400px;height:400px;" :src="warning"/>
                        </div>
                    </div>
                    <div style="width: 100%;margin-top: 200px;">
                        <div>
                            <div style="font-size: 20px;color:#333333;font-weight: bold;margin-bottom: 5px;">分析说明</div>
                            <div style="padding-top: 3px;line-height: 1.8">
                                <div>
                                    <div v-html="report.warning_desc"></div>
                                </div>
                                <div>
                                    <div v-html="report.risk_analysis"></div>
                                </div>
                                <div>
                                    <div v-html="report.change_rank_desc"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!--                <div style="text-align: center;font-style: italic;font-weight: bold;color: #999999;">- -->
                <!--                    {{ assess_list_length + 1 }} - -->
                <!--                </div>-->
                <div
                    style="height:70px;width:100%;z-index: 10000;position: absolute;left:0;bottom: 0;background: linear-gradient(to right, #DD4510, #D22B01);">
                    <div
                        style="font-weight: bold;color: #f68282;font-size: 20px;height:70px;line-height: 70px;text-align: right;margin-right: 200px;letter-spacing: 30px;">
                        MINGSHUOZEJIA
                    </div>
                </div>
            </div>
            <div
                :class="{'report_content show_red' : show_background === true, 'report_content show_white': show_background === false}"
                v-for="(assesses, index) in assess_list"
                style="width:1240px;height:1752px;background-color: #FFFFFF;margin-top:100px;position: relative">
                <div :style="{backgroundImage:'url(' + list_header + ')',backgroundRepeat:'no-repeat'}"
                     class="list_header"></div>
                <div
                    style="margin-top: 72px;margin-left: 980px;font-size: 25px;color: #D9261B;font-weight: bold;display: flex;align-items:center;position: relative">
                    <div style="border-bottom: #D9261B 1px solid;width:50px;display: inline-block;"></div>
                    <div style="display: inline-block;margin-left: 10px;">我的报告</div>
                </div>
                <div style="margin-left: 135px;position: relative;z-index: 999;height:1550px;overflow:hidden;">
                    <div style="margin-top: 100px;">
                        <div style="border-bottom: #D9261B 5px solid;width:50px;display: inline-block;"></div>
                    </div>
                    <div style="margin-top: 15px;position: relative">
                        <div style="font-size: 50px;color: #D22B01;font-weight: bold;">志愿列表</div>
                        <div
                            style="font-size: 50px;color: #D22B01;font-weight: bold;opacity:0.1;font-style: oblique;position: absolute;top:20px;">
                            VOLUNTEER
                        </div>
                    </div>
                    <div style="margin-top: 3px;">
                        <div style="border-bottom: #D9261B 5px solid;width:50px;display: inline-block;"></div>
                    </div>
                    <div style="width: 100%;">
                        <div class="school_list">
                            <div v-for="item in assesses">
                                <div style="height:20px;" id="mountedid">
                                    {{ item.plan_rank }}
                                </div>
                                <div style="margin-left:50px;" @click="getDataId()">
                                    <el-descriptions class="margin-top" :column="2" size="default">
                                        <el-descriptions-item min-width="550px" align="left" label="学校名称">
                                            {{ item.school_name }} [ {{ item.province_name }} ]
                                            <span v-if="item.plan_rank !== item.plan_score_rank"
                                                  style="color: red">推荐顺序:{{ item.plan_score_rank }}</span>
                                            <span v-if="item.school_code !== undefined && item.school_code.length > 0"
                                                  style="color: #4D94F1">院校代号:{{ item.school_code }}</span>
                                        </el-descriptions-item>
                                        <el-descriptions-item min-width="450px" align="left" label="专业名称">
                                            {{ item.majors_name }}
                                            <span v-if="item.fees !== undefined && item.fees !== ''">
                                                <span v-if="item.fees > 10000" style="color: #D9261B;">[学费:{{
                                                        item.fees
                                                    }}]</span>
                                                <span v-else>[学费:{{ item.fees }}]</span>
                                            </span>
                                            <span style="color: #D9261B;" v-if="item.type_name !== ''">
                                                [{{ item.type_name }}]
                                            </span>
                                            <span v-if="item.major_num !== undefined && item.major_num.length > 0"
                                                  style="color: #4D94F1">
                                                专业代号:{{ item.major_num }}
                                            </span>
                                        </el-descriptions-item>
                                        <el-descriptions-item min-width="550px" align="left" label="学校标签">
                                            <el-tag size="mini" type="success" style="margin-left: 5px;"
                                                    v-for="school_label in item.school_label">
                                                {{ school_label }}
                                            </el-tag>
                                            <el-tag size="mini" type="success" style="margin-left: 5px;">
                                                {{ item.nature_str }}
                                            </el-tag>
                                        </el-descriptions-item>
                                        <el-descriptions-item min-width="450px" align="left" label="专业标签">
                                            <el-tag size="mini" type="success" style="margin-left: 5px;"
                                                    v-for="major_label in item.major_label">
                                                {{ major_label }}
                                            </el-tag>
                                        </el-descriptions-item>
                                    </el-descriptions>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div style="text-align: center;font-style: italic;font-weight: bold;color: #999999;">
                    - {{ index + 1 }} -
                </div>
                <div
                    style="height:70px;width:100%;z-index: 888;position: absolute;left:0;bottom: 0;background: linear-gradient(to right, #DD4510, #D22B01);">
                    <div
                        style="font-weight: bold;color: #f68282;font-size: 20px;height:70px;line-height: 70px;text-align: right;margin-right: 200px;letter-spacing: 30px;">
                        MINGSHUOZEJIA
                    </div>
                </div>
            </div>
            <div
                :class="{'report_content show_red' : show_background === true, 'report_content show_white': show_background === false}"
                v-for="(school_list, list_index) in page_list"
                style="width:1240px;height:1752px;background-color: #FFFFFF;margin-top:100px;position: relative">
                <div :style="{backgroundImage:'url(' + list_header + ')',backgroundRepeat:'no-repeat'}"
                     class="list_header"></div>
                <div
                    style="margin-top: 72px;margin-left: 980px;font-size: 25px;color: #D9261B;font-weight: bold;display: flex;align-items:center;position: relative">
                    <div style="border-bottom: #D9261B 1px solid;width:50px;display: inline-block;"></div>
                    <div style="display: inline-block;margin-left: 10px;">我的报告</div>
                </div>
                <div style="margin-left: 135px;position: relative;z-index: 999;height:1640px;overflow:hidden;">
                    <div style="margin-top: 100px;">
                        <div style="border-bottom: #D9261B 5px solid;width:50px;display: inline-block;"></div>
                    </div>
                    <div style="margin-top: 15px;position: relative">
                        <div style="font-size: 50px;color: #D22B01;font-weight: bold;">志愿条目分析</div>
                        <div
                            style="font-size: 50px;color: #D22B01;font-weight: bold;opacity:0.1;font-style: oblique;position: absolute;top:20px;">
                            VOLUNTEER
                        </div>
                    </div>
                    <div style="margin-top: 3px;">
                        <div style="border-bottom: #D9261B 5px solid;width:50px;display: inline-block;"></div>
                    </div>
                    <!-- content -->
                    <div style="width: 100%;" v-for="item in school_list">
                        <div style="width: 100%;margin-top: 30px;">
                            <div
                                style="margin-right: 135px;border-top: #DCDDDD 2px dashed;padding-top: 30px;display: flex;">
                                <div style="width:200px;">
                                    <div style="width:200px;font-size: 25px;font-weight: bold;color: #D22B01;">
                                        {{ item['info']['school_name'] }}
                                    </div>
                                    <div
                                        style="width:200px;margin-top:25px;font-size: 20px;font-weight: bold;color: #231815;">
                                        {{ item['info']['majors_name'] }}
                                    </div>
                                    <div
                                        style="width:200px;margin-top:25px;font-size: 32px;font-weight: bold;color: #999999;font-style: italic">
                                        {{ item['info']['plan_rank'] }}
                                    </div>
                                    <div v-if="item['info']['school_name'].length > 0"
                                         style="width:190px;margin-top:25px;border-bottom: #DCDDDD 2px solid;">
                                    </div>
                                </div>
                                <div style="min-width:750px;margin-left: 20px;" v-if="item.list !== undefined">
                                    <h4 style="margin-bottom: 5px;" v-if="item.list.school_content !== undefined">
                                        院校分析
                                    </h4>
                                    <div v-if="item.list.school_content !== undefined" style="line-height: 1.8;">
                                        <div v-for="content in item.list.school_content" style="line-height: 1.8;">
                                            <div v-html="content"></div>
                                        </div>
                                    </div>

                                    <h4 style="margin-bottom: 5px;" v-if="item.list.major_content !== undefined">
                                        专业特征分析
                                    </h4>
                                    <div v-if="item.list.major_content !== undefined">
                                        <div v-for="content in item.list.major_content" style="line-height: 1.8;">
                                            <div v-html="content"></div>
                                        </div>
                                    </div>

                                    <h4 style="margin-bottom: 5px;"
                                        v-if="item.list.job_orientation !== undefined && item.list.job_orientation.length> 0">
                                        就业方向
                                    </h4>
                                    <div
                                        v-if="item.list.job_orientation !== undefined && item.list.job_orientation.length> 0">
                                        <div v-for="content in item.list.job_orientation" style="line-height: 1.8;">
                                            <div v-html="content"></div>
                                        </div>
                                    </div>

                                    <h4 v-if="item.list.subject_development_content !== undefined"
                                        style="margin-bottom: 5px;">
                                        专业实力分析
                                    </h4>
                                    <div v-if="item.list.subject_development_content !== undefined">
                                        <div v-for="content in item.list.subject_development_content"
                                             style="line-height: 1.8;">
                                            <div v-html="content"></div>
                                        </div>
                                    </div>

                                    <h4 style="margin-bottom: 5px;"
                                        v-if="item.list.major_analysis_content !== undefined">
                                        专业适应性分析
                                    </h4>
                                    <div v-if="item.list.major_analysis_content !== undefined">
                                        <div v-for="content in item.list.major_analysis_content"
                                             style="line-height: 1.8;">
                                            <div v-html="content"></div>
                                        </div>
                                    </div>

                                    <h4 v-if="item.list.data_analysis_content != undefined" style="margin-bottom: 5px;">
                                        数据分析
                                    </h4>
                                    <div v-if="item.list.data_analysis_content != undefined" style="line-height: 1.8;">
                                        <div v-for="content in item.list.data_analysis_content"
                                             style="line-height: 1.8;">
                                            <div v-html="content"></div>
                                        </div>
                                    </div>

                                    <h4 v-if="item.list.postgraduate_content !== undefined" style="margin-bottom: 5px;">
                                        考研推荐
                                    </h4>
                                    <div v-if="item.list.postgraduate_content !== undefined">
                                        <div v-for="content in item.list.postgraduate_content"
                                             style="line-height: 1.8;">
                                            <div v-html="content"></div>
                                        </div>
                                    </div>

                                    <h4 v-if="item.list.tips_content !== undefined"
                                        style="margin-bottom: 5px;color: #D22B01;">
                                        特别提醒
                                    </h4>
                                    <div v-if="item.list.tips_content !== undefined" style="line-height: 1.8;">
                                        <div v-for="content in item.list.tips_content" style="line-height: 1.8;">
                                            <div v-html="content"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div style="clear: both;text-align: center"></div>
                    </div>
                </div>
                <div
                    style="text-align: center;font-style: italic;font-weight: bold;color: #999999;width:100px;margin:0 auto;position: relative;top:-90px;">
                    - {{ assess_list_length + list_index + 1 }} -
                </div>
                <div
                    style="height:70px;width:100%;z-index: 1;position: absolute;left:0;bottom: 0;background: linear-gradient(to right, #DD4510, #D22B01);">
                    <div
                        style="font-weight: bold;color: #f68282;font-size: 20px;height:70px;line-height: 70px;text-align: right;margin-right: 200px;letter-spacing: 30px;">
                        MINGSHUOZEJIA
                    </div>
                </div>
            </div>
            <div
                :class="{'report_content show_red' : show_background === true, 'report_content show_white': show_background === false}"
                style="width:1240px;height:1752px;background-color: #FFFFFF;margin-top:100px;position: relative">
                <div class="proposal_left">
                    <span>MINGSHUOZEJIA</span>
                </div>
                <div style="float:right;width:1155px;">
                    <div style="margin-left: 110px;">
                        <div
                            style="margin-top: 72px;margin-left: 780px;font-size: 25px;color: #D9261B;font-weight: bold;display: flex;align-items:center">
                            <div style="border-bottom: #D9261B 1px solid;width:50px;display: inline-block;"></div>
                            <div style="display: inline-block;margin-left: 10px;">我的报告</div>
                        </div>
                        <div style="margin-top: 200px;">
                            <div style="border-bottom: #D9261B 5px solid;width:50px;display: inline-block;"></div>
                        </div>
                        <div style="margin-top: 15px;position: relative">
                            <div style="font-size: 50px;color: #D22B01;font-weight: bold;">
                                报告说明
                            </div>
                            <div
                                style="font-size: 50px;color: #D22B01;font-weight: bold;opacity:0.1;font-style: oblique;position: absolute;top:20px;">
                                REPORT DESCRIPTION
                            </div>
                        </div>
                        <div style="margin-top: 3px;">
                            <div style="border-bottom: #D9261B 5px solid;width:50px;display: inline-block;"></div>
                        </div>
                        <div style="width: 100%;margin-top: 30px;">
                            <div style="margin-right: 200px;border-top: #DCDDDD 2px dashed;padding-top: 30px;">
                                <div style="text-indent: 20px;line-height: 1.8;margin-top: 20px;">
                                    1、本系统的风险分析功能具有普适性，具体请结合考生实际！
                                </div>
                                <div style="text-indent: 20px;line-height: 1.8;">
                                    2、本系统的硕士点、博士点数据来自“中国研究生招生信息网”，学科评估数据来自“学位与研究生教育网”，一流学科数据来自教育部官网。在数据整合的基础上，我的报告专家组通过查阅并研究《普通高等学校本科专业类教学质量国家标准》和《一级学科硕士
                                    博士基本要求》实现了本科与研究生专业之间的关联关系。
                                </div>
                                <div style="text-indent: 20px;line-height: 1.8;">
                                    3、志愿列表中的院校代码和专业代码仅供参考，具体报考时请以河北省教育考试院数据为准。
                                </div>
                                <div style="text-indent: 20px;line-height: 1.8;">
                                    4、我的报告的志愿推荐排序是考虑了院校层次、院校性质、院校所在地域、学科评估、硕博点建设情况、冷热门、校内优劣势等因素的综合评价排序，考生可根据个人实际需求修改推荐排序，请确保修改后的排序是您的真实意愿表达。
                                </div>
                                <div style="text-indent: 20px;line-height: 1.8;">
                                    5、本系统提供的是志愿填报辅助服务，具体填报务必在河北省教育考试院指定的系统内完成填报。
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                <div :style="{backgroundImage:'url(' + suggest_footer + ')',backgroundRepeat:'no-repeat'}"
                     class="suggest_footer"></div>
            </div>
        </div>
    </article>
    <el-dialog v-model="dialogVisible" title="下载进度" width="30%" :before-close="handleClose">
        <el-progress :text-inside="true" :stroke-width="20" :percentage="progress_num" status="success">
            <span>{{ progress_str }}</span>
        </el-progress>
        <template #footer></template>
    </el-dialog>
    <el-backtop/>
    <footer style="background-color: #F8F8F8;width:100%;height:167px;margin-top: 75px;" v-if="wechat === false">
        <div style="color: #999999 ;text-align: center;padding-top: 60px;width:1240px;margin: 0 auto;">
            Copyright @ 2011-2021 我的报告（河北）教育科技有限公司 All Rights Reserved. 版权所有<br><br>
            备案号：冀ICP备17013465号-1
        </div>
    </footer>
    <el-dialog
        v-model="show_help"
        title="操作提醒"
        top="300"
        width="1100px"
        :before-close="handleClose"
    >
        <img src="https://oss.shudaozaixian.com/images/dayintixing.jpg" width="1000"/>
        <template #footer>
      <span class="dialog-footer">
        <el-button @click="show_help = false">取消</el-button>
        <el-button v-print="print" type="primary" @click="show_help = false">开始打印报告</el-button>
      </span>
        </template>
    </el-dialog>
</template>

<script>
import api from '@/api'
import axios from 'axios'
import Header from '@/components/Header.vue'
import html2canvas from 'html2canvas';
import JsPDF from 'jspdf';
import COVER_CONTENT from '@/assets/report/cover_content.png';
import COVER_LOGO from '@/assets/report/cover_logo.png';
import COVER_FOOTER from '@/assets/report/cover_footer.png';
import SUGGEST_FOOTER from '@/assets/report/suggest_footer.png';
import LIST_HEADER from '@/assets/report/list_header.png';
import SUCCESS from '@/assets/report/success.png';
import ERROR from '@/assets/report/error.png';
import WARNING from '@/assets/report/warning.png';
import print from 'vue3-print-nb'
import {
    ElNotification
} from "element-plus";

export default {
    name: 'Report',
    components: {
        Header
    },
    data() {
        return {
            id: 0,
            show_help: false,
            dialogVisible: false,
            progress_str: '',
            progress_num: 0,
            show_background: true,
            cover_content: COVER_CONTENT,
            cover_logo: COVER_LOGO,
            cover_footer: COVER_FOOTER,
            suggest_footer: SUGGEST_FOOTER,
            success: SUCCESS,
            error: ERROR,
            warning: WARNING,
            list_header: LIST_HEADER,
            report: {
                report_suggest: '',
                summary: '',
                risk_analysis: '',
                type: 0
            },
            assess: [],
            assess_list: [],
            assess_list_length: 0,
            content_list: [],
            base_info: [],
            page_list: [],
            print: {
                id: 'myReport',
                popTitle: '我的志愿规划报告', // 打印配置页上方标题
                extraHead: '', //最上方的头部文字，附加在head标签上的额外标签,使用逗号分隔
                preview: false, // 是否启动预览模式，默认是false（开启预览模式，可以先预览后打印）
                previewTitle: '预览', // 打印预览的标题（开启预览模式后出现）,
                previewPrintBtnLabel: '打印 我的志愿规划报告', // 打印预览的标题的下方按钮文本，点击可进入打印（开启预览模式后出现）
                zIndex: '', // 预览的窗口的z-index，默认是 20002（此值要高一些，这涉及到预览模式是否显示在最上面）
                previewBeforeOpenCallback() {
                    console.log('previewBeforeOpenCallback')
                }, //预览窗口打开之前的callback（开启预览模式调用）
                previewOpenCallback() {
                    console.log('previewOpenCallback')
                }, // 预览窗口打开之后的callback（开启预览模式调用）
                beforeOpenCallback: (e) => {
                    this.show_background = false
                    console.log('beforeOpenCallback')
                }, // 开启打印前的回调事件
                openCallback() {
                    console.log('openCallback')
                }, // 调用打印之后的回调事件
                closeCallback: (e) => {
                    this.show_background = true
                    console.log('closeCallback')
                }, //关闭打印的回调事件（无法确定点击的是确认还是取消）
                url: '',
                standard: '',
                extraCss: '',
            },
            list: [],
            list_num: 0,
            canvas: null,
            pdf: null,
            time: '',
            wechat: false,
            subjects: '',
            name: '',
            score: '',
        }
    },
    created() {
        this.id = this.$route.query.id
        this.time = this.$route.query.time || ''

        let ua = navigator.userAgent.toLowerCase();
        if (ua.match(/MicroMessenger/i) == "micromessenger") {
            this.wechat = true
        }

        let base_info = localStorage.getItem('base_info')
        if (base_info == null) {
            if (this.time !== '') {
                console.log('--------------')
                this.user_info()
            } else {
                this.base_info = {
                    subjects: [],
                    name: '',
                    score: 0
                }
            }
        } else {
            this.base_info = JSON.parse(base_info)
            this.get_report_demo()
        }
    },
    methods: {
        getDataId(id) {
            let a = document.getElementById("mountedid").innerText
            console.log("-----------------------", a);
            console.log(this.assess_list_length)
        },
        handleClose() {
        },
        button_click(e) {
            this.show_help = true
            this.show_background = false
        },
        download_test() {
            this.show_background = false
            this.dialogVisible = true
            this.list = document.querySelectorAll('.report_content')
            this.list_num = this.list.length
            this.canvas = document.createElement("canvas");
            this.pdf = new JsPDF('', 'pt', 'a4');
            this.pdf.page = 1

            let win_in = document.documentElement.clientWidth || document.body.clientWidth; // 获得当前可视窗口的宽度（不包含滚动条）
            let win_out = window.innerWidth; // 获得当前窗口的宽度（包含滚动条）
            let abs = win_out > win_in ? ((win_out - win_in) / 2) : 0;

            this.transformPic(0, abs)
        },
        transformPic(i, abs) {
            let that = this

            let page = parseInt(i) + 1
            that.progress_str = '第' + page + '页';
            that.progress_num = Math.ceil((page / that.list_num) * 100)

            if (this.list[i] === undefined) {
                that.show_background = true
                this.dialogVisible = false
                that.pdf.save('我的志愿规划报告');
                return false
            }

            let eleW = this.list[i].offsetWidth; // 获得该容器的宽
            let eleH = this.list[i].offsetHeight; // 获得该容器的高

            let eleOffsetTop = this.list[i].offsetTop; // 获得该容器到文档顶部的距离
            let eleOffsetLeft = this.list[i].offsetLeft; // 获得该容器到文档最左的距离

            this.canvas.width = eleW; // 将画布宽&&高放大两倍
            this.canvas.height = eleH;

            let context = this.canvas.getContext("2d");
            context.scale(1, 1);
            context.translate(-eleOffsetLeft - abs, -eleOffsetTop);

            html2canvas(this.list[i], {
                dpi: 300,
                useCORS: true
            }).then((canvas) => {
                console.log('正在转化第 ' + page + ' 页')

                let imgWidth = 595.28;
                let imgHeight = 595.28 / canvas.width * canvas.height;
                let pageData = canvas.toDataURL('image/jpeg', 1.0);

                that.pdf.addImage(pageData, 'JPEG', 0, 0, imgWidth, imgHeight);
                that.pdf.addPage();

                that.transformPic(page, abs)
            }).catch((res) => {
                console.log(res)
                that.show_background = true
                this.dialogVisible = false
                ElNotification({
                    title: '下载失败',
                    message: '下载PDF失败或页数不全，请稍后再试',
                    duration: 0,
                    offset: 150,
                })
                that.pdf.save('我的志愿规划报告');
            })

        },
        downloadPDF(ele, pdfName) {
            ele = document.querySelector(ele)
            let eleW = ele.offsetWidth; // 获得该容器的宽
            let eleH = ele.offsetHeight; // 获得该容器的高
            let eleOffsetTop = ele.offsetTop; // 获得该容器到文档顶部的距离
            let eleOffsetLeft = ele.offsetLeft; // 获得该容器到文档最左的距离

            let canvas = document.createElement("canvas");
            let abs = 0;

            let win_in = document.documentElement.clientWidth || document.body.clientWidth; // 获得当前可视窗口的宽度（不包含滚动条）
            let win_out = window.innerWidth; // 获得当前窗口的宽度（包含滚动条）

            if (win_out > win_in) {
                // abs = (win_o - win_i)/2;    // 获得滚动条长度的一半
                abs = (win_out - win_in) / 2; // 获得滚动条宽度的一半
                // console.log(a, '新abs');
            }
            canvas.width = eleW * 2; // 将画布宽&&高放大两倍
            canvas.height = eleH * 2;

            let context = canvas.getContext("2d");
            context.scale(2, 2);
            context.translate(-eleOffsetLeft - abs, -eleOffsetTop);
            // 这里默认横向没有滚动条的情况，因为offset.left(),有无滚动条的时候存在差值，因此
            // translate的时候，要把这个差值去掉

            // html2canvas(element).then( (canvas)=>{ //报错
            // html2canvas(element[0]).then( (canvas)=>{
            html2canvas(ele, {
                dpi: 300,
                // allowTaint: true,  //允许 canvas 污染， allowTaint参数要去掉，否则是无法通过toDataURL导出canvas数据的
                useCORS: true //允许canvas画布内 可以跨域请求外部链接图片, 允许跨域请求。
            }).then((canvas) => {
                let contentWidth = canvas.width;
                let contentHeight = canvas.height;
                //一页pdf显示html页面生成的canvas高度;
                let pageHeight = contentWidth / 592.28 * 842;
                //未生成pdf的html页面高度
                let leftHeight = contentHeight;
                //页面偏移
                let position = 0;
                //a4纸的尺寸[595.28,841.89]，html页面生成的canvas在pdf中图片的宽高
                let imgWidth = 595.28;
                let imgHeight = 595.28 / contentWidth * contentHeight;
                let pageData = canvas.toDataURL('image/jpeg', 1.0);
                let pdf = new JsPDF('', 'pt', 'a4');
                pdf.page = 1;
                //有两个高度需要区分，一个是html页面的实际高度，和生成pdf的页面高度(841.89)
                //当内容未超过pdf一页显示的范围，无需分页
                if (leftHeight < pageHeight) {
                    //在pdf.addImage(pageData, 'JPEG', 左，上，宽度，高度)设置在pdf中显示；
                    pdf.addImage(pageData, 'JPEG', 0, 0, imgWidth, imgHeight);
                    // pdf.addImage(pageData, 'JPEG', 20, 40, imgWidth, imgHeight);
                } else { // 分页
                    while (leftHeight > 0) {
                        pdf.addImage(pageData, 'JPEG', 0, position, imgWidth, imgHeight);
                        leftHeight -= pageHeight;
                        position -= 842;
                        //避免添加空白页
                        if (leftHeight > 0) {
                            pdf.addPage();
                        }
                    }
                }
                //可动态生成
                pdf.save(pdfName);

            })
        },
        user_info() {
            let that = this
            axios({
                url: api.user.info,
                method: "post",
                data: {},
                headers: {Authorization: this.time},
                responseType: "json", // 表明返回服务器返回的数据类型
            }).then(function (res) {
                console.log(res)
                that.base_info = res.data.data.base_info
                that.get_report_demo()
            }).catch(function (err) {
                that.$notify.error({
                    title: '错误',
                    message: err.response.data.msg,
                    offset: 50,
                });
            });
        },
        get_json_data(json_url) {
            let that = this
            if (json_url.indexOf('json') === -1) {
                return false
            }
            let url = 'https://oss.shudaozaixian.com/' + json_url
            // let url = 'https://zhangjiakou-info.oss-cn-zhangjiakou.aliyuncs.com/' + json_url

            axios.get(
                url, {params: {}, headers: {}}
            ).then(function (res) {

                that.report = res.data.report
                that.assess = res.data.assess
                that.page_list = res.data.page_list
                that.subjects = res.data.subjects
                that.name = res.data.name
                that.score = res.data.score
                for (let i in that.assess) {
                    // if(i>95){
                    // 	console.log('------------',i);
                    // 	let obj = {

                    // 	}
                    // 	that.assess_list[index].push(obj);
                    // }
                    let index = Math.floor(i / 15)
                    if (that.assess_list[index] === undefined) {
                        that.assess_list[index] = [];
                    }
                    let obj = {
                        plan_rank: that.assess[i]['plan_rank'],
                        school_name: that.assess[i]['school_name'],
                        majors_name: that.assess[i]['majors_name'],
                        forecast_low_score: that.assess[i]['forecast_low_score'],
                        plan_score_rank: that.assess[i]['plan_score_rank'],
                        school_label: that.assess[i]['school_label'],
                        major_label: that.assess[i]['major_label'],
                        school_code: that.assess[i]['school_code'] || '',
                        major_num: that.assess[i]['major_num'] || '',
                        type_name: that.assess[i]['type_name'] || '',
                        province_name: that.assess[i]['province_name'],
                        fees: that.assess[i]['fees'] || '',
                        nature_str: that.assess[i]['nature_str'] || '',
                        plan_user_score_rank: that.assess[i]['plan_user_score_rank'],
                    }
                    that.assess_list[index].push(obj);
                }
                that.assess_list_length = that.assess_list.length
            }).catch(function (err) {
                that.$notify.error({
                    title: '错误',
                    message: err.response.data.msg,
                    offset: 50,
                });
            });
        },
        get_report_demo() {
            let that = this
            let jwt = ''
            if (this.time !== '') {
                jwt = that.time
            } else {
                jwt = localStorage.getItem("jwt")
            }
            axios.get(
                api.user.report_info, {
                    params: {
                        id: that.id,
                    },
                    headers: {
                        Authorization: jwt
                    }
                }
            ).then(function (res) {
                that.get_json_data(res.data.data.json_url)
            }).catch(function (err) {
                that.$notify.error({
                    title: '错误',
                    message: err.response.data.msg,
                    offset: 50,
                });
            });
        },
        get_report() {
            let that = this
            axios.get(
                'https://ceshi.shudaozaixian.com/website/v1/' + api.user.get_report, {
                    params: {
                        id: that.id,
                        time: that.time
                    }
                }
            ).then(function (res) {
                // console.log("成功获取到数据",res)
                that.report = res.data.data.report
                that.assess = res.data.data.assess
                that.page_list = res.data.data.page_list
                that.subjects = res.data.data.subjects
                that.name = res.data.data.name
                that.score = res.data.data.score

                for (let i in that.assess) {
                    // if(i>95){
                    // 	console.log('------------',i);
                    // 	let obj = {

                    // 	}
                    // 	that.assess_list[index].push(obj);
                    // }
                    let index = Math.floor(i / 15)
                    if (that.assess_list[index] === undefined) {
                        that.assess_list[index] = [];
                    }
                    let obj = {
                        plan_rank: that.assess[i]['plan_rank'],
                        school_name: that.assess[i]['school_name'],
                        majors_name: that.assess[i]['majors_name'],
                        forecast_low_score: that.assess[i]['forecast_low_score'],
                        plan_score_rank: that.assess[i]['plan_score_rank'],
                        school_label: that.assess[i]['school_label'],
                        major_label: that.assess[i]['major_label'],
                        province_name: that.assess[i]['province_name'],
                        plan_user_score_rank: that.assess[i]['plan_user_score_rank'],
                    }
                    that.assess_list[index].push(obj);
                }
                that.assess_list_length = that.assess_list.length
            }).catch(function (err) {
                that.$notify.error({
                    title: '错误',
                    message: err.response.data.msg
                });
            });
        },
    }
}
</script>
<style scoped>
@page {
    size: auto;
    margin: 0;
}

* {
    font-family: 'Microsoft YaHei', 'Helvetica Neue', Helvetica, 'PingFang SC', 'Hiragino Sans GB',
    '微软雅黑', Arial, sans-serif;
}

p {
    margin: 0;
    margin-block-start: 0;
    margin-block-end: 0;
}

.container {
    background-color: #FFFFFF;
    width: 1240px;
    margin: 70px auto 0;
}

.div_nav a {
    margin-left: 50px;
}

.el-card >>> .el-card__header {
    padding: 0 20px;
}

.cover_logo {
    position: absolute;
    right: 95px;
    bottom: 100px;
    height: 55px;
    width: 260px;
}

.cover_footer {
    width: 100%;
    position: absolute;
    bottom: 0;
    padding: 0;
    height: 440px;
}

.proposal_left {
    float: left;
    width: 85px;
    background: linear-gradient(#DC5D00, #D22B01);
    height: 100%;
    text-align: center;
}

.proposal_left span {
    margin-top: 1000px;
    writing-mode: vertical-rl;
    font-size: 25px;
    font-weight: bold;
    color: white;
    letter-spacing: 35px;
}

.suggest_footer {
    height: 529px;
    width: 915px;
    position: absolute;
    right: 0;
    bottom: 0;
}

.list_header {
    height: 258px;
    width: 841px;
    position: absolute;
    right: 0;
    top: 0;
}

.show_red {
    border: red 1px solid;
}

.show_white {
    border: white 1px solid;
}

.school_list {
    margin-right: 135px;
    height: 90px;
    line-height: 90px;
    z-index: 999;
}
</style>
