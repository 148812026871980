<template>
    <footer class="footer">
        <div class="copy_info">
            Copyright @ 2011-2022 茗硕择嘉（河北）教育科技有限公司 All Rights Reserved. 版权所有<br><br>
            备案号：冀ICP备17013465号-1
        </div>
    </footer>
</template>

<script>

export default {
    name: 'Footer',
    props: {},
    data() {
        return {}
    },
}
</script>

<style scoped>
* {
    font-family: 'Liberation Sans', 'Helvetica Neue', Helvetica, 'PingFang SC', 'Hiragino Sans GB',
    'Microsoft YaHei', '微软雅黑', Arial, sans-serif;
}

.footer {
    background-color: #F8F8F8;
    width: 100%;
    height: 167px;
    margin-top: 75px;
}

.footer .copy_info {
    color: #999999;
    text-align: center;
    padding-top: 60px;
}
</style>
