<template>
    <Header chose_index="1"></Header>
    <article class="container">
        <div
            style="text-align: center;width: 100%;font-size: 30px;color: #333333;margin-top: 90px;font-family: 'Microsoft YaHei';font-weight: bold">
            2022年河北高考志愿辅助填报
        </div>
        <div style="margin: 40px auto 0;width: 70%;">
            <el-steps :active="active">
                <el-step title="录入信息"></el-step>
                <el-step title="志愿填报"></el-step>
                <el-step title="生成报表"></el-step>
            </el-steps>
        </div>
        <div style="margin-top: 60px;">
            我的成绩: {{ base_info.score }}分 {{ base_info.precedence }}位次 {{ base_info.subjects }}
            <span v-if="vip_level >= 60 && modify_score_times > 0" @click="edit_info_click" style="cursor:pointer;margin-left: 10px;"><i class="el-icon-edit"></i></span>
        </div>
        <div style="margin-top: 50px;">
            <div class="zhi_img" :style="{backgroundImage:'url(' + zhi + ')',backgroundRepeat:'no-repeat'}">
                <div style="margin-top: 50px;margin-left: 50px;">
                    <div style="color: #FFFFFF;font-size: 24px;">{{ high_branch.name }}-批次</div>
                    <div style="margin-top: 26px;color: #FFFFFF;font-size: 24px;">
                        <span>科类 | {{ high_branch.class_name }}</span><span
                        style="margin-left: 55px;">批次线 | {{ high_branch.score_line }}分</span></div>
                    <div style="margin-top: 26px;">
                        <el-button @click="chose_major(1)"><span style="color: #C23B1E;font-size: 16px;">智能填报</span>
                        </el-button>
                    </div>
                </div>
            </div>
            <div class="yuan_img" :style="{backgroundImage:'url(' + yuan + ')',backgroundRepeat:'no-repeat'}">
                <div style="margin-top: 50px;margin-left: 50px;">
                    <div style="color: #FFFFFF;font-size: 24px;">{{ low_branch.name }}-批次</div>
                    <div style="margin-top: 26px;color: #FFFFFF;font-size: 24px;">
                        <span>科类 | {{ low_branch.class_name }}</span><span
                        style="margin-left: 55px;">批次线 | {{ low_branch.score_line }}分</span></div>
                    <div style="margin-top: 26px;">
                        <el-button @click="chose_major(2)"><span style="color: #4D94F1;font-size: 16px;">智能填报</span>
                        </el-button>
                    </div>
                </div>
            </div>
        </div>
        <div style="clear:both;"></div>
        <div style="margin-top: 70px;">
            <div style="color: #333333;font-size: 14px;font-family: 'Microsoft YaHei';">
                填报须知：<br><br>
            </div>
            <div style="color: #999999;font-size: 14px;font-family: 'Microsoft YaHei';">
                1、本系统依据历年院校录取数据提供高考志愿填报辅助功能，正式填报请登录省考试院指定网站；<br>
                2、2022年招生计划已更新（正式招生计划以2022年6月考试院发布为准）；<br>
                3、2019-2021年各院校录取数据，参考各省市出版的填报指南以及各本专科院校的官网历年录取数据；<br>
                4、您正在使用的是河北版，提供“普通类本科批”的志愿智能模拟功能；<br>
                5、在正式填报时，以教育考试院公布的最新招生计划为准；<br>

            </div>
        </div>
    </article>
    <footer style="background-color: #F8F8F8;width:100%;height:167px;margin-top: 75px;">
        <div style="color: #999999 ;text-align: center;padding-top: 60px;">
            Copyright @ 2011-2022 茗硕择嘉（河北）教育科技有限公司 All Rights Reserved. 版权所有<br><br>
            备案号：冀ICP备17013465号-1
        </div>
    </footer>
    <EditInfo :show="show_edit_info" @fatherMethod="fatherMethod"></EditInfo>
</template>

<script>
import api from '@/api'
import axios from 'axios'
import Header from '@/components/Header.vue'
import EditInfo from "../components/EditInfo";
import logo from '@/assets/logo.png';
import zhi from '@/assets/zhi.png';
import yuan from '@/assets/yuan.png';

export default {
    name: 'Index',
    components: {EditInfo, Header},
    data() {
        return {
            logo: logo,
            zhi: zhi,
            yuan: yuan,
            show_edit_info: 0,
            modify_score_times: 0,
            vip_level: 0,
            active: 1,
            edit_info: false,
            majors: [
                {'name': '物理', 'index': '1'},
                {'name': '历史', 'index': '2'},
                {'name': '生物', 'index': '3'},
                {'name': '化学', 'index': '4'},
                {'name': '地理', 'index': '5'},
                {'name': '思想政治', 'index': '6'},
            ],
            branch: [{
                'name': '',
                'class_name': '',
                'score_line': '',
                'level': ''
            }],
            high_branch: {class_name: '', level: 0, name: '', score_line: 0},
            low_branch: {class_name: '', level: 0, name: '', score_line: 0},
            base_info: {
                'class_id': 0,
                'score': 0,
                'name': '',
                'province': '0',
                'province_id': '0',
                'subject_one': 0,
                'subject_three': 0,
                'subject_two': 0,
                'subjects': "",
                'majors': [],
            },
            form: {
                name: '',
                province_id: '0',
                majors: [],
                score: 0,
            },
        }
    },
    created() {
        this.branch = JSON.parse(localStorage.getItem('branch'))
        this.base_info = JSON.parse(localStorage.getItem('base_info'))
        if (this.base_info.score.length === 0 || parseInt(this.base_info.score) <= 0) {
            this.active = 1
        } else {
            this.active = 2
        }
        for (let i in this.branch) {
            if (this.branch[i]['name'] === '本科') {
                this.high_branch = this.branch[i]
            }
            if (this.branch[i]['name'] === '专科') {
                this.low_branch = this.branch[i]
            }
        }
        console.log(this.active)
        console.log(this.branch)
        this.user_info()
    },
    methods: {
        fatherMethod() {
            this.user_info()
            console.log('-----+++++---')
        },
        edit_info_click() {
            this.show_edit_info++
        },
        sign_out() {
            localStorage.clear()
            this.$router.push('/')
        },
        step_one_click(e) {
            this.show_edit_info++
        },
        input_info() {
            console.log(this.form)
            let that = this
            axios.post(
                api.user.input, this.form
            ).then(function (res) {
                console.log(res)
                if (res.data.data.code === 10000) {
                    localStorage.setItem('base_info', JSON.stringify(res.data.data.base_info))
                    localStorage.setItem('branch', JSON.stringify(res.data.data.branch))
                    that.branch = res.data.data.branch
                    that.base_info = res.data.data.base_info
                    that.active = 2
                    that.$notify({
                        title: '成功',
                        message: '信息录入成功',
                        type: 'success',
                        offset: 50,
                    });
                } else {
                    that.$notify.error({
                        title: '错误',
                        message: res.data.data.msg,
                        offset: 50,
                    });
                }
            }).catch(function (err) {
                that.$notify.error({
                    title: '错误',
                    message: err.response.data.msg,
                    offset: 50,
                });
            });
        },
        chose_major(id = 0) {
            this.$router.push({path: '/chose_school', query: {id}})
        },
        user_info() {
            let that = this
            axios.post(
                api.user.info, {}
            ).then(function (res) {
                console.log(res)
                console.log(res.data.data.status)
                console.log(res.data.data.base_info)
                if (res.data.data.base_info.score == '0') {
                    that.show_edit_info = 1
                } else if (res.data.data.base_info.vip_level >= 60 &&
                    res.data.data.base_info.need_more_info === 1) {
                    that.show_more_info++
                }

                that.group_id = res.data.data.group_id
                that.group_name = res.data.data.group_name
                that.level_name = res.data.data.level_name
                that.submit_times = res.data.data.base_info.submit_times
                that.subjects_name = res.data.data.base_info.subjects_name
                that.need_more_info = res.data.data.base_info.need_more_info
                that.modify_score_times = parseInt(res.data.data.base_info.modify_score_times)
                that.detailed_info_times = parseInt(res.data.data.base_info.detailed_info_times)
                if (res.data.data.base_info.show_recommend_rank == 1) {
                    that.recommend_ranking = false
                } else {
                    that.recommend_ranking = true
                }
                console.log('-------------------------------')
                console.log(that.recommend_ranking)
                that.vip_level = res.data.data.base_info.vip_level
                that.base_info = res.data.data.base_info
                that.user_status = res.data.data.status
                localStorage.setItem('base_info', JSON.stringify(res.data.data.base_info))
                localStorage.setItem('vip_level', res.data.data.base_info.vip_level)
                localStorage.setItem('group_name', res.data.data.group_name)
                localStorage.setItem('level_name', res.data.data.level_name)
                localStorage.setItem('submit_times', res.data.data.base_info.submit_times)
            }).catch(function (err) {
                that.$notify.error({
                    title: '错误',
                    message: err.response.data.msg
                });
            });
        },
    }
}
</script>
<style scoped>
* {
    font-family: 'Microsoft YaHei', 'Helvetica Neue', Helvetica, 'PingFang SC', 'Hiragino Sans GB',
    '微软雅黑', Arial, sans-serif;
}

.header {
    width: 100%;
    text-align: center;
    margin: 0 auto;
    padding: 0;
    box-shadow: 0px 5px 5px #F8F8F8;
}

.container {
    background-color: #FFFFFF;
    width: 64%;
    margin: 70px auto 0;
}

.left_content {
    float: left;
    width: 55%;
}

.left_content_img {
    width: 100%;
    margin-top: 124px;
}

.right_content {
    float: right;
    width: 40%;
    margin-top: 124px;
}

.header_info {
    text-align: center;
    margin: 0 auto;
    width: 64%;
    display: flex;
    display: -webkit-flex;
    height: 100px;
    line-height: 100px;
}

.div_logo {
    justify-content: flex-start;
    display: flex;
    display: -webkit-flex;
    align-items: center;
}

.img_logo {
    height: 100px;
}

.name_logo {
    height: 100px;
    line-height: 100px;
    margin-left: 20px;
    font-weight: bold;
    font-size: 30px;
}

.div_nav {
    flex: 1;
    justify-content: flex-end;
    display: flex;
    display: -webkit-flex;
    align-items: center;
}

.div_nav_a {
    font-family: 'Microsoft YaHei';
    text-decoration: none;
    cursor: pointer;
}

.div_nav a {
    margin-left: 50px;
}

.zhi_img {
    width: 48%;
    min-height: 230px;
    float: left;
    border-radius: 5px;
}

.yuan_img {
    width: 48%;
    min-height: 230px;
    float: right;
    border-radius: 5px;
}

</style>