<template>
    <Header chose_index="1"></Header>
    <article class="container">
        <div style="margin-top: 60px;" v-if="buy_it">
            商品列表
        </div>
        <div style="margin-top: 60px;" v-else>
            确认订单
        </div>
        <div style="margin-top: 50px;" v-if="buy_it">
            <div class="zhi_list" v-for="(item, index) in goods">
                <div style="position:relative;display: inline-block;width:30%">
                    <img :src="zhi" style="width:100%;">
                    <div style="position:absolute;top:0;left:0;">
                        <div style="margin-top: 50px;margin-left: 50px;">
                            <div style="color: #FFFFFF;font-size: 24px;">{{ item.name }}</div>
                        </div>
                    </div>
                </div>
                <div style="display: inline-block;width:70%;vertical-align: top">
                    <div style="margin-left: 20px;">
                        <div style="margin-bottom: 10px;">
                            价格: ￥{{ item.price }}
                            <el-button size="mini" @click="buy_goods(item.id, index)" type="primary">购买</el-button>
                        </div>
                        <div>说明:</div>
                        <div><p v-html="item.describe" style="font-size: 12px;"></p></div>
                    </div>
                </div>
            </div>
        </div>
        <div style="margin-top: 50px;" v-else>
            <div class="zhi_list" style="border-radius: 5px;background-color: #f3f5f7;padding: 20px;">
                <div style="margin-bottom: 20px;">商品信息</div>
                <div>
                    <div style="text-align: center;background-color: #fff">
                        <div style="width: 90%;margin: 0 auto;padding: 20px;">
                            <div style="position:relative;display: inline-block;width:30%">
                                <img :src="zhi" style="width:100%;">
                                <div style="position:absolute;top:0;left:0;">
                                    <div style="margin-top: 50px;margin-left: 50px;">
                                        <div style="color: #FFFFFF;font-size: 24px;">{{ chose_good.name }}</div>
                                    </div>
                                </div>
                            </div>
                            <div style="display: inline-block;width:70%;vertical-align: top;text-align: left">
                                <div style="margin-left: 20px;">
                                    <div>说明:</div>
                                    <div><p v-html="chose_good.describe" style="font-size: 12px;"></p></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <el-divider></el-divider>
                    <div>
                        <div>
                            <p style="text-align: right;width:600px;float: right"><em>￥</em>{{ chose_good.price }}</p>
                            <p style="text-align: right;float: right">共 1 件商品, 商品总金额: </p>
                        </div>
                        <div style="clear: both">
                            <p style="text-align: right;width:600px;float: right">
<!--                                <el-input @change="check_coupon" style="width:250px" v-model="chose_good.coupon" size="mini" placeholder="请输入优惠券" />-->
                                <el-input v-model="serial_number[0]" autocomplete="off" size="small" style="width: 100px;text-align: center;"/>
                                -
                                <el-input v-model="serial_number[1]" autocomplete="off" size="small" style="width: 100px;text-align: center;"/>
                                -
                                <el-input v-model="serial_number[2]" autocomplete="off" size="small" style="width: 100px;text-align: center;"/>
                                -
                                <el-input v-model="serial_number[3]" autocomplete="off" size="small" style="width: 100px;text-align: center;"/>
                            </p>
                            <p style="text-align: right;float: right;height:28px;line-height: 28px;">优惠券: </p>
                        </div>
                        <div style="clear: both">
                            <p style="text-align: right;width:600px;float: right;color:red;">
                                <em>￥</em>{{ chose_good.pay_price }}</p>
                            <p style="text-align: right;float: right">应付: </p>
                        </div>
                        <div style="clear: both">
                            <p style="text-align: right;">
                                <el-button type="info" @click="un_payment" round>取消</el-button>
                                <el-button type="danger" @click="payment" round>购买</el-button>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div style="clear:both;"></div>
        <div style="margin-top: 70px;">
            <div style="color: #333333;font-size: 14px;font-family: 'Microsoft YaHei';">
                填报须知：<br><br>
            </div>
            <div style="color: #999999;font-size: 14px;font-family: 'Microsoft YaHei';">
                1、本系统依据历年院校录取数据提供高考志愿填报智能模拟功能，不等同于实际的网上填报志愿，建议考生在填报志愿时，结合本系统的推荐以完善志愿表，正式填报请登录省考试院指定网站；<br>
                2、2021年招生计划已更新（正式招生计划以2021年6月考试院发布为准）；<br>
                3、2012-2020年各院校录取数据，参考各省市出版的填报指南以及各本专科院校的官网历年录取数据；<br>
                4、您正在使用的是河北版，提供“普通类本科批、专科批、”2个批次的志愿智能模拟功能，您可以按系统的重点填报提示选择适合自己的批次模拟，也可直接选择自己关注的批次模拟；<br>
                5、在正式填报时，以教育考试院公布的最新招生计划为准；<br>
                6、若推荐院校招生计划数出现新增专业，会提示”新增专业“且历三年录取数据展示默认采用该院校最低录取分数线。<br>
            </div>
        </div>
    </article>
    <footer style="background-color: #F8F8F8;width:100%;height:167px;margin-top: 75px;">
        <div style="color: #999999 ;text-align: center;padding-top: 60px;">
            Copyright @ 2011-2021 茗硕择嘉（河北）教育科技有限公司 All Rights Reserved. 版权所有<br><br>
            备案号：冀ICP备17013465号-1
        </div>
    </footer>
    <el-dialog
        v-model="payment_status"
        :title="title"
        :center="true"
        width="380px"
        :close-on-press-escape="false"
        :show-close="false"
        :before-close="handleClose">
        <div style="text-align: center">
            <qrcode-vue :value="qr_value" :size="qr_size" level="H"/>
            <p>使用微信扫描二维码进行支付</p>
            <div><span @click="close_qr" style="cursor: pointer">重新选择其他产品</span></div>
        </div>
    </el-dialog>
</template>

<script>
import api from '@/api'
import axios from 'axios'
import QrcodeVue from 'qrcode.vue'
import Header from '@/components/Header.vue'
import logo from '@/assets/logo.png';
import zhi from '@/assets/zhi.png';
import yuan from '@/assets/yuan.png';

export default {
    name: 'Goods',
    components: {QrcodeVue, Header},
    data() {
        return {
            logo: logo,
            zhi: zhi,
            yuan: yuan,
            buy_it: true,
            payment_status: false,
            qr_value: 'https://www.baidu.com',
            qr_size: 300,
            goods_id: 0,
            goods: [],
            goods_index: -1,
            chose_good: [],
            title: '',
            timer: undefined,
            form: {id: 0,},
            serial_number: ['','','',''],
        }
    },
    created() {
        this.goods_list()
        this.get_card_num()
    },
    methods: {
        get_card_num() {
            let that = this
            axios.get(
                api.user.get_card_num, {}
            ).then(function (res) {
                console.log(res)
                if (res.data.code === 10000) {
                    console.log(res.data.data)
                    that.serial_number = res.data.data.serial_number.split('-')
                    console.log(that.serial_number)
                }
            }).catch(function (err) {
            });
        },
        edit_info_click() {
            this.show_edit_info++
        },
        sign_out() {
            localStorage.clear()
            this.$router.push('/')
        },
        step_one_click(e) {
            this.show_edit_info++
        },
        buy_goods(type, index) {
            this.goods_id = type
            this.goods_index = index
            this.chose_good = this.goods[index]
            this.chose_good.pay_price = this.chose_good.price
            this.title = '微信支付: ￥' + this.chose_good.pay_price
            this.buy_it = !this.buy_it
        },
        check_coupon(){
            let that = this
            let form = {}
            form.coupon = (this.serial_number.join('-')).toUpperCase()
            form.price = this.chose_good.price
            axios.post(
                api.goods.check_coupon, form
            ).then(function (res) {
                console.log(res)
                if (res.data.code === 10000) {
                    that.chose_good.pay_price = res.data.data.money
                    that.title = '微信支付: ￥' + that.chose_good.pay_price
                    that.buy_vip()
                } else {
                    that.$notify.error({
                        title: '错误',
                        message: res.data.data.msg,
                        offset: 50,
                    });
                }
            }).catch(function (err) {
                that.$notify.error({
                    title: '错误',
                    message: err.response.data.msg,
                    offset: 50,
                });
            });
        },
        buy_vip(){
            let that = this
            let form = {}
            form.good_id = this.goods_id
            form.coupon = (this.serial_number.join('-')).toUpperCase()
            axios.post(
                api.goods.order, form
            ).then(function (res) {
                console.log(res)
                if (res.data.code === 10000) {
                    if(res.data.status != undefined){
                        that.$router.push('/home_page')
                    }else{
                        that.qr_value = that.URLdecode(res.data.data.code_url)
                        that.form.id = res.data.data.id
                        that.payment_status = !that.payment_status
                        that.timer = setInterval(function () {
                            that.get_pay_status()
                        }, 1500);
                    }
                } else {
                    that.$notify.error({
                        title: '错误',
                        message: res.data.data.msg,
                        offset: 50,
                    });
                }
            }).catch(function (err) {
                that.$notify.error({
                    title: '错误',
                    message: err.response.data.msg,
                    offset: 50,
                });
            });
        },
        payment() {
            this.check_coupon()
        },
        URLdecode(str) {
            let ret = "";
            for (let i = 0; i < str.length; i++) {
                let chr = str.charAt(i);
                if (chr == "+") {
                    ret += " ";
                } else if (chr == "%") {
                    let asc = str.substring(i + 1, i + 3);
                    if (parseInt("0x" + asc) > 0x7f) {
                        ret += decodeURI("%" + str.substring(i + 1, i + 9));
                        i += 8;
                    } else {
                        ret += String.fromCharCode(parseInt("0x" + asc));
                        i += 2;
                    }
                } else {
                    ret += chr;
                }
            }
            return ret;
        },
        un_payment() {
            this.buy_it = !this.buy_it
        },
        get_pay_status() {
            let that = this
            console.log(that.form)
            axios.post(
                api.goods.pay_status, that.form
            ).then(function (res) {
                console.log(res)
                if (res.data.code === 10000) {
                    if(res.data.data.status == 2){
                        clearInterval(that.timer);
                        that.$router.push('/home_page')
                    }
                } else {
                    that.$notify.error({
                        title: '错误',
                        message: res.data.data.msg,
                        offset: 50,
                    });
                }
            }).catch(function (err) {
                that.$notify.error({
                    title: '错误',
                    message: err.response.data.msg,
                    offset: 50,
                });
            });
        },
        handleClose(done) {
        },
        close_qr() {
            this.payment_status = !this.payment_status
        },
        goods_list() {
            let that = this
            axios.get(
                api.goods.list, that.form
            ).then(function (res) {
                console.log(res)
                if (res.data.code === 10000) {
                    that.goods = res.data.data
                    // that.$notify({
                    //     title: '成功',
                    //     message: '信息录入成功',
                    //     type: 'success'
                    // });
                } else {
                    that.$notify.error({
                        title: '错误',
                        message: res.data.data.msg,
                        offset: 50,
                    });
                }
            }).catch(function (err) {
                that.$notify.error({
                    title: '错误',
                    message: err.response.data.msg,
                    offset: 50,
                });
            });
        },
    }
}
</script>
<style scoped>
* {
    font-family: 'Microsoft YaHei', 'Helvetica Neue', Helvetica, 'PingFang SC', 'Hiragino Sans GB',
    '微软雅黑', Arial, sans-serif;
}

.header {
    width: 100%;
    text-align: center;
    margin: 0 auto;
    padding: 0;
    box-shadow: 0px 5px 5px #F8F8F8;
}

.container {
    background-color: #FFFFFF;
    width: 64%;
    margin: 70px auto 0;
}

.left_content {
    float: left;
    width: 55%;
}

.left_content_img {
    width: 100%;
    margin-top: 124px;
}

.right_content {
    float: right;
    width: 40%;
    margin-top: 124px;
}

.header_info {
    text-align: center;
    margin: 0 auto;
    width: 64%;
    display: flex;
    display: -webkit-flex;
    height: 100px;
    line-height: 100px;
}

.div_logo {
    justify-content: flex-start;
    display: flex;
    display: -webkit-flex;
    align-items: center;
}

.img_logo {
    height: 100px;
}

.name_logo {
    height: 100px;
    line-height: 100px;
    margin-left: 20px;
    font-weight: bold;
    font-size: 30px;
}

.div_nav {
    flex: 1;
    justify-content: flex-end;
    display: flex;
    display: -webkit-flex;
    align-items: center;
}

.div_nav_a {
    font-family: 'Microsoft YaHei';
    text-decoration: none;
    cursor: pointer;
}

.div_nav a {
    margin-left: 50px;
}

.zhi_list {
    width: 100%;
}

</style>