<template>
    <Header chose_index="4"></Header>
    <article class="container">
        <div
            style="height: 60px;line-height: 60px;margin-top:20px;margin-bottom:20px;display: flex;justify-content:space-between;align-items:center;">
      <span>
        <div style="font-size: 16px;">
          学生: {{ student.name }} 类型: {{ student.class_str }} 成绩: {{
                student.score
            }}分 选科: {{ student.subject_one_str }}/{{ student.subject_two_str }}/{{ student.subject_three_str }}
        </div>
      </span>
            <span>
          <el-button @click="open_summary">打开综合评价</el-button>
          <el-button @click="submit_2_student">审核完成</el-button>
      </span>
        </div>
        <div id="sortable_list">
            <el-card class="box-card" v-for="(item, index) in tableData">
                <div style="height:60px;width:100%;background-color: #F2F2F2;">
                    <div>
                        <div style="position: absolute;" v-if="item.match_status===1" class="major_sprint"></div>
                        <div style="position: absolute;" v-else-if="item.match_status===2" class="major_stable"></div>
                        <div style="position: absolute;" v-else class="major_floors"></div>
                        <div
                            style="position: absolute;height:60px;width:40px;line-height:60px;padding-right:10px;text-align: right;border-radius: 5px;color: white">
                            {{ item.match_str }}
                        </div>
                    </div>
                    <div
                        style="float: left;margin-left:65px;height:60px;line-height: 60px;text-align: center;padding-right: 10px;">
              <span style="font-size: 20px;margin-right: 10px;color: #333333;">
                {{ item.majors }}
              </span>
                        <span style="font-size: 16px;color: #e8320a;" v-if="item.new_major === 1">
                新
              </span>
                    </div>
                    <div style="float: right;height:60px;line-height: 60px;text-align: center;padding-right: 10px;">
                        <el-tag v-if="item.district_info === 0" type="info" effect="plain">地域</el-tag>
                        <el-tag v-else-if="item.district_info === 1" type="danger" effect="dark">地域</el-tag>
                        <el-tag v-else="item.district_info === 2" type="success" effect="dark">地域</el-tag>

                        <el-tag v-if="item.school_topic_info === 0" type="info" effect="plain">学校</el-tag>
                        <el-tag v-else-if="item.school_topic_info === 1" type="danger" effect="dark">学校</el-tag>
                        <el-tag v-else="item.school_topic_info === 2" type="success" effect="dark">学校</el-tag>

                        <el-tag v-if="item.major_topic_info === 0" type="info" effect="plain">专业</el-tag>
                        <el-tag v-else-if="item.major_topic_info === 1" type="danger" effect="dark">专业</el-tag>
                        <el-tag v-else="item.major_topic_info === 2" type="success" effect="dark">专业</el-tag>

                        <el-tag v-if="item.score_topic === 0" type="info" effect="plain">策略</el-tag>
                        <el-tag v-else-if="item.score_topic === 1" type="danger" effect="dark">策略</el-tag>
                        <el-tag v-else="item.score_topic === 2" type="success" effect="dark">策略</el-tag>
                    </div>
                </div>
                <div>
                    <div style="margin-top:30px;margin-bottom:30px;float: left;width: 20%;">
                        <div style="text-align: center">
                            <img style="height: 160px;" :src="item.school_logo">
                        </div>
                        <div style="text-align: center">
                            {{ item.school_name }}
                        </div>
                        <div style="text-align: center">
                            {{ item.nature_str }}
                        </div>
                    </div>
                    <div style="margin-top:30px;margin-bottom:30px;float: left;width: 20%;">
                        <div style="text-align: left;">
                            <div style="margin-bottom: 20px;">{{ item.type_str }} [{{ item.postgraduate }}]</div>
                            <el-tag size="mini">{{ item.diff }}</el-tag>
                            <el-tag size="mini">{{ item.use_sum }}</el-tag>
                            <el-tag size="mini">{{ item.forecast_low_score }}</el-tag>
                        </div>
                        <div class="school_tag">
                            <el-tag size="mini" type="success" v-for="(label, index) in item.label_list" :key="index">
                                {{ label }}
                            </el-tag>
                        </div>
                    </div>
                    <div class="major_list" style="border-left: 1px #EBEBEB solid;">
                        <div>
                            <div>招生计划</div>
                            <el-row>
                                <el-col :span="4">
                                    <div class="major_info_desc">
                                        <span>人数</span>&nbsp;|&nbsp;<span>{{ item.num }}</span>
                                    </div>
                                </el-col>
                                <el-col :span="4" :offset="1">
                                    <div class="major_info_desc">
                                        <span>学年</span>&nbsp;|&nbsp;<span>{{ item.length }}</span>
                                    </div>
                                </el-col>
                                <el-col :span="4" :offset="1">
                                    <div class="major_info_desc">
                                        <span>学费</span>&nbsp;|&nbsp;<span>{{ item.fee }}</span>
                                    </div>
                                </el-col>
                                <el-col :span="8" :offset="1">
                                    <div class="major_info_desc">
                    <span style="float: left;padding-left: 20px;"><span>学科</span>&nbsp;|&nbsp;<span>{{
                            item.major_group_rule
                        }}</span></span>
                                        <span v-if="item.major_limit === 1"
                                              style="float: right;padding-right: 20px;color: #24C599">选科匹配</span>
                                        <span v-else
                                              style="float: right;padding-right: 20px;color: #999999">选科不匹配</span>
                                    </div>
                                </el-col>
                            </el-row>
                        </div>
                        <div style="margin-top: 20px;">
                            <div>统计 ( 录取人数 / 线差 / 最低分 / 最低位次 )</div>
                            <div class="major_info_desc" style="height: 70px;padding-left: 20px;">
                                <div style="width: 33%;display: inline-block;text-align: left;margin-top: 5px;">
                                    <div style="line-height: 30px;">{{ before_one }}年</div>
                                    <div style="line-height: 30px;">
                                        {{ item.before_one.number_basics }}&nbsp;/&nbsp;
                                        {{ item.before_one.line_diff_basics }}&nbsp;/&nbsp;
                                        {{ item.before_one.low_score_basics }}&nbsp;/&nbsp;
                                        {{ item.before_one.precedence_basics }}
                                    </div>
                                </div>
                                <div style="width: 33%;display: inline-block;text-align: left;">
                                    <div style="line-height: 30px;">{{ before_two }}年</div>
                                    <div style="line-height: 30px;">
                                        {{ item.before_two.number_basics }}&nbsp;/&nbsp;
                                        {{ item.before_two.line_diff_basics }}&nbsp;/&nbsp;
                                        {{ item.before_two.low_score_basics }}&nbsp;/&nbsp;
                                        {{ item.before_two.precedence_basics }}
                                    </div>
                                </div>
                                <div style="width: 33%;display: inline-block;text-align: left;">
                                    <div style="line-height: 30px;">{{ before_three }}年</div>
                                    <div style="line-height: 30px;">
                                        {{ item.before_three.number_basics }}&nbsp;/&nbsp;
                                        {{ item.before_three.line_diff_basics }}&nbsp;/&nbsp;
                                        {{ item.before_three.low_score_basics }}&nbsp;/&nbsp;
                                        {{ item.before_three.precedence_basics }}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div style="margin-top: 20px;">
                            <el-input
                                v-model="item.approval_content"
                                :rows="3"
                                type="textarea"
                                style="margin-bottom: 10px;"
                                placeholder="请输入审核信息"
                            />
                            <el-button size="mini" @click="submit_assess(item.id, index)" type="primary">提交</el-button>
                        </div>
                    </div>
                </div>
            </el-card>
        </div>
        <div style="margin-top: 20px;"></div>
        <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            v-model:currentPage="form.page"
            :page-sizes="[10, 20, 50, 100]"
            :page-size="form.page_size"
            layout="total, sizes, prev, pager, next, jumper"
            :total="form.total">
        </el-pagination>
    </article>
    <footer style="background-color: #F8F8F8;width:100%;height:167px;margin-top: 75px;">
        <div style="color: #999999 ;text-align: center;padding-top: 60px;">
            Copyright @ 2011-2021 茗硕择嘉（河北）教育科技有限公司 All Rights Reserved. 版权所有<br><br>
            备案号：冀ICP备17013465号-1
        </div>
    </footer>
    <el-drawer
        ref="drawer"
        v-model="dialog"
        title="志愿整体评价"
        :before-close="handleClose"
        direction="rtl"
        custom-class="demo-drawer"
    >
        <div>
            <el-form style="margin-right: 20px;">
                <el-form-item label="内容" :label-width="formLabelWidth">
                    <el-input v-model="student.summary" :rows="30" type="textarea" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="操作" :label-width="formLabelWidth">
                    <el-button type="primary" @click="summary_handle">提交</el-button>
                </el-form-item>
            </el-form>
        </div>
    </el-drawer>
    <EditInfo :show="show_edit_info" @fatherMethod="fatherMethod"></EditInfo>
</template>

<script>
import http from '@/utils/http'
import api from '@/api'
import Header from '@/components/Header.vue'
import logo from '@/assets/logo.png';
import EditInfo from "../components/EditInfo";
import axios from "axios";

export default {
    name: 'Approval',
    components: {EditInfo, Header},
    data() {
        return {
            summary: '',
            dialog: false,
            logo: logo,
            show_edit_info: 0,
            marks: [],
            score: 0,
            all_num: 0,
            top: 0,
            top_num: 0,
            middle_num: 0,
            bottom_num: 0,
            bottom: 0,
            label_num: [],
            dialogVisible: false,
            dialogMajorsVisible: false,
            activeName: 'first',
            listName: 'first',
            tags: [],
            base_info: {
                'class_id': 0,
                'score': 0,
                'subject_one': 0,
                'subject_three': 0,
                'subject_two': 0,
                'subjects': "",
            },
            form: {
                change_id: '',
                provinces: [],
                type: [],
                level: [],
                school_ids: [],
                follow_major: [],
                major_one: 0,
                major_two: [],
                page_size: 10,
                step: 10,
                total: 0,
                page: 1,
                order_type: 1,
                score_range: 0,
            },
            favorite_list: [],
            majorsData: [],
            formLabelWidth: '120px',
            dialogFormVisible: true,
            provinces: [],
            types: [],
            schools: [],
            major_one: [],
            major_two: [],
            major_three: [],
            major_three_list: [],
            majors: [],
            school_list: [],
            level: [],
            tableData: [],
            before_one: '',
            before_two: '',
            before_three: '',
            rank_list: [],
            plan_rank: [],
            student_id: 0,
            student: {
                name: '',
                score: 0,
                class_str: '',
                subject_one_str: '',
                subject_three_str: '',
                subject_two_str: '',
                summary: '',
            }
        };
    },
    mounted() {
        this.before_one = (new Date()).getFullYear() - 1 + ''
        this.before_two = this.before_one - 1 + ''
        this.before_three = this.before_one - 2 + ''
    },
    created() {
        this.branch = JSON.parse(localStorage.getItem('branch'))
        this.base_info = JSON.parse(localStorage.getItem('base_info'))

        this.student_id = this.$route.query.id

        this.student_info()
        this.favorites()
    },
    methods: {
        summary_handle() {
            let data = http.post(api.instructor.update_summary, {
                student_id: this.student_id,
                summary: this.student.summary
            })
            data.then(data => {
                console.log(data)
                if (data.code === 10000) {
                    this.$notify({
                        title: '成功',
                        message: '信息录入成功',
                        type: 'success',
                        offset: 50,
                    });
                } else {
                    this.$notify.error({
                        title: '错误',
                        message: res.data.data.msg,
                        offset: 50,
                    });
                }
            })
        },
        user_info() {
            let that = this
            axios.post(
                api.user.info, {}
            ).then(function (res) {
                console.log(res)
                console.log(res.data.data.status)
                console.log(res.data.data.base_info)
                if (res.data.data.base_info.score == '0') {
                    that.show_edit_info = 1
                } else if (res.data.data.base_info.vip_level >= 60 &&
                    res.data.data.base_info.need_more_info === 1) {
                    that.show_more_info++
                }

                that.group_id = res.data.data.group_id
                that.group_name = res.data.data.group_name
                that.level_name = res.data.data.level_name
                that.submit_times = res.data.data.base_info.submit_times
                that.subjects_name = res.data.data.base_info.subjects_name
                that.need_more_info = res.data.data.base_info.need_more_info
                that.modify_score_times = parseInt(res.data.data.base_info.modify_score_times)
                that.detailed_info_times = parseInt(res.data.data.base_info.detailed_info_times)
                if (res.data.data.base_info.show_recommend_rank == 1) {
                    that.recommend_ranking = false
                } else {
                    that.recommend_ranking = true
                }
                console.log('-------------------------------')
                console.log(that.recommend_ranking)
                that.vip_level = res.data.data.base_info.vip_level
                that.base_info = res.data.data.base_info
                that.user_status = res.data.data.status
                localStorage.setItem('base_info', JSON.stringify(res.data.data.base_info))
                localStorage.setItem('vip_level', res.data.data.base_info.vip_level)
                localStorage.setItem('group_name', res.data.data.group_name)
                localStorage.setItem('level_name', res.data.data.level_name)
                localStorage.setItem('submit_times', res.data.data.base_info.submit_times)
            }).catch(function (err) {
                that.$notify.error({
                    title: '错误',
                    message: err.response.data.msg,
                    offset: 50,
                });
            });
        },
        open_summary() {
            this.dialog = true
        },
        handleClose() {
            this.dialog = false
        },
        edit_info_click() {
            this.show_edit_info++
        },
        sign_out() {
            localStorage.clear()
            this.$router.push('/')
        },
        collection_order_click(e) {
            this.form.order_type = 1
            this.tableData = []
            this.form.page = 1;
            this.favorites()
        },
        score_order_click(e) {
            this.form.order_type = 2
            this.tableData = []
            this.form.page = 1;
            this.favorites()
        },
        handleSizeChange(val) {
            this.form.page_size = val
            this.favorites()
            console.log(`每页 ${val} 条`);
        },
        handleSelectionChange(val) {
            this.multipleSelection = val;
        },
        tableRowClassName({row, rowIndex}) {
            if (rowIndex === 1) {
                return 'warning-row';
            } else if (rowIndex === 3) {
                return 'success-row';
            }
            return '';
        },
        handleCurrentChange(val) {
            this.form.page = val
            this.favorites()
            console.log(`当前页: ${val}`);
        },
        submit_2_student() {
            let data = http.post(api.instructor.send_2_member, {student_id: this.student_id})
            data.then(data => {
                console.log(data.data)
            })
        },
        student_info() {
            let data = http.get(api.instructor.student_info, {student_id: this.student_id})
            data.then(data => {
                console.log(data.data)
                this.student = data.data
            })
        },
        favorites() {
            let obj = {...this.form}
            obj.student_id = this.student_id
            let data = http.post(api.user.member_collection, obj)
            data.then(data => {
                console.log(data)
                console.log(data.data)
                console.log(data.data.data)
                console.log(data.data.rank)

                let total = data.data.total
                let from = data.data.from
                this.tableData = data.data.data
                this.rank_list = data.data.rank
                for (let i in this.tableData) {
                    if (this.tableData[i]['before_one'] !== '未招生' && this.tableData[i]['before_one'] !== undefined) {
                        this.tableData[i]['before_one'] = JSON.parse(this.tableData[i]['before_one'])
                    }
                    if (this.tableData[i]['before_two'] !== '未招生' && this.tableData[i]['before_two'] !== undefined) {
                        this.tableData[i]['before_two'] = JSON.parse(this.tableData[i]['before_two'])
                    }
                    if (this.tableData[i]['before_three'] !== '未招生' && this.tableData[i]['before_three'] !== undefined) {
                        this.tableData[i]['before_three'] = JSON.parse(this.tableData[i]['before_three'])
                    }
                    this.tableData[i]['order_id'] = from
                    from++
                }
                console.log(this.tableData)

                for (let i = 1; i <= total; i++) {
                    this.favorite_list.push({'value': i, 'label': i})
                }

                this.form.page = data.data.current_page
                this.form.total = data.data.total
                this.form.page_size = data.data.per_page
            })
        },
        submit_assess(id, index) {
            let data = http.post(api.instructor.assess_plan, {
                student_id: this.student_id,
                plan_id: id,
                content: this.tableData[index]['approval_content']
            })
            data.then(data => {
                console.log(data.data)
            })
        }
    }
}
</script>
<style scoped>
* {
    font-family: 'Microsoft YaHei', 'Helvetica Neue', Helvetica, 'PingFang SC', 'Hiragino Sans GB',
    '微软雅黑', Arial, sans-serif;
}

.header {
    width: 100%;
    text-align: center;
    margin: 0 auto;
    box-shadow: 0px 5px 5px #F8F8F8;
}

.container {
    background-color: #FFFFFF;
    width: 64%;
    margin: 70px auto 0;
}

.header_info {
    margin: 0 auto;
    width: 64%;
    display: flex;
    display: -webkit-flex;
}

.div_logo {
    justify-content: flex-start;
    display: flex;
    display: -webkit-flex;
    align-items: center;
}

.img_logo {
    height: 100px;
}

.name_logo {
    height: 100px;
    line-height: 100px;
    margin-left: 20px;
    font-weight: bold;
    font-size: 30px;
}

.div_nav {
    flex: 1;
    justify-content: flex-end;
    display: flex;
    display: -webkit-flex;
    align-items: center;
}

.div_nav a {
    margin-left: 50px;
}

.box-card {
    border: 0;
    margin-top: 15px;
}

.box-card >>> .el-card__body {
    padding: 0;
}

.box-card >>> .el-card__header {
    padding: 0;
}

.major_sprint {
    display: inline-block;
    border-top: 60px solid #F3AB50;
    border-right: 18px solid transparent;
    height: 0;
    width: 40px;
}

.major_floors {
    display: inline-block;
    border-top: 60px solid #24C599;
    border-right: 18px solid transparent;
    height: 0;
    width: 40px;
}

.major_stable {
    display: inline-block;
    border-top: 60px solid #4D94F1;
    border-right: 18px solid transparent;
    height: 0;
    width: 40px;
}

.major_info_desc {
    background-color: #F8F8F8;
    border-radius: 5px;
    margin-top: 10px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    font-size: 12px;
}

.school_info {
    margin-top: 30px;
    float: left;
    height: 285px;
    width: 40%;
    border-right: 1px #EBEBEB solid;
}

.majors_list {
    float: right;
    margin-top: 30px;
    height: 285px;
    width: 57%;
    overflow-y: scroll;
    scrollbar-color: #DBDBDB #F8F8F8;
    /* 滑块颜色  滚动条背景颜色 */
    scrollbar-width: thin;
    /* 滚动条宽度有三种：thin、auto、none */
}

.major_list {
    float: left;
    margin-top: 30px;
    margin-bottom: 20px;
    padding-left: 20px;
    width: 57%;
}

.majors_list::-webkit-scrollbar {
    /*滚动条整体样式*/
    width: 5px; /*高宽分别对应横竖滚动条的尺寸*/
    height: 1px;
}

.majors_list::-webkit-scrollbar-thumb {
    /*滚动条里面小方块*/
    border-radius: 10px;
    background-color: skyblue;
    background-image: -webkit-linear-gradient(#DBDBDB, #DBDBDB);
}

.majors_list::-webkit-scrollbar-track {
    /*滚动条里面轨道*/
    box-shadow: inset 0 0 5px rgba(248, 248, 248, 1);
    background: #ededed;
    border-radius: 10px;
}

.school_tag {
    text-align: left;
    margin-top: 10px;
}

.school_tag >>> .el-tag--light {
    margin-left: 0px;
    margin-right: 10px;
}

.el-checkbox >>> .el-checkbox__input {
    display: none;
}

.el-form-item >>> .el-form-item__label {
    line-height: 30px;
}

.el-form-item >>> .el-form-item__content {
    line-height: 30px;
}

.el-radio >>> .el-radio__input {
    display: none;
}

.selected {
    color: #d01414;
    font-size: 24px;
    height: 60px;
    line-height: 60px;
    border-bottom: 1px red solid;
    cursor: pointer
}

.unselected {
    color: #919090;
    font-size: 20px;
    cursor: pointer
}

</style>
