<template>
    <el-dialog title="补充信息" v-model="edit_info" @closed="close_edit_info">
        <el-form :model="base_info" style="margin: 20px;">
            <div class="step_one_content">
                <el-form-item label="省份">
                    <el-select v-model="info.province" filterable @change="province_change" style="margin-right: 10px;"
                               size="small" placeholder="请选择">
                        <el-option
                            v-for="(item, index) in province"
                            :key="index"
                            :label="item"
                            :value="index"
                        >
                        </el-option>
                    </el-select>
                    <el-select v-model="info.city" filterable @change="cities_change" style="margin-right: 10px;"
                               size="small" placeholder="请选择">
                        <el-option
                            v-for="(item, index) in chose_cities"
                            :key="index"
                            :label="item"
                            :value="index"
                        >
                        </el-option>
                    </el-select>
                    <el-select v-model="info.area" filterable @change="area_change" placeholder="请选择" size="small">
                        <el-option
                            v-for="(item, index) in chose_areas"
                            :key="index"
                            :label="item"
                            :value="index"
                        >
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="性别">
                    <el-radio-group v-model="info.gender">
                        <el-radio :label="1">男</el-radio>
                        <el-radio :label="2">女</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="学校">
                    <el-input v-model="info.school_name" size="small" style="width: 300px;"></el-input>
                </el-form-item>
                <el-form-item label="语文">
                    <el-input-number v-model="info.chinese_score" :min="0" :max="150" />
                </el-form-item>
                <el-form-item label="数学">
                    <el-input-number v-model="info.math_score" :min="0" :max="150" />
                </el-form-item>
                <el-form-item label="英语">
                    <el-input-number v-model="info.english_score" :min="0" :max="150" />
                </el-form-item>
                <el-form-item :label="subjects_name[0]">
                    <el-input-number v-model="info.subject_three_score" :min="0" :max="150" />
                </el-form-item>
                <el-form-item :label="subjects_name[1]">
                    <el-input-number v-model="info.subject_two_score" :min="0" :max="150" />
                </el-form-item>
                <el-form-item :label="subjects_name[2]">
                    <el-input-number v-model="info.subject_one_score" :min="0" :max="150" />
                </el-form-item>
            </div>
        </el-form>
        <template #footer>
    <span class="dialog-footer">
      <el-button @click="edit_info = false">取 消</el-button>
      <el-button type="primary" @click="submit_info">确 定</el-button>
    </span>
        </template>
    </el-dialog>
</template>

<script>
import api from '@/api'
import axios from "axios";
import {ElMessageBox, ElMessage} from 'element-plus'

export default {
    name: 'AddMoreInfo',
    props: {
        show: Number,
        subjects_name: Array
    },
    data() {
        return {
            edit_info: false,
            province: [],
            cities: [],
            chose_cities: [],
            areas: [],
            chose_areas: [],
            base_info: {
                'class_id': 0,
                'score': 0,
                'name': '',
                'province': '0',
                'province_id': '0',
                'subject_one': 0,
                'subject_three': 0,
                'subject_two': 0,
                'subjects': "",
                'majors': [],
                'second': [],
            },
            info: {
                address: '',
                gender: '',
                province: '',
                province_str: '',
                city: '',
                city_str: '',
                area: '',
                area_str: '',
                school_name: [],
                subject_one_score: '',
                subject_two_score: '',
                subject_three_score: '',
                math_score: '',
                chinese_score: '',
                english_score: '',
                replace: 0,
            },
        }
    },
    watch: {
        show(new_val) {
            this.edit_info = new_val > 0;
        }
    },
    mounted() {
    },
    created() {
        this.base_info = JSON.parse(localStorage.getItem('base_info'))

        this.info.chinese_score = parseInt(this.base_info.chinese_score || 0)
        this.info.english_score = parseInt(this.base_info.english_score || 0)
        this.info.math_score = parseInt(this.base_info.math_score || 0)
        this.info.subject_one_score = parseInt(this.base_info.subject_one_score || 0)
        this.info.subject_two_score = parseInt(this.base_info.subject_two_score || 0)
        this.info.subject_three_score = parseInt(this.base_info.subject_three_score || 0)
        this.info.gender = this.base_info.gender
        this.info.school_name = this.base_info.school_name
        this.info.replace = this.base_info.need_more_info == 1 ? 0 : 1;

        let address = JSON.parse(this.base_info.full_address || '[]')

        this.info.province_str = address[0] || ''
        this.info.city_str = address[1] || ''
        this.info.area_str = address[2] || ''

        this.edit_info = this.show > 0

        this.get_areas()
    },
    methods: {
        close_edit_info(e) {
            this.edit_info = false
        },
        province_change(v) {
            this.chose_cities = this.cities[v]
            this.info.province_str = this.province[v]
        },
        cities_change(v) {
            console.log(this.areas[v])
            this.chose_areas = this.areas[v]
            this.info.city_str = this.chose_cities[v]
        },
        area_change(v) {
            this.info.area_str = this.chose_areas[v]
        },
        check_score(type, e) {
            let val = parseInt(e)
            if (val < 0 || (val > 150 && type < 4) || (val > 100 && type > 3)) {
                val = 0
                if (type === 1) {
                    this.info.chinese_score = val
                } else if (type === 2) {
                    this.info.math_score = val
                } else if (type === 3) {
                    this.info.math_score = val
                } else if (type === 4) {
                    this.info.subject_three_score = val
                } else if (type === 5) {
                    this.info.subject_two_score = val
                } else {
                    this.info.subject_one_score = val
                }
            }
        },
        get_areas() {
            let that = this
            axios.post(
                api.user.areas, {}
            ).then(function (res) {
                console.log(res)
                console.log(res.data.code)
                if (res.data.code === 10000) {
                    that.province = res.data.data.province
                    that.cities = res.data.data.cities
                    that.areas = res.data.data.areas

                    for (let i in that.province) {
                        if (that.province[i] === that.info.province_str) {
                            that.info.province = i
                            that.chose_cities = that.cities[i]

                            break
                        }
                    }

                    for (let i in that.chose_cities) {
                        if (that.chose_cities[i] === that.info.city_str) {
                            that.info.city = i
                            that.chose_areas = that.areas[i]

                            break
                        }
                    }

                    for (let i in that.chose_areas) {
                        if (that.chose_areas[i] === that.info.area_str) {
                            that.info.area = i

                            break
                        }
                    }
                } else {
                    that.$notify.error({
                        title: '地区获取错误',
                        message: res.data.data.msg,
                        offset: 50,
                    });
                }
            }).catch(function (err) {
                that.$notify.error({
                    title: '未知错误',
                    message: err.response.data.msg,
                    offset: 50,
                });
            });
        },
        submit_info() {
            //this.edit_info = false
            let that = this

            let sum = parseInt(this.info.chinese_score || 0) + parseInt(this.info.english_score || 0) + parseInt(this.info.english_score || 0)
            sum += parseInt(this.info.subject_one_score || 0) + parseInt(this.info.subject_two_score || 0) + parseInt(this.info.subject_three_score || 0)
            if (sum <= 0 || sum > 750) {
                ElMessageBox.confirm(
                    '请输入正确的分数',
                    '提醒',
                    {
                        confirmButtonText: '确认修改',
                        cancelButtonText: '取消',
                        type: 'warning',
                    }
                ).then(() => {
                }).catch(() => {
                })
                return false
            }
            console.log(sum)
            console.log(that.base_info.score)
            if (parseInt(sum) !== parseInt(that.base_info.score)) {
                let content = '是否用分科成绩之和替换总分,此操作会清空收藏列表(新用户不清空)！'
                if(that.base_info.subject_one_score == 0 && that.base_info.subject_three_score == 0 && that.base_info.subject_two_score == 0){
                    content = '是否用分科成绩之和替换总分?'
                }
                ElMessageBox.confirm(
                    content,
                    '分科成绩之和与总分不匹配',
                    {
                        confirmButtonText: '确认修改',
                        cancelButtonText: '取消',
                        type: 'warning',
                    }
                )
                    .then(() => {
                        that.confirm_mod_info()
                    })
                    .catch(() => {
                        ElMessage({
                            type: 'info',
                            message: '您已取消修改分数',
                        })
                    })
                return false
            }else{
                that.confirm_mod_info()
            }
        },
        confirm_mod_info() {
            this.edit_info = false
            let that = this
            let address = [this.info.province_str, this.info.city_str, this.info.area_str];
            this.info.address = JSON.stringify(address)
            this.info.gender += ''
            axios.post(
                api.user.extra_info, this.info
            ).then(function (res) {
                if (res.data.code === 10000) {
                    that.base_info.chinese_score = that.info.chinese_score || 0
                    that.base_info.english_score = that.info.english_score || 0
                    that.base_info.math_score = that.info.math_score || 0
                    that.base_info.subject_one_score = that.info.subject_one_score || 0
                    that.base_info.subject_two_score = that.info.subject_two_score || 0
                    that.base_info.subject_three_score = that.info.subject_three_score || 0
                    that.base_info.gender = that.info.gender
                    that.base_info.school_name = that.info.school_name
                    that.base_info.score = parseInt(that.info.chinese_score || 0) + parseInt(that.info.english_score || 0) + parseInt(that.info.math_score || 0)
                    that.base_info.score += (parseInt(that.info.subject_one_score || 0) + parseInt(that.info.subject_two_score || 0) + parseInt(that.info.subject_three_score || 0))
                    that.base_info.full_address = JSON.stringify(address)
                    localStorage.setItem('base_info', JSON.stringify(that.base_info))

                    that.$notify({
                        title: '成功',
                        message: '信息录入成功',
                        type: 'success',
                        offset: 50,
                    });
                    that.$emit('fatherMethod');
                } else {
                    that.$notify.error({
                        title: '错误',
                        message: res.data.data.msg,
                        offset: 50,
                    });
                }
            }).catch(function (err) {
                that.$notify.error({
                    title: '错误',
                    message: err.response.data.msg,
                    offset: 50,
                });
            });
        },
    }
}
</script>

<style scoped>
h3 {
    margin: 40px 0 0;
}

ul {
    list-style-type: none;
    padding: 0;
}

li {
    margin: 0 10px;
}

a {
    color: #42b983;
}

.el-form-item {
    margin-bottom: 5px;
}
</style>
