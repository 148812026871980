<template>
    <Header chose_index="2"></Header>
    <article class="container">
        <div class="school_header">
            <div>
                <img :src="school_extra_info.logo" class="school_logo" alt="学校LOGO"/>
            </div>
            <div style="margin-left: 10px;">
                <div><h2>{{ school_extra_info.name }}</h2></div>
                <div>
                    <el-tag style="margin-right: 10px;" type="info" size="small"
                            v-for="item in school_extra_info.labels">
                        {{ item }}
                    </el-tag>
                </div>
                <div>{{ school_extra_info.province_name }} {{ school_extra_info.city_name }} /
                    {{ school_extra_info.type_name }} / {{ school_extra_info.school_nature_name }} /
                    {{ school_extra_info.belong }}
                </div>
            </div>
        </div>
        <div>
            <el-tabs class="demo-tabs" v-model="school_type" style="width: 1350px;" @tab-click="schoolTypeClick">
                <el-tab-pane label="院校概况" name="info">
                    <div v-if="show_school_info">
                        <div style="margin: 20px auto;width: 100%;text-align: center">
                            <h3 style="display: inline-block">院校详情</h3>
                        </div>
                        <div v-html="more_school_info"></div>
                    </div>
                    <div v-else>
                        <div class="school_info">
                            <div style="margin-top: 10px;margin-right: 30px;">
                                <div id="gaodeditu" style="width:480px;height:300px;"></div>
                                <div style="width: 100%;">
                                    <div style="margin-top: 5px;cursor: pointer;float: right"
                                         @click="school_map(school_name)">
                                        <span class="iconfont_small">&#xe64b;</span>点击查看大图
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div class="school_label">
                                    <div style="text-align: center">
                                        <img :src="create" style="width: 45px;height: 45px;">
                                        <div>{{ school_extra_info.create_date }}</div>
                                    </div>
                                    <div style="text-align: center">
                                        <img :src="native" style="width: 45px;height: 45px;">
                                        <div>{{ school_extra_info.school_nature_name }}</div>
                                    </div>
                                    <div style="text-align: center">
                                        <img :src="belong" style="width: 45px;height: 45px;">
                                        <div>{{ school_extra_info.type_name }}</div>
                                    </div>
                                    <div style="text-align: center">
                                        <img :src="gongxin" style="width: 45px;height: 45px;">
                                        <div>{{ school_extra_info.belong }}</div>
                                    </div>
                                    <div style="text-align: center">
                                        <img :src="type" style="width: 45px;height: 45px;">
                                        <div>{{ school_extra_info.school_type_name }}</div>
                                    </div>
                                    <div style="text-align: center">
                                        <img :src="local" style="width: 45px;height: 45px;">
                                        <div>{{ school_extra_info.province_name }} {{
                                                school_extra_info.city_name
                                            }}
                                        </div>
                                    </div>
                                    <div style="text-align: center">
                                        <img :src="shuo" style="width: 45px;height: 45px;">
                                        <div>{{ school_extra_info.num_master }} / {{
                                                school_extra_info.num_master2
                                            }}
                                            <el-popover
                                                placement="top-start"
                                                :width="200"
                                                trigger="hover"
                                            >
                                                <template #reference>
                                                    <div
                                                        style="width: 15px;height:15px;line-height:15px;font-size:10px;border-radius: 50%;display: inline-block;border: 1px #C23B1E solid;">
                                                        ?
                                                    </div>
                                                </template>
                                                <template #default>
                                                    <div style="display: flex; gap: 16px; flex-direction: column">
                                                        <div>
                                                            <p style="margin: 0; font-weight: 500">
                                                                一级学科硕士点 {{ school_extra_info.num_master }} 个
                                                            </p>
                                                            <p style="margin: 0; font-weight: 500">
                                                                二级学科硕士点 {{ school_extra_info.num_master2 }} 个
                                                            </p>
                                                        </div>
                                                    </div>
                                                </template>
                                            </el-popover>
                                        </div>
                                    </div>
                                    <div style="text-align: center">
                                        <img :src="bo" style="width: 45px;height: 45px;">
                                        <div>{{ school_extra_info.num_doctor }} / {{
                                                school_extra_info.num_doctor2
                                            }}
                                            <el-popover
                                                placement="top-start"
                                                :width="200"
                                                trigger="hover"
                                            >
                                                <template #reference>
                                                    <div
                                                        style="width: 15px;height:15px;line-height:15px;font-size:10px;border-radius: 50%;display: inline-block;border: 1px #C23B1E solid;">
                                                        ?
                                                    </div>
                                                </template>
                                                <template #default>
                                                    <div style="display: flex; gap: 16px; flex-direction: column">
                                                        <div>
                                                            <p style="margin: 0; font-weight: 500">
                                                                一级学科博士点 {{ school_extra_info.num_doctor }} 个
                                                            </p>
                                                            <p style="margin: 0; font-weight: 500">
                                                                二级学科博士点 {{ school_extra_info.num_doctor2 }} 个
                                                            </p>
                                                        </div>
                                                    </div>
                                                </template>
                                            </el-popover>
                                        </div>
                                    </div>
                                </div>
                                <div style="line-height: 180%;margin-top: 15px;">
                                    {{ school_extra_info.content }}...<span
                                    style="margin-left:3px;color:red;cursor:pointer"
                                    @click="show_more_school_info">更多</span>
                                </div>
                                <div style="line-height: 180%;margin-top: 15px;">
                                    <div style="display: flex;justify-content: flex-start">
                                        <div style="margin-right: 10px;"><span class="iconfont_small">&#xe60a;</span>&nbsp;&nbsp;{{
                                                school_extra_info.address
                                            }}
                                        </div>
                                        <div style="margin-right: 10px;"><span class="iconfont_small">&#xe604;</span>&nbsp;&nbsp;{{
                                                school_extra_info.phone
                                            }}
                                        </div>
                                        <div style="margin-right: 10px;">
                                            <span class="iconfont_small">&#xe624;</span>&nbsp;&nbsp;<a target="_blank"
                                                                                                       :href="school_extra_info.school_site">{{
                                                school_extra_info.school_site
                                            }}</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="school_detail">
                            <div style="width: 100%;">
                                <div style="margin-bottom: 40px;" v-if="short_enroll_list_info.length > 0">
                                    <div style="margin-bottom: 20px;"><h4>招生简章</h4></div>
                                    <div>
                                        <el-table :data="short_enroll_list_info" stripe style="width: 100%"
                                                  :empty-text="short_enroll_list_empty">
                                            <el-table-column prop="title" label="招生信息">
                                                <template #default="scope">
                                                    <span style="cursor:pointer;"
                                                          @click="show_enroll_info(scope.row.id)">{{
                                                            scope.row.title
                                                        }}</span>
                                                </template>
                                            </el-table-column>
                                        </el-table>
                                    </div>
                                </div>
                                <div style="margin-bottom: 40px;" v-if="first_class_info.length > 0">
                                    <div style="margin-bottom: 20px;"><h4>一流学科</h4></div>
                                    <div>
                                        <el-table :data="first_class_info" border style="width: 100%"
                                                  :empty-text="first_class_empty">
                                            <el-table-column prop="name" label="专业"/>
                                            <el-table-column prop="code" label="专业代码"/>
                                        </el-table>
                                    </div>
                                </div>
                                <div style="margin-bottom: 40px;" v-if="subject_review_info.length > 0">
                                    <div style="margin-bottom: 20px;"><h4>学科评估</h4></div>
                                    <div>
                                        <el-table :data="subject_review_info" border style="width: 100%"
                                                  :empty-text="subject_review_empty">
                                            <el-table-column prop="assess" label="评级" width="180"/>
                                            <el-table-column prop="major_name" label="学科名称"/>
                                        </el-table>
                                    </div>
                                </div>
                                <div style="margin-bottom: 40px;" v-if="special_major_info.length > 0">
                                    <div style="margin-bottom: 20px;"><h4>国家特色专业</h4></div>
                                    <div>
                                        <el-table :data="special_major_info" border style="width: 100%"
                                                  :empty-text="special_major_empty">
                                            <el-table-column prop="type" label="批次" width="180"/>
                                            <el-table-column prop="major_name" label="专业名称"/>
                                        </el-table>
                                    </div>
                                </div>
                                <div style="margin-bottom: 40px;" v-if="department_info.length > 0">
                                    <div style="margin-bottom: 20px;"><h4 style="display: inline-block">开设专业</h4><h5
                                        style="display: inline-block;margin-left: 10px;">
                                        注:（硕）（博）分别表示本校有相关专业的硕士点和博士点</h5></div>
                                    <div>
                                        <el-table :data="department_info" border style="width: 100%"
                                                  :empty-text="department_empty">
                                            <el-table-column prop="l2_name" label="类别" width="200"/>
                                            <el-table-column prop="major_name" label="专业">
                                                <template #default="scope">
                                                    <div v-html="scope.row.major_name"></div>
                                                </template>
                                            </el-table-column>
                                        </el-table>
                                    </div>
                                </div>
                                <div>
                                </div>
                            </div>

                        </div>
                    </div>
                </el-tab-pane>
                <el-tab-pane label="招生简章" name="recruit">
                    <div v-if="show_enroll_detail">
                        <div style="margin: 20px auto;width: 100%;text-align: center">
                            <h3 style="display: inline-block">{{ enroll_detail_title }}</h3>
                        </div>
                        <div v-html="enroll_detail"></div>
                    </div>
                    <div v-else>
                        <div>
                            <div style="display: inline-block;margin-right: 10px;">共 {{ enroll_list_info.length }} 条
                            </div>
                            <el-input v-model="search_recruit" placeholder="请输入内容" size="small" clearable
                                      style="width:400px;margin-right: 10px;"></el-input>
                            <el-button @click="search_recruit_btn" size="small" type="primary">搜索</el-button>
                        </div>
                        <div>
                            <el-table :data="enroll_list_info" stripe style="width: 100%"
                                      :empty-text="enroll_list_empty">
                                <el-table-column prop="title" label="招生信息">
                                    <template #default="scope">
                                        <span style="cursor:pointer;"
                                              @click="show_enroll_info(scope.row.id)">{{ scope.row.title }}</span>
                                    </template>
                                </el-table-column>
                            </el-table>
                        </div>
                    </div>
                </el-tab-pane>
                <el-tab-pane label="录取分数线" name="score">
                    <div>
                        <div style="display: inline-block;margin-right: 10px;">请选择招生类别</div>
                        <el-select v-model="score_class" placeholder="请选择招生类别" @change="score_class_change"
                                   clearable>
                            <el-option label="物理组" value="2"/>
                            <el-option label="历史组" value="3"/>
                        </el-select>
                    </div>
                    <div>
                        <el-table :data="score_list" stripe style="width: 100%" :empty-text="score_empty">
                            <el-table-column prop="year" label="年份"/>
                            <el-table-column prop="class_name" label="招生类型"/>
                            <el-table-column prop="batch_name" label="批次"/>
                            <el-table-column prop="type_name" label="招生类别"/>
                            <el-table-column prop="low_score" label="最低分"/>
                        </el-table>
                    </div>
                </el-tab-pane>
                <el-tab-pane label="招生计划" name="plan">
                    <el-card class="box-card" v-for="(item, index) in plans_info">
                        <div style="display: flex">
                            <div class="collection">
                                <div v-if="item.match_status===1" class="major_sprint">{{ item.match_str }}</div>
                                <div v-else-if="item.match_status===2" class="major_stable">{{ item.match_str }}</div>
                                <div v-else class="major_floors">{{ item.match_str }}</div>
                                <div style="height:60px;width:90px;line-height: 60px;text-align: center;">
                                <span v-if="item.collection === 1"
                                      style="color: #F2F2F2;cursor: pointer;background-color: #C23B1E;padding: 5px 7px;border-radius: 3px;font-size: 14px;"
                                      @click="un_favorite(index, item, 1)">取消选择</span>
                                    <span v-else>
                                  <span v-if="item.major_limit == 1"
                                        style="cursor: pointer;border: 1px #C23B1E solid;padding: 5px 7px;border-radius: 3px;font-size: 14px;"
                                        @click="favorite(index, item, 1)">选择</span>
                                  <span v-else @click="no_match_major"
                                        style="float: right;padding-right: 20px;cursor: pointer;color: #999999">选择</span>
                                </span>
                                </div>
                                <div style="font-size: 12px;text-align: center;width: 100%;font-weight: bold">
                                    {{ item.batch_name }}
                                </div>
                            </div>
                            <div style="margin-top:10px;margin-bottom:10px;width: 30%;">
                                <div style="text-align: center;cursor: pointer;"
                                     @click="school_info(item.school_id, item.school_name)">
                                    {{ item.school_name }}[{{ item.nature_str }}]
                                    <span v-if="item.school_guarantee_rate !== ''">[{{
                                            item.school_guarantee_rate
                                        }}]</span>
                                    <span v-else-if="item.postgraduate_str !== ''">[{{ item.postgraduate_str }}]</span>
                                </div>
                                <div v-if="item.school_pre"
                                     style="text-align: center;font-size: 12px;margin: 0 auto;padding: 5px;">
                                    历史沿革: {{ item.school_pre }}
                                </div>
                                <div class="school_tag">
                                    <el-tag size="mini" type="success" v-for="(label, index) in item.label_list"
                                            :key="index">
                                        {{ label }}
                                    </el-tag>
                                </div>
                                <div style="text-align: center;font-size: 12px;cursor:pointer;"
                                     @click="school_map(item.school_name)">
                                    <span class="iconfont_small">&#xe64b;</span>{{ item.address }}
                                </div>
                            </div>
                            <div style="margin-top:10px;margin-bottom:10px;width: 40%;">
                                <div class="recruit_tag" style="text-align: left;">
                                    <div v-if="item.majors" @click="show_majors_job(item.majors_list)">
                                        {{ item.majors }}
                                        <span :class="item.collection===1?'collection_new':'un_collection_new'"
                                              v-if="item.new_major === 1">新</span>
                                        {{ item.type_str }}
                                        <el-popover placement="top-start" :width="200" trigger="hover"
                                                    v-if="batch !== 'A' && from !== 3"
                                                    :content="system_tips_list[2]['name']">
                                            <template #reference>
                                                <el-tag size="mini">{{ system_tips_list[2]['name'] }}:{{
                                                        item.use_sum
                                                    }}
                                                </el-tag>
                                            </template>
                                        </el-popover>
                                        <el-popover placement="top-start" :width="200" trigger="hover"
                                                    :content="system_tips_list[1]['name']">
                                            <template #reference>
                                                <el-tag size="mini">{{
                                                        system_tips_list[1]['name']
                                                    }}:{{ item.forecast_low_score }}
                                                </el-tag>
                                            </template>
                                        </el-popover>
                                    </div>
                                    <span
                                        style="border: #898989 1px dashed;border-radius: 5px;margin-right: 20px;margin-bottom:5px;padding-right: 5px;min-width: 250px;display: inline-block"
                                        v-if="item.foreign_rules || item.foreign_study_mode || item.foreign_universities">
                                        <span style="display: inline-block;">
                                            <span v-if="item.foreign_universities"
                                                  style="font-size:12px;margin-bottom: 5px;display:inline-block;">合作院校:
                                                {{ item.foreign_universities }}
                                            </span>
                                            <span v-if="item.foreign_study_mode"
                                                  style="font-size:12px;margin-bottom: 5px;color: #e8320a;display:inline-block;">就读模式:
                                                {{ item.foreign_study_mode }}
                                            </span>
                                            <span v-if="item.foreign_rules"
                                                  style="font-size:14px;display:inline-block;">
                                                <a target="_blank" :href="item.foreign_rules">查看招生简章</a>
                                            </span>
                                        </span>
                                    </span>
                                </div>
                                <div class="school_tag" style="text-align: left">
                                    <el-tag size="mini" type="danger"
                                            v-if="item.discipline_ranking_major_str !== ''">
                                        {{ item.discipline_ranking_major_str }}
                                    </el-tag>
                                    <el-tag size="mini" type="danger" v-if="item.postgraduate_major_str !== ''">
                                        {{ item.postgraduate_major_str }}
                                    </el-tag>
                                    <el-tag size="mini" type="danger" v-if="item.doctor_major_str !== ''">{{
                                            item.doctor_major_str
                                        }}
                                    </el-tag>
                                    <el-tag size="mini" type="danger" v-if="item.first_class_major_str !== ''">{{
                                            item.first_class_major_str
                                        }}
                                    </el-tag>
                                    <el-tag size="mini" type="danger" style="margin-right: 5px;"
                                            v-if="item.nation_feature !== undefined && item.nation_feature !== ''">
                                        {{ item.nation_feature }}
                                    </el-tag>
                                    <el-tag size="mini" type="danger" style="margin-right: 5px;"
                                            v-if="item.double_first_class_level !== undefined && item.double_first_class_level !== ''">
                                        {{ item.double_first_class_level }}
                                    </el-tag>
                                </div>
                                <div>
                                    <div
                                        style="display:flex;justify-content: start;align-content: center;font-size: 12px;color:#666666;">
                                        <div class="major_info_desc">
                                            <span>人数</span>|<span>{{ item.num }}</span>
                                        </div>
                                        <div class="major_info_desc">
                                            <span>学年</span>|<span>{{ item.length }}</span>
                                        </div>
                                        <div class="major_info_desc">
                                            <span>学费</span>|<span v-if="item.fees >= 10000"
                                                                    style="color: red;">{{ item.fees }}</span>
                                            <span v-else>{{ item.fees }}</span>
                                        </div>
                                        <div class="major_info_desc">
                                            <span style="float: left;padding-right: 5px;"><span>选科</span>|<span>{{
                                                    item.major_group_rule
                                                }}</span></span>
                                            <span v-if="item.major_limit === 1"
                                                  style="float: right;padding-right: 5px;color: #24C599">选科匹配</span>
                                            <span v-else
                                                  style="float: right;padding-right: 5px;color: #999999">选科不匹配</span>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <div style="display: inline-block;font-size: 12px;" v-html="item.remarks"></div>
                                </div>
                            </div>
                            <div class="major_list" style="border-left: 1px #EBEBEB solid;">
                                <div>
                                    <div style="font-size: 14px;">统计 ( 录取人数 / <span v-if="batch !== 'B'">线差 /</span> 最低分 / 最低位次 )</div>
                                    <div>
                                        <div
                                            style="height: 20px;line-height: 20px;text-align: left;font-size: 12px;color:#666666;"
                                            v-for="(info, year) in item.historical_data">
                                            <div>
                                                ({{ year }})
                                                {{ info.number_basics }}&nbsp;/&nbsp;
                                                <span v-if="batch !== 'B'">{{ info.line_diff_basics }}&nbsp;/&nbsp;</span>
                                                {{ info.low_score_basics }}&nbsp;/&nbsp;
                                                {{ info.precedence_basics }}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </el-card>
                </el-tab-pane>
            </el-tabs>
        </div>
    </article>
    <footer style="background-color: #F8F8F8;width:100%;height:167px;margin-top: 75px;">
        <div style="color: #999999 ;text-align: center;padding-top: 60px;">
            Copyright @ 2011-2021 茗硕择嘉（河北）教育科技有限公司 All Rights Reserved. 版权所有<br><br>
            备案号：冀ICP备17013465号-1
        </div>
    </footer>
    <el-dialog v-model="favorites_status" title="专业特别提示" :show-close="false" width="30%"
               :before-close="handleClose">
        <div style="font-size: 16px;margin-left: 35px;margin-bottom:15px;" v-for="item in major_remind_list">{{
                item
            }}
        </div>
        <template #footer>
			<span class="dialog-footer">
				<el-button @click="favorites_status = false">放弃</el-button>
				<el-button type="primary" @click="continue_favorite">继续选择</el-button>
			</span>
        </template>
    </el-dialog>
</template>
<script>
import env from '@/utils/env'
import Header from '@/components/Header.vue'
import "@/assets/css/iconfont.css"
import axios from "axios";
import api from "@/api";
import AMapLoader from '@amap/amap-jsapi-loader';
import {shallowRef} from '@vue/reactivity'
import 'default-passive-events';
import belong from '@/assets/schoolinfo/belong.png'
import bo from '@/assets/schoolinfo/bo.png'
import create from '@/assets/schoolinfo/create.png'
import gongxin from '@/assets/schoolinfo/gongxin.png'
import local from '@/assets/schoolinfo/local.png'
import native from '@/assets/schoolinfo/native.png'
import shuo from '@/assets/schoolinfo/shuo.png'
import type from '@/assets/schoolinfo/type.png'
import "@/assets/css/iconfont.css"
import http from "@/utils/http";

export default {
    name: 'SchoolInfo',
    components: {Header},
    setup() {
        const map = shallowRef(null);
        return {
            map,
        }
    },
    data() {
        return {
            url: env[process.env.NODE_ENV].JSON_SITE,
            vocation_api: env[process.env.NODE_ENV].VOCATION_API,
            ben_api: env[process.env.NODE_ENV].API,
            arts_api: env[process.env.NODE_ENV].ART_API,
            preb_api: env[process.env.NODE_ENV].PRE_API,
            favorites_index: 0,
            favorites_list: [],
            favorites_type: 1,
            major_remind_list: [],
            favorites_status: false,
            school_type: 'plan',
            search_recruit: '',
            school_class: '',
            score_class: '',
            school_id: 0,
            belong: belong,
            bo: bo,
            create: create,
            gongxin: gongxin,
            local: local,
            native: native,
            shuo: shuo,
            type: type,
            school_extra_info: [],
            enroll_list_info: [],
            short_enroll_list_info: [],
            first_class_info: [],
            special_major_info: [],
            subject_review_info: [],
            department_info: [],
            jobs_report_info: [],
            score_list: [],
            plans_info: [],
            plans_empty: '加载中...',
            score_empty: '加载中...',
            enroll_list_empty: '加载中...',
            department_empty: '加载中...',
            special_major_empty: '加载中...',
            subject_review_empty: '加载中...',
            first_class_empty: '加载中...',
            short_enroll_list_empty: '加载中...',
            show_school_info: false,
            more_school_info: '',
            show_enroll_detail: false,
            enroll_detail: '',
            school_name: '',
            from: 1,
            page: 1,
            batch: '',
            system_tips_list: {1: {name: '同位次分'}, 2: {name: '上段位变化量'}, 3: {name: '本校招生变化量'}},
            tableData: [
                {
                    date: '2016-05-03',
                    name: 'Tom',
                    address: 'No. 189, Grove St, Los Angeles',
                },
                {
                    date: '2016-05-02',
                    name: 'Tom',
                    address: 'No. 189, Grove St, Los Angeles',
                },
                {
                    date: '2016-05-04',
                    name: 'Tom',
                    address: 'No. 189, Grove St, Los Angeles',
                },
                {
                    date: '2016-05-01',
                    name: 'Tom',
                    address: 'No. 189, Grove St, Los Angeles',
                },
            ]
        }
    },
    // beforeRouteEnter(to, from, next) {
    //
    //     console.log(to)
    //     console.log(from)
    //     console.log(next)
    //
    //     next(vm => {
    //
    //     });
    // },
    mounted() {
        let query = this.$route.query;
        if (query.school_id !== undefined) {
            this.school_id = parseInt(query.school_id)
            this.school_name = query.school_name
            this.from = parseInt(query.from)
            this.page = query.page || 1
            if (this.from === 2) {
                this.batch = query.batch
            }

            this.school_extra()
            this.enroll_list()
            this.first_class()
            this.special_major()
            this.subject_review()
            this.department()
            this.jobs_report()
            this.plans(this.from)
            this.scores()
        }

        this.initMap(this.school_name)
    },
    destroyed() {
        //window.removeEventListener('popstate', this.popstate, false);
    },
    created() {
        //window.addEventListener('popstate', this.popstate, false);
        console.log(this.$router)
    },
    methods: {
        popstate(e) {
            console.log(e)
            console.log('发生跳转');
            return false
        },
        handleClose() {
        },
        initMap(school_name = '') {
            let that = this
            AMapLoader.load({
                key: "8471b39e4309c73de9e81395039d1b01",             // 申请好的Web端开发者Key，首次调用 load 时必填
                version: "2.0",      // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
                plugins: ['AMap.PlaceSearch'],       // 需要使用的的插件列表，如比例尺'AMap.Scale'等
            }).then((AMap) => {
                const {Map, PlaceSearch} = AMap

                console.log(Map)
                console.log(PlaceSearch)

                const amap = new Map('gaodeditu', {
                    center: [120.980795, 31.385476],
                    zoom: 10,
                    resizeEnable: true
                });

                const placeSearch = new PlaceSearch({
                    pageSize: 10, // 单页显示结果条数
                    pageIndex: 1, // 页码
                    city: "010", // 兴趣点城市
                    citylimit: false,  //是否强制限制在设置的城市内搜索
                    map: amap, // 展现结果的地图实例
                    autoFitView: true // 是否自动调整地图视野使绘制的 Marker点都处于视口的可见范围
                });
                //关键字查询
                placeSearch.search(school_name, (status, result) => {
                    console.log(status);
                    console.log(result);
                });
            }).catch(e => {
                console.log(e);
            })
        },
        schoolTypeClick(pane, ev) {
            if (pane.instance.props.name === 'info' && this.show_school_info === true) {
                this.initMap(this.school_name)
                this.show_school_info = false
            }
            this.show_enroll_detail = false
        },
        show_enroll_info(id) {
            let that = this
            that.school_type = 'recruit'
            axios.get(
                api.school.enroll_info, {params: {'school_id': this.school_id, 'news_id': parseInt(id)}}
            ).then(function (res) {
                console.log(res)
                if (res.data.code === 10000) {
                    that.show_enroll_detail = !that.show_enroll_detail
                    that.enroll_detail = res.data.data.content
                    that.enroll_detail_title = res.data.data.title
                }
            }).catch(function (err) {
            });
        },
        show_enroll_detail_btn() {
            this.show_enroll_detail = !this.show_enroll_detail
        },
        show_base_school_info() {
            this.show_school_info = !this.show_school_info
        },
        show_more_school_info() {
            let that = this
            axios.get(
                api.school.base_info, {params: {'school_id': this.school_id}}
            ).then(function (res) {
                console.log(res)
                if (res.data.code === 10000) {
                    that.more_school_info = res.data.data.content
                    that.show_school_info = !that.show_school_info
                }
            }).catch(function (err) {
            });
        },
        school_extra() {
            let that = this
            axios.get(
                api.school.extra, {params: {'school_id': this.school_id}}
            ).then(function (res) {
                console.log(res)
                if (res.data.code === 10000) {
                    that.school_extra_info = res.data.data
                }
            }).catch(function (err) {
            });
        },
        score_class_change() {
            this.scores(parseInt(this.score_class))
        },
        scores(school_class = 0) {
            let that = this
            let params = {'school_id': this.school_id}
            if (school_class > 0) {
                params.class_type = school_class
            }
            axios.get(
                api.school.scores, {params: params}
            ).then(function (res) {
                console.log(res)
                if (res.data.code === 10000) {
                    that.score_list = res.data.data
                    if (that.score_list.length === 0) {
                        that.score_empty = '暂无数据'
                    }
                }
            }).catch(function (err) {
            });
        },
        search_recruit_btn() {
            this.enroll_list(this.search_recruit)
        },
        enroll_list(search_recruit = '') {
            let that = this
            axios.get(
                api.school.enroll_list, {params: {'school_id': this.school_id, 'search_recruit': search_recruit}}
            ).then(function (res) {
                console.log(res)
                if (res.data.code === 10000) {
                    that.enroll_list_info = res.data.data
                    that.short_enroll_list_info = that.enroll_list_info.slice(0, 9)
                    if (that.enroll_list_info.length === 0) {
                        that.enroll_list_empty = '暂无数据'
                    }
                }
            }).catch(function (err) {
            });
        },
        first_class() {
            let that = this
            axios.get(
                api.school.first_class, {params: {'school_id': this.school_id}}
            ).then(function (res) {
                console.log(res)
                if (res.data.code === 10000) {
                    that.first_class_info = res.data.data
                    if (that.first_class_info.length === 0) {
                        that.first_class_empty = '暂无数据'
                    }
                }
            }).catch(function (err) {
            });
        },
        special_major() {
            let that = this
            axios.get(
                api.school.special_major, {params: {'school_id': this.school_id}}
            ).then(function (res) {
                console.log('国家特色专业')
                console.log(res)
                if (res.data.code === 10000) {
                    that.special_major_info = res.data.data
                    if (that.special_major_info.length === 0) {
                        that.special_major_empty = '暂无数据'
                    }
                }
            }).catch(function (err) {
            });
        },
        subject_review() {
            let that = this
            axios.get(
                api.school.subject_review, {params: {'school_id': this.school_id}}
            ).then(function (res) {
                console.log(res)
                if (res.data.code === 10000) {
                    that.subject_review_info = res.data.data
                    if (that.subject_review_info.length === 0) {
                        that.subject_review_empty = '暂无数据'
                    }
                }
            }).catch(function (err) {
            });
        },
        department() {
            let that = this
            axios.get(
                api.school.department, {params: {'school_id': this.school_id}}
            ).then(function (res) {
                console.log('开设专业')
                console.log(res)
                if (res.data.code === 10000) {
                    that.department_info = res.data.data
                    if (that.department_info.length === 0) {
                        that.department_empty = '暂无数据'
                    }
                }
            }).catch(function (err) {
            });
        },
        jobs_report() {
            let that = this
            axios.get(
                api.school.jobs_report, {params: {'school_id': this.school_id}}
            ).then(function (res) {
                console.log(res)
                if (res.data.code === 10000) {
                    that.jobs_report_info = res.data.data
                }
            }).catch(function (err) {
            });
        },
        school_info(school_id, school_name) {
            //this.$router.push({path: '/school_info', query: {school_id: school_id, school_name: school_name}})
        },
        school_map(school_name) {
            //this.$router.push({path: '/school_map', query: {school_name: school_name}})

            const routeData = this.$router.resolve({path: '/school_map', query: {school_name: school_name}})
            window.open(routeData.href, '_blank')
        },
        school_class_change() {
            this.plans(parseInt(this.school_class))
        },
        plans(from = 0) {
            let that = this
            let params = {'school_id': this.school_id}
            params.from = from
            axios.get(
                api.school.plans, {params: params}
            ).then(function (res) {
                console.log(res)
                if (res.data.code === 10000) {
                    that.plans_info = res.data.data
                    if (that.plans_info.length === 0) {
                        that.plans_empty = '暂无数据'
                    }
                }
            }).catch(function (err) {
            });
        },
        continue_favorite() {
            let index = this.favorites_index
            let list = this.favorites_list
            let type = this.favorites_type

            let url = this.ben_api
            if (this.from === 1) {
                url = this.ben_api
            } else if (this.from === 2) {
                url = this.preb_api
            } else if (this.from === 3) {
                url = this.vocation_api
            } else if (this.from === 4) {
                url = this.arts_api
            }

            let data = http.post(url + api.user.favorite, {
                "favorite_id": list.id
            })
            data.then(data => {
                console.log(data)
                console.log(data.data)
                if (data.code === 10000) {
                    if (type === 1) {
                        this.plans_info[index]['collection'] = 1
                    } else {
                        this.plans_info[index]['collection'] = 1
                    }
                    this.$notify({
                        title: '成功',
                        message: '选择成功',
                        type: 'success',
                        offset: 50,
                    });
                    this.favorites_status = false
                } else {
                    this.$notify.error({
                        title: '错误',
                        message: data.data.msg,
                        offset: 50,
                    });
                    this.favorites_status = false
                }
            })
        },
        favorite(index, list, type = 1) {
            let url = this.ben_api
            if (this.from === 1) {
                url = this.ben_api
            } else if (this.from === 2) {
                url = this.preb_api
            } else if (this.from === 3) {
                url = this.vocation_api
            } else if (this.from === 4) {
                url = this.arts_api
            }

            console.log(url)

            if (list.collection === 1) {
                this.un_favorite(index, list, type)
            } else {
                if (list.major_remind_desc !== null && list.major_remind_desc.length > 0) {
                    this.favorites_index = index
                    this.favorites_list = list
                    this.favorites_type = type
                    this.favorites_status = true
                    this.major_remind_list = list.major_remind_desc
                } else {
                    this.major_remind_list = []

                    let data = http.post(url + api.user.favorite, {
                        "favorite_id": list.plan_id
                    })
                    data.then(data => {
                        console.log(data)
                        console.log(data.data.sum)
                        if (data.code === 10000) {
                            this.plans_info[index]['collection'] = 1
                            this.$notify({
                                title: '成功',
                                message: '选择成功',
                                type: 'success',
                                offset: 50,
                            });
                        } else {
                            this.$notify.error({
                                title: '错误',
                                message: data.data.msg,
                                offset: 50,
                            });
                        }
                    })
                }
            }
            console.log(list.id)
        },
        un_favorite(index, list, type = 1) {
            let url = this.ben_api
            if (this.from === 1) {
                url = this.ben_api
            } else if (this.from === 2) {
                url = this.preb_api
            } else if (this.from === 3) {
                url = this.vocation_api
            } else if (this.from === 4) {
                url = this.arts_api
            }

            let that = this
            console.log(list.id)
            let data = http.post(url + api.user.un_favorite, {
                "favorite_id": list.id,
                "type": 1,
            })
            data.then(data => {
                console.log(data)
                console.log(data.data)
                if (data.code === 10000) {
                    if (type === 1) {
                        this.plans_info[index]['collection'] = 0
                    } else {
                        this.plans_info[index]['collection'] = 0
                    }
                    this.$notify({
                        title: '成功',
                        message: '取消选择成功',
                        type: 'success',
                        offset: 50,
                    });
                } else {
                    this.$notify.error({
                        title: '错误',
                        message: data.data.msg,
                        offset: 50,
                    });
                }
            })
        },
    }
}
</script>
<style scoped>
* {
    font-family: 'Microsoft YaHei', 'Helvetica Neue', Helvetica, 'PingFang SC', 'Hiragino Sans GB',
    '微软雅黑', Arial, sans-serif;
}

#gaodeditu {
    padding: 0px;
    margin: 0px;
    width: 100%;
    height: 800px;
}

.container {
    background-color: #FFFFFF;
    width: 1350px;
    margin: 70px auto 0;
}

.school_header {
    height: 100px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 15px;
    margin-top: 15px;
}

.school_label {
    margin-top: 10px;
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.school_info {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
}

.school_detail {
    margin-top: 40px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}

.recruit_list {
    height: 50px;
    list-style-type: none;
    display: flex;
    justify-content: space-around;
    align-items: center;
    border-bottom: #24C599 1px solid;
}

.school_logo {
    width: 80px;
    height: 80px;
}

.collection {
    width: 90px;
    text-align: center;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.un_collection {
    width: 90px;
    text-align: center;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.major_sprint {
    background-color: #F3AB50;
    height: 40px;
    width: 40px;
    line-height: 40px;
    text-align: center;
    color: white;
    margin: 0 auto;
    border-radius: 50%;
}

.major_floors {
    background-color: #24C599;
    height: 40px;
    width: 40px;
    line-height: 40px;
    text-align: center;
    color: white;
    margin: 0 auto;
    border-radius: 50%;
}

.major_stable {
    background-color: #4D94F1;
    height: 40px;
    width: 40px;
    line-height: 40px;
    text-align: center;
    color: white;
    margin: 0 auto;
    border-radius: 50%;
}

.major_info_desc {
    background-color: #F8F8F8;
    border-radius: 5px;
    margin-top: 5px;
    height: 25px;
    line-height: 25px;
    text-align: center;
    padding: 0 10px;
    font-size: 12px;
    margin-right: 5px;
}

.major_list {
    margin-top: 10px;
    padding-left: 20px;
    width: 450px;
}

.school_tag {
    text-align: center;
}

.school_tag >>> .el-tag--light {
    margin-left: 0px;
    margin-right: 5px;
    margin-bottom: 5px;
}

.recruit_tag >>> .el-tag--light {
    margin-left: 0px;
    margin-right: 5px;
    margin-bottom: 5px;
}


.box-card {
    border: 0;
    margin: 5px auto;
}

.box-card >>> .el-card__body {
    padding: 0;
}

.box-card >>> .el-card__header {
    padding: 0;
}

.collection_new {
    color: #F2F2F2;
    font-size: 16px;
}

.un_collection_new {
    color: #e8320a;
    font-size: 16px;
}

</style>
<style>
.demo-tabs .el-tabs__item {
    color: #666666;
    border: 0;
    text-align: center;
    padding: 0;
    width: 120px;
    font-size: 16px;
}

.demo-tabs .is-active {
    color: #ffffff;
    background-color: #ba0806;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    text-align: center;
    width: 120px;
    padding: 0;
    font-size: 16px;
}

.demo-tabs .el-tabs__active-bar {
    background-color: #ba0806;
    width: 120px;
}

.demo-tabs .el-tabs__nav-wrap::after {
    background-color: #ba0806;
}
</style>

