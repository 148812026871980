<template>
    <Header chose_index="10"></Header>
    <article class="container">
        <el-tabs class="demo-tabs" v-model="activeName" @tab-click="handleClick">
            <el-tab-pane label="搜索条件" name="first">
                <el-form ref="form" :model="form" label-width="80px" @submit.native.prevent>
                    <el-form-item label="院校省份">
                        <el-checkbox-group v-model="form.provinces" @change="province_change($event)">
                            <el-checkbox :label="item.province_id" :key="item.id" v-for="item in provinces">
                                {{ item.short }}
                            </el-checkbox>
                        </el-checkbox-group>
                    </el-form-item>
                    <el-form-item label="院校类型">
                        <el-checkbox-group v-model="form.educational_type" @change="type_change($event)">
                            <el-checkbox :label="item.id" :key="item.id" v-for="item in educational_type">
                                {{ item.name }}
                            </el-checkbox>
                        </el-checkbox-group>
                    </el-form-item>
                    <el-form-item label="院校层次">
                        <el-checkbox-group v-model="form.school_level" @change="level_change($event)">
                            <el-checkbox :label="item.id" :key="item.id" v-for="item in school_level">{{
                                    item.name
                                }}
                            </el-checkbox>
                        </el-checkbox-group>
                    </el-form-item>
                    <el-form-item label="院校性质">
                        <el-checkbox-group v-model="form.nature_type" @change="nature_type_change($event)">
                            <el-checkbox :label="item.id" :key="item.id" v-for="item in nature_type">{{
                                    item.name
                                }}
                            </el-checkbox>
                        </el-checkbox-group>
                    </el-form-item>
                    <!--                    <el-form-item label="招生类型">-->
                    <!--                        <el-checkbox-group v-model="form.recruit_type" @change="recruit_type_change($event)">-->
                    <!--                            <el-checkbox :label="item.id" :key="item.id" v-for="item in recruit_type">{{-->
                    <!--                                    item.name-->
                    <!--                                }}-->
                    <!--                            </el-checkbox>-->
                    <!--                        </el-checkbox-group>-->
                    <!--                    </el-form-item>-->
                    <el-form-item label="意向院校">
                        <el-select-v2 v-model="form.school_ids" filterable :options="school_list" placeholder="请选择"
                                      style="min-width: 500px;" @change="school_list_change($event)" clearable
                                      multiple/>
                    </el-form-item>
                </el-form>
            </el-tab-pane>
        </el-tabs>
        <div style="clear: both"></div>
        <el-card class="box-card" v-for="(item, index) in tableData">
            <div style="display: flex;justify-content: flex-start;align-items: center;">
                <img style="margin-left:10px;width:80px;height:80px;cursor:pointer;"
                     @click="school_info(item.school_id, item.school_name)"
                     :src="item['img_url'] + item.logo"/>
                <div style="margin-left: 30px;width: 450px;">
                    <div>
                        <span
                            style="cursor:pointer;"
                            @click="school_info(item.school_id, item.school_name)"
                        >
                            {{ item.school_name }}<span v-if="item.level.length > 0">[{{ item.level }}]</span>&nbsp;&nbsp;{{
                                item.subject_attribute
                            }}&nbsp;
                        </span>
                        <span v-if="item.postgraduate.length > 0">[{{ item.postgraduate }}]</span>
                    </div>
                    <div style="margin-top: 10px;">
                        <el-tag
                            style="margin: 5px 5px 5px 0;"
                            size="mini"
                            type="success"
                            v-for="(label, index) in item.label"
                            :key="index">
                            {{ label }}
                        </el-tag>
                    </div>
                </div>
                <div style="margin-left: 30px;width: 550px;">
                    <div style="font-size: 14px;cursor:pointer;">
                        <span @click="school_map(item.school_name)">
                            <span class="iconfont_small">&#xe64b;</span>
                            {{ item.address }}
                        </span>
                    </div>
                    <div v-if="item.school_pre.length > 0" style="font-size: 14px;margin-top: 10px;padding-left: 3px;">
                        历史沿革: {{ item.school_pre }}
                    </div>
                </div>
                <div style="width:80px;height: 80px;line-height: 80px;margin-right: 20px;text-align: center">
                    <el-button v-if="item.collection == 1"
                               size="small"
                               style="padding: 0 10px;background-color: #ba0806;border: 1px #ba0806 solid; color: white;"
                               type="danger"
                               @click="collection(item.id, index, 'd')" plain>
                        已选择
                    </el-button>
                    <el-button v-else
                               style="padding: 0 10px;border: 1px #ba0806 solid; color: #ba0806;"
                               type="text"
                               size="small"
                               @click="collection(item.id, index, 'c')" plain>
                        选择
                    </el-button>
                </div>
            </div>
        </el-card>
        <div style="height: 20px;"></div>
        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                       v-model:currentPage="currentPage" :page-sizes="[10, 20, 50, 100]" :page-size="form.page_size"
                       layout="total, sizes, prev, pager, next, jumper" :total="form.total">
        </el-pagination>
    </article>
    <Footer></Footer>
</template>

<script>
import logo from '@/assets/logo.png';
import miniapp from '@/assets/miniapp.jpg';
import * as echarts from "echarts";
import Sortable from 'sortablejs'
import http from '@/utils/http'
import api from '@/api'
import EditInfo from "../components/EditInfo";
import Header from "../components/Header";
import axios from "axios";
import env from '@/utils/env'
import {ElLoading, ElMessage, ElMessageBox, ElNotification} from 'element-plus'
import "@/assets/css/iconfont.css"
import Footer from '@/components/Footer'

export default {
    name: 'ChoseSchool',
    components: {
        Header,
        EditInfo,
        Footer
    },
    data() {
        return {
            is_show_collection: true,
            show_collection_str: '显示已收藏的专业',
            json_api: env[process.env.NODE_ENV].JSON_SITE,
            search_type: 'major',
            logo: logo,
            miniapp: miniapp,
            favorite_status: false,
            show_majors_job_info: false,
            majors_job_info: [],
            majors_job_list: [],
            currentPage: 1,
            favorite_need_vip: false,
            show_alert: true,
            favorite_index: 0,
            favorite_list: [],
            favorite_type: 1,
            major_remind_list: [],
            need_vip: false,
            show_edit_info: 0, // 显示编辑个人信息对话框
            marks: [],
            score: 0,
            vip_level: 0,
            modify_score_times: 0,
            all_num: 0,
            top: 0,
            submit_times: 0,
            top_num: 0,
            middle_num: 0,
            bottom_num: 0,
            bottom: 0,
            un_plan_ids: [],
            plan_ids: [],
            label_num: [],
            dialogVisible: false,
            dialogMajorsVisible: false,
            activeName: 'first',
            listName: 'first',
            tags: {},
            plans: {},
            form: {
                provinces: [],
                type: [],
                educational_type: [],
                level: [],
                school_level: [],
                recruit_type: [],
                nature_type: [],
                school_ids: [],
                follow_major: [],
                major_one: 0,
                level_one: 0,
                major_two: [],
                level_two: [],
                page_size: 10,
                total: 0,
                page: 1,
                search_type: 1,
                score_range: 2,
            },
            majorsData: [],
            formLabelWidth: '120px',
            dialogFormVisible: false,
            provinces: [],
            types: [],
            schools: [],
            system_tips_list: {1: {name: '等效分'}, 2: {name: '上段位变化量'}, 3: {name: '本校招生变化量'}},
            major_one: [],
            major_two: [],
            major_three: [],
            major_three_list: [],
            majors: [],
            school_list: [],
            level: [],
            educational_type: [],
            nature_type: [],
            recruit_type: [],
            school_level: [],
            tableData: [],
            base_info: [],
            //
            old_nature_type: [],
            old_recruit_type: [],
            old_educational_type: [],
            old_school_level: [],
            old_provinces: [],
            old_school_list: [],
            old_follow_major: [],
            old_major_two: [],
            major_list: [],
            school_first: false,
            major_first: true,
            tag_index: 0,
            collection_list: [],
            un_collection_list: [],
            collection_total: 0,
            loading: null,
            guarantee: 0,
            impulse: 0,
            stability: 0,
            f_total: 0,
            show_major_label: 0,
            submit_report: 0
        };
    },
    mounted() {
        this.loading = ElLoading.service({
            lock: true,
            text: 'Loading',
            background: 'rgba(0, 0, 0, 0.7)',
        })
    },
    created() {
        // this.search_param()
        this.get_school_json()
        this.get_school_label_json()
        this.get_province_json()
        this.change()
        this.user_info()
    },
    methods: {
        get_power() {
            let that = this

            let teacher_login = localStorage.getItem("teacher_login")
            if(teacher_login && parseInt(teacher_login) === 1){
                that.show_major_label = 1
                that.submit_report = 1
                return false
            }

            axios.get(
                api.user.power, {}
            ).then(function (res) {
                console.log(res)
                if (res.data.code === 10000) {
                    console.log(res.data.data)
                    that.show_major_label = res.data.data.show_major_label
                    that.submit_report = res.data.data.submit_report
                }
            }).catch(function (err) {
            });
        },
        majors_job_info_close() {
            this.show_majors_job_info = !this.show_majors_job_info
            this.majors_job_info = []
            this.majors_job_list = []
        },
        show_majors_job(item) {
            let that = this

            this.show_majors_job_info = !this.show_majors_job_info
            this.majors_job_info = item

            console.log(item)
            let param = []
            for (let i in item) {
                param.push(item[i]['code'])
            }

            if (param.length < 1) {
                return false
            }
            axios.post(
                api.open_major.majors_info, {'major_codes': param}
            ).then(function (res) {
                console.log(res)
                if (res.data.code === 10000) {
                    console.log(res.data.data)
                    that.majors_job_list = res.data.data
                }
            }).catch(function (err) {
            });
        },
        collection(id, index, type = 'c') {
            let that = this
            axios.get(
                api.user.collection_school, {
                    params: {
                        'school_id': id,
                        'c_type': type,
                    }
                }
            ).then(function (res) {
                if (res.data.code === 10000) {
                    that.tableData[index]['collection'] = !that.tableData[index]['collection']
                    that.$notify({
                        title: '成功',
                        message: '信息录入成功',
                        type: 'success',
                        offset: 50,
                    });
                    //that.search_param()
                } else {
                    that.$notify.error({
                        title: '错误',
                        message: res.data.data.msg,
                        offset: 50,
                    });
                }
            }).catch(function (err) {
            });
        },
        search_param() {
            let that = this
            axios.get(api.user.search_param, {}).then(function (res) {
                console.log(res)
                if (res.data.code === 10000) {
                    console.log(res.data.data)
                    let provinces = res.data.data.provinces || []
                    let nature_type = res.data.data.nature_type || []
                    let educational_type = res.data.data.educational_type || []
                    let school_level = res.data.data.school_level || []
                    let recruit_type = res.data.data.recruit_type || []
                    //let school_ids = res.data.data.school_ids || []

                    for (let i in provinces) {
                        that.form.provinces.push(parseInt(provinces[i]))
                    }
                    for (let i in nature_type) {
                        that.form.nature_type.push(parseInt(nature_type[i]))
                    }
                    for (let i in educational_type) {
                        that.form.educational_type.push(parseInt(educational_type[i]))
                    }
                    for (let i in school_level) {
                        that.form.school_level.push(parseInt(school_level[i]))
                    }
                    for (let i in recruit_type) {
                        that.form.recruit_type.push(parseInt(recruit_type[i]))
                    }
                    // for (let i in school_ids) {
                    //     that.form.school_ids.push(school_ids[i])
                    // }

                    that.old_school_list = that.form.school_ids
                    that.old_educational_type = that.form.educational_type
                    that.old_school_level = that.form.school_level
                    that.old_recruit_type = that.form.recruit_type
                    that.old_nature_type = that.form.nature_type
                    that.old_provinces = that.form.provinces
                    that.old_major_two = that.form.level_two
                    that.old_follow_major = that.form.follow_major
                    //
                    that.get_school_json()
                    that.get_school_label_json()
                    that.get_province_json()
                    that.change()
                }
            }).catch(function (err) {
            });
        },
        handleClose() {
        },
        fatherMethod() {
            this.user_info()
            console.log('-----+++++---')
        },
        user_info() {
            let that = this
            axios.post(
                api.user.info, {}
            ).then(function (res) {
                console.log(res)
                console.log(res.data.data.status)
                console.log(res.data.data.base_info)
                if (res.data.data.base_info.score == '0') {
                    that.show_edit_info = 1
                } else if (res.data.data.base_info.vip_level >= 60 &&
                    res.data.data.base_info.need_more_info === 1) {
                    that.show_more_info++
                }

                that.group_id = res.data.data.group_id
                that.group_name = res.data.data.group_name
                that.level_name = res.data.data.level_name
                that.submit_times = res.data.data.base_info.submit_times
                that.subjects_name = res.data.data.base_info.subjects_name
                that.need_more_info = res.data.data.base_info.need_more_info
                that.modify_score_times = parseInt(res.data.data.base_info.modify_score_times)
                that.detailed_info_times = parseInt(res.data.data.base_info.detailed_info_times)
                if (res.data.data.base_info.show_recommend_rank == 1) {
                    that.recommend_ranking = false
                } else {
                    that.recommend_ranking = true
                }
                console.log('-------------------------------')
                console.log(that.recommend_ranking)
                that.vip_level = res.data.data.base_info.vip_level
                that.base_info = res.data.data.base_info
                that.user_status = res.data.data.status
                localStorage.setItem('base_info', JSON.stringify(res.data.data.base_info))
                localStorage.setItem('vip_level', res.data.data.base_info.vip_level)
                localStorage.setItem('group_name', res.data.data.group_name)
                localStorage.setItem('level_name', res.data.data.level_name)
                localStorage.setItem('submit_times', res.data.data.base_info.submit_times)
            }).catch(function (err) {
                that.$notify.error({
                    title: '错误',
                    message: err.response.data.msg,
                    offset: 50,
                });
            });
        },
        father_send_collection() {
            this.send_collection(4)
        },
        show_collection() {
            this.is_show_collection = !this.is_show_collection
            this.send_collection(0, 5, 1)
        },
        send_collection(type = 0, need_update = 0, get_list = 0) {
            // console.log(type)
            // console.log(need_update)
            // console.log(get_list)

            let that = this
            type = parseInt(type)
            if (type !== 4 && type !== 3 && type !== 5 && this.un_plan_ids.length < 1 && this.plan_ids.length < 1) {
                that.change()
                return false
            }
            axios.post(
                api.member.collect, {un_plan_ids: this.un_plan_ids, plan_ids: this.plan_ids}
            ).then(function (res) {
                if (type === 1 || type === 5) {
                    that.plan_ids = []
                    that.un_plan_ids = []
                }
                if (get_list === 1) {
                    that.change()
                }
                if (need_update === 1) {
                    that.get_collection()
                    that.favorites_class()
                }
                if (type === 3) {
                    that.plan_ids = []
                    that.un_plan_ids = []
                    that.approval_report()
                }
                if (type === 4) {
                    that.plan_ids = []
                    that.un_plan_ids = []
                    that.$router.push('/home_page')
                }
            }).catch(function (err) {
            });
        },
        favorites_class(type = 0) {
            let that = this
            axios.post(
                api.user.favorites_class, {}
            ).then(function (res) {
                console.log('----------------------------')
                console.log(res)
                that.guarantee = res.data.data.guarantee
                that.impulse = res.data.data.impulse
                that.stability = res.data.data.stability
                that.collection_total = res.data.data.total
            }).catch(function (err) {
            });
        },
        get_collection() {
            let that = this
            axios.get(
                api.member.collections, {}
            ).then(function (res) {
                that.collection_list = res.data.data.list
                that.collection_total = res.data.data.total
                that.plan_ids = that.collection_list
            }).catch(function (err) {
                // that.$notify.error({
                //     title: '错误',
                //     message: err.response.data.msg
                // });
            });
        },
        get_school_json() {
            let that = this
            axios.get(
                api.search_param.school_list, {}
            ).then(function (res) {
                // console.log(res.data.data)
                // console.log(that.form.school_ids)
                that.schools = res.data.data
                that.school_list = []
                for (let i in that.schools) {
                    that.school_list.push({
                        value: (that.schools[i]['id'] + ''),
                        label: that.schools[i]['name'] + '',
                        key: that.schools[i]['name'] + '',
                    })
                    if (that.form.school_ids.indexOf(i) > -1) {
                        // console.log(i)
                        that.tag_index++
                        that.tags[that.tag_index] = {
                            name: that.schools[i]['name'],
                            type: (that.schools[i]['id'] + ''),
                            class: 'school_list'
                        }
                    }
                }
            }).catch(function (err) {
                console.log(err)
                that.$notify.error({
                    title: '错误',
                    message: err.response.data.msg,
                    offset: 50,
                });
            });
        },
        get_major_json() {
            let that = this
            axios.get(
                api.search_param.majors, {}
            ).then(function (res) {
                console.log(res)
                console.log(that.form)
                that.major_one = res.data.data.level_one
                that.major_two = res.data.data.level_two
                that.major_three = res.data.data.level_three
                for (let i in res.data.data.level_three) {
                    let code = res.data.data.level_three[i]['code']
                    let name = res.data.data.level_three[i]['name']
                    that.major_three_list.push({
                        key: res.data.data.level_three[i]['id'],
                        value: code,
                        label: name,
                    })
                    if (that.form.follow_major.indexOf(code) > -1) {
                        // console.log(i)
                        that.tag_index++
                        that.tags[that.tag_index] = {
                            name: name,
                            type: code,
                            class: 'major_three'
                        }
                    }
                }
                for (let i in res.data.data.level_two) {
                    let code = res.data.data.level_two[i]['code']
                    let name = res.data.data.level_two[i]['name']
                    that.major_three_list.push({
                        key: res.data.data.level_two[i]['id'],
                        value: code,
                        label: name,
                    })
                    if (res.data.data.level_two[i]['level1_id'] === that.form.level_one) {
                        that.majors.push(res.data.data.level_two[i])
                    }
                    if (that.form.level_two.indexOf(code) > -1) {
                        // console.log(i)
                        that.tag_index++
                        that.tags[that.tag_index] = {
                            name: name,
                            type: code,
                            class: 'major_two'
                        }
                    }
                }
            }).catch(function (err) {
                console.log(err)
                that.$notify.error({
                    title: '错误',
                    message: err.response.data.msg
                });
            });
        },
        get_school_label_json() {
            let that = this
            axios.get(
                api.search_param.school_label, {}
            ).then(function (res) {
                console.log(res)
                // console.log(that.form)
                that.nature_type = res.data.data[3]
                for (let i in that.nature_type) {
                    if (that.nature_type[i]['status'] !== 1) {
                        delete that.nature_type[i]
                    }
                    if (that.form.nature_type.indexOf(parseInt(i)) > -1) {
                        that.tag_index++
                        that.tags[that.tag_index] = {
                            name: that.nature_type[i]['name'],
                            type: that.nature_type[i]['id'] + '',
                            class: 'nature_type'
                        }
                    }
                }

                that.recruit_type = res.data.data[13]
                for (let i in that.recruit_type) {
                    if (that.recruit_type[i]['status'] !== 1) {
                        delete that.recruit_type[i]
                    }
                    if (that.form.recruit_type.indexOf(parseInt(i)) > -1) {
                        that.tag_index++
                        that.tags[that.tag_index] = {
                            name: that.recruit_type[i]['name'],
                            type: that.recruit_type[i]['id'] + '',
                            class: 'recruit_type'
                        }
                    }
                }

                // 院校层次
                that.school_level = res.data.data[4]
                for (let i in that.school_level) {
                    if (that.school_level[i]['status'] !== 1) {
                        delete that.school_level[i]
                    }
                    if (that.form.school_level.indexOf(parseInt(i)) > -1) {
                        that.tag_index++
                        that.tags[that.tag_index] = {
                            name: that.school_level[i]['name'],
                            type: that.school_level[i]['id'] + '',
                            class: 'school_level'
                        }
                    }
                }

                // 院校类型
                that.educational_type = res.data.data[9]
                for (let i in that.educational_type) {
                    if (that.educational_type[i]['status'] !== 1) {
                        delete that.educational_type[i]
                    }
                    if (that.form.educational_type.indexOf(parseInt(i)) > -1) {
                        that.tag_index++
                        that.tags[that.tag_index] = {
                            name: that.educational_type[i]['name'],
                            type: that.educational_type[i]['id'] + '',
                            class: 'educational_type'
                        }
                    }
                }

            }).catch(function (err) {
                that.$notify.error({
                    title: '错误',
                    message: err.response.data.msg
                });
            });
        },
        get_province_json() {
            let that = this
            axios.get(
                api.search_param.province, {}
            ).then(function (res) {
                that.provinces = res.data.data
                console.log(typeof that.provinces)
                console.log(that.provinces)
                console.log(typeof that.form.provinces)
                console.log(that.form.provinces)
                for (let i in that.provinces) {
                    if (that.form.provinces.indexOf(parseInt(i)) > -1) {
                        that.tag_index++
                        that.tags[that.tag_index] = {
                            name: that.provinces[i]['short'],
                            type: (that.provinces[i]['province_id'] + ''),
                            class: 'provinces'
                        }
                    }
                }
            }).catch(function (err) {
                console.log(err)
                that.$notify.error({
                    title: '错误',
                    message: err.response.data.msg
                });
            });
        },
        open_vip() {
            this.$router.push('/goods')
        },
        member_page() {
            this.send_collection(1)
            this.$router.push('/home_page')
        },
        chose_change(tab, event) {
            this.loading = ElLoading.service({
                lock: true,
                text: 'Loading',
                background: 'rgba(0, 0, 0, 0.7)',
            })
            if (tab.props.name === 'major') {
                this.major_button_press()
            } else {
                this.school_button_press()
            }
            this.form.score_change = 0
            this.form.page = 1
        },
        edit_info_click() {
            this.show_edit_info++
        },
        continue_favorite() {
            this.favorite_plan(this.favorite_index, this.favorite_list, this.favorite_type)
        },
        no_match_major() {
            ElMessage.error('选科不匹配')
        },
        favorite(index, list, type = 1) {
            if (this.vip_level < 60) {
                this.favorite_need_vip = true
                return false;
            }
            if (list.collection === 1) {
                this.un_favorite(index, list, type)
            } else {
                if (list.major_remind_desc !== null && list.major_remind_desc.length > 0) {
                    this.favorite_index = index
                    this.favorite_list = list
                    this.favorite_type = type
                    this.favorite_status = true
                    this.major_remind_list = list.major_remind_desc
                } else {
                    this.major_remind_list = []
                    this.favorite_plan(index, list, type)
                }
            }
        },
        favorite_plan(index, list, type) {
            if (this.collection_total >= 300) {
                this.$notify.error({
                    title: '操作提醒',
                    message: '当前已选择: ' + this.collection_total + ',最大支持300条',
                    offset: 50,
                });
                return false
            }

            let that = this
            axios.post(
                api.user.favorite, {favorite_id: list.id, type: 1}
            ).then(function (res) {
                if (res.data.code === 10000) {
                    that.collection_total = res.data.data.sum
                    that.favorite_status = false
                    if (type === 1) {
                        that.tableData[index]['collection'] = 1
                    } else {
                        that.major_list[list['school_id']][index]['collection'] = 1
                    }

                    if (that.form.score_range === 1) {
                        that.impulse = that.impulse + 1
                    } else if (that.form.score_range === 2) {
                        that.stability = that.stability + 1
                    } else if (that.form.score_range === 3) {
                        that.guarantee = that.guarantee + 1
                    }

                    that.$notify({
                        title: '成功',
                        message: '当前已选择: ' + that.collection_total + ',最大支持300条',
                        type: 'success',
                        offset: 50,
                    });
                } else {
                    that.$notify.error({
                        title: '错误',
                        message: res.data.data.msg
                    });
                }
            }).catch(function (err) {
                console.log(err)
                that.$notify.error({
                    title: '错误',
                    message: err.response.data.msg
                });
            });
        },
        un_favorite(index, list, type = 1) {
            let that = this
            axios.post(
                api.user.un_favorite, {favorite_id: list.id, type: 1}
            ).then(function (res) {
                if (res.data.code === 10000) {
                    that.collection_total = res.data.data.sum
                    if (that.form.score_range === 1) {
                        that.impulse = that.impulse > 0 ? that.impulse - 1 : 0
                    } else if (that.form.score_range === 2) {
                        that.stability = that.stability > 0 ? that.stability - 1 : 0
                    } else if (that.form.score_range === 3) {
                        that.guarantee = that.guarantee > 0 ? that.guarantee - 1 : 0
                    }

                    if (type === 1) {
                        that.tableData[index]['collection'] = 0
                    } else {
                        that.major_list[list['school_id']][index]['collection'] = 0
                    }

                    that.$notify({
                        title: '成功',
                        message: '当前已选择: ' + that.collection_total + ',最大支持300条',
                        type: 'success',
                        offset: 50,
                    });
                } else {
                    that.$notify.error({
                        title: '错误',
                        message: res.data.data.msg
                    });
                }
            }).catch(function (err) {
                console.log(err)
                that.$notify.error({
                    title: '错误',
                    message: err.response.data.msg
                });
            });
        },
        show_majors(index, row) {
            this.majorsData = []
            this.dialogMajorsVisible = true;
            let param = this.form;
            param['school_id'] = row.school_id
            param['page'] = 1
            param['page_size'] = row.count > 10 ? row.count : 10
            let data = http.post(api.match.majors, param)
            data.then(data => {
                this.majorsData = data.data.data
            })
        },
        tags_clear() {
            this.tags = []
        },
        tag_click(tag) {
            console.log(tag)
            console.log(this.tags)
            console.log(this.form)
            for (let i in this.tags) {
                if (this.tags[i]['class'] === tag.class && this.tags[i]['type'] == tag.type) {
                    delete this.tags[i]
                    break
                }
            }

            switch (tag['class']) {
                case 'provinces':
                    for (let i in this.form.provinces) {
                        if (this.form.provinces[i] == tag.type) {
                            this.form.provinces.splice(i, 1);
                            break
                        }
                    }
                    break;
                case 'educational_type':
                    for (let i in this.form.educational_type) {
                        if (this.form.educational_type[i] == tag.type) {
                            this.form.educational_type.splice(i, 1);
                            break
                        }
                    }
                    break;
                case 'school_level':
                    for (let i in this.form.school_level) {
                        if (this.form.school_level[i] == tag.type) {
                            this.form.school_level.splice(i, 1);
                            break
                        }
                    }
                    break;
                case 'major_two':
                    for (let i in this.form.level_two) {
                        if (this.form.level_two[i] == tag.type) {
                            this.form.level_two.splice(i, 1);
                            break
                        }
                    }
                    break;
                case 'major_three':
                    for (let i in this.form.follow_major) {
                        if (this.form.follow_major[i] == tag.type) {
                            this.form.follow_major.splice(i, 1);
                            break
                        }
                    }
                    break;
                case 'school_list':
                    for (let i in this.form.school_ids) {
                        if (this.form.school_ids[i] == tag.type) {
                            if (this.form.school_ids.length <= 1) {
                                this.form.school_ids = []
                            } else {
                                this.form.school_ids.splice(i, 1);
                            }
                            break
                        }
                    }
                    break;
                case 'nature_type':
                    for (let i in this.form.nature_type) {
                        if (this.form.nature_type[i] == tag.type) {
                            this.form.nature_type.splice(i, 1);
                            break
                        }
                    }
                    break;
                case 'recruit_type':
                    for (let i in this.form.recruit_type) {
                        if (this.form.recruit_type[i] == tag.type) {
                            this.form.recruit_type.splice(i, 1);
                            break
                        }
                    }
                    break;
                default:
                    console.log(tag)
                    break
            }

            this.send_collection(1, 1, 1)
        },
        province_change(e) {
            console.log(e)
            console.log(this.form.provinces)
            let new_obj = e.concat(this.old_provinces).filter(v => e.includes(v) && !this.old_provinces.includes(v))
            console.log(new_obj)
            if (new_obj.length > 0) {
                this.tag_index++
                let new_index = new_obj[0]
                this.tags[this.tag_index] = {
                    name: this.provinces[new_index]['short'],
                    type: this.provinces[new_index]['province_id'],
                    class: 'provinces'
                }
            }

            let del_obj = this.old_provinces.concat(e).filter(v => !e.includes(v) && this.old_provinces.includes(v))
            console.log(del_obj)
            if (del_obj.length > 0) {
                let del_index = del_obj[0]
                for (let i in this.tags) {
                    if (this.tags[i]['class'] === 'provinces' && this.tags[i]['type'] == del_index) {
                        delete this.tags[i]
                        break
                    }
                }
            }

            this.old_provinces = e

            this.send_collection(1, 1, 1)
        },
        delete_tag(tag_name, class_name, type_id) {
            for (let i in this.tags) {
                if (this.tags[i]['name'] === tag_name && this.tags[i]['class'] === class_name && parseInt(this.tags[i][
                    'type'
                    ]) === parseInt(type_id)) {
                    delete this.tags[i]
                    break
                }
            }
        },
        type_change(e) {
            console.log(e)
            console.log(this.tags)
            console.log(this.educational_type)
            console.log(this.form)
            let new_obj = e.concat(this.old_educational_type).filter(v => e.includes(v) && !this.old_educational_type
                .includes(v))
            console.log(new_obj)
            if (new_obj.length > 0) {
                this.tag_index++
                let new_index = new_obj[0]
                this.tags[this.tag_index] = {
                    name: this.educational_type[new_index]['name'],
                    type: this.educational_type[new_index]['id'],
                    class: 'educational_type'
                }
            }

            let del_obj = this.old_educational_type.concat(e).filter(v => !e.includes(v) && this.old_educational_type
                .includes(v))
            console.log(del_obj)
            if (del_obj.length > 0) {
                let del_index = del_obj[0]
                for (let i in this.tags) {
                    if (this.tags[i]['class'] === 'educational_type' && this.tags[i]['type'] == del_index) {
                        delete this.tags[i]
                        break
                    }
                }
            }

            this.old_educational_type = e

            this.send_collection(1, 1, 1)
        },
        nature_type_change(e) {
            //console.log(e)
            let new_obj = e.concat(this.old_nature_type).filter(v => e.includes(v) && !this.old_nature_type.includes(
                v))
            if (new_obj.length > 0) {
                let new_index = new_obj[0]
                this.tag_index++
                this.tags[this.tag_index] = {
                    name: this.nature_type[new_index]['name'],
                    type: this.nature_type[new_index]['id'],
                    class: 'nature_type'
                }
            }

            let del_obj = this.old_nature_type.concat(e).filter(v => !e.includes(v) && this.old_nature_type.includes(
                v))
            if (del_obj.length > 0) {
                let del_index = del_obj[0]
                for (let i in this.tags) {
                    if (this.tags[i]['class'] === 'nature_type' && this.tags[i]['type'] == del_index) {
                        delete this.tags[i]
                        break
                    }
                }
            }

            this.old_nature_type = e

            this.send_collection(1, 1, 1)
        },
        recruit_type_change(e) {
            let new_obj = e.concat(this.old_recruit_type).filter(v => e.includes(v) && !this.old_recruit_type.includes(
                v))
            if (new_obj.length > 0) {
                let new_index = new_obj[0]
                this.tag_index++
                this.tags[new_index] = {
                    name: this.recruit_type[new_index]['name'],
                    type: this.recruit_type[new_index]['id'],
                    class: 'recruit_type'
                }
            }

            let del_obj = this.old_recruit_type.concat(e).filter(v => !e.includes(v) && this.old_recruit_type.includes(
                v))
            if (del_obj.length > 0) {
                let del_index = del_obj[0]
                for (let i in this.tags) {
                    if (this.tags[i]['class'] === 'recruit_type' && this.tags[i]['type'] == del_index) {
                        delete this.tags[i]
                        break
                    }
                }
            }

            this.old_recruit_type = e

            this.send_collection(1, 1, 1)
        },
        level_change(e) {
            let new_obj = e.concat(this.old_school_level).filter(v => e.includes(v) && !this.old_school_level.includes(
                v))
            if (new_obj.length > 0) {
                let new_index = new_obj[0]
                this.tag_index++
                this.tags[this.tag_index] = {
                    name: this.school_level[new_index]['name'],
                    type: this.school_level[new_index]['id'],
                    class: 'school_level'
                }
            }

            let del_obj = this.old_school_level.concat(e).filter(v => !e.includes(v) && this.old_school_level.includes(
                v))
            if (del_obj.length > 0) {
                let del_index = del_obj[0]
                for (let i in this.tags) {
                    if (this.tags[i]['class'] === 'school_level' && this.tags[i]['type'] == del_index) {
                        delete this.tags[i]
                        break
                    }
                }
            }

            this.old_school_level = e

            this.send_collection(1, 1, 1)
        },
        major_two_change(e) {
            console.log(e)
            let new_obj = e.concat(this.old_major_two).filter(v => e.includes(v) && !this.old_major_two.includes(v))
            if (new_obj.length > 0) {
                let new_index = new_obj[0]
                for (let i in this.major_two) {
                    if (new_index == this.major_two[i]['code']) {
                        this.tag_index++
                        this.tags[this.tag_index] = {
                            name: this.major_two[i]['name'],
                            type: this.major_two[i]['code'],
                            class: 'major_two'
                        }
                    }
                }
            }

            let del_obj = this.old_major_two.concat(e).filter(v => !e.includes(v) && this.old_major_two.includes(v))
            if (del_obj.length > 0) {
                let del_index = del_obj[0]
                for (let i in this.tags) {
                    if (this.tags[i]['class'] === 'major_two' && this.tags[i]['type'] == del_index) {
                        delete this.tags[i]
                        break
                    }
                }
            }

            this.old_major_two = e

            this.send_collection(1, 1, 1)
        },
        major_three_list_change(e) {
            console.log(e)
            let new_obj = e.concat(this.old_follow_major).filter(v => e.includes(v) && !this.old_follow_major.includes(
                v))
            console.log(new_obj)
            if (new_obj.length > 0) {
                let new_code = new_obj[0]
                this.tag_index++
                if (this.major_three[new_code] !== undefined) {
                    this.tags[this.tag_index] = {
                        name: this.major_three[new_code]['name'],
                        type: this.major_three[new_code]['code'],
                        class: 'major_three'
                    }
                } else {
                    this.tags[this.tag_index] = {
                        name: this.major_two[new_code]['name'],
                        type: this.major_two[new_code]['code'],
                        class: 'major_two'
                    }
                }
            }

            let del_obj = this.old_follow_major.concat(e).filter(v => !e.includes(v) && this.old_follow_major.includes(
                v))
            console.log(del_obj)
            if (del_obj.length > 0) {
                let del_index = del_obj[0]
                for (let i in this.tags) {
                    if (this.tags[i]['class'] === 'major_three' && this.tags[i]['type'] == del_index) {
                        delete this.tags[i]
                        break
                    }
                }
            }

            this.old_follow_major = e

            this.send_collection(1, 1, 1)
        },
        school_list_change(e) {
            console.log(e)
            console.log(this.form.school_ids)
            let new_obj = e.concat(this.old_school_list).filter(v => e.includes(v) && !this.old_school_list.includes(
                v))
            if (new_obj.length > 0) {
                let new_index = new_obj[0]
                for (let i in this.school_list) {
                    if (new_index == this.school_list[i]['value']) {
                        this.tag_index++
                        this.tags[this.tag_index] = {
                            name: this.school_list[i]['label'],
                            type: this.school_list[i]['value'],
                            class: 'school_list'
                        }
                    }
                }
            }

            let del_obj = this.old_school_list.concat(e).filter(v => !e.includes(v) && this.old_school_list.includes(
                v))
            if (del_obj.length > 0) {
                let del_index = del_obj[0]
                for (let i in this.tags) {
                    if (this.tags[i]['class'] === 'school_list' && this.tags[i]['type'] == del_index) {
                        delete this.tags[i]
                        break
                    }
                }
            }

            this.old_school_list = e

            this.send_collection(1, 1, 1)
        },
        change_status(val) {
            this.form.score_range = val
            this.form.score_change = 0
            this.form.page = 1
            this.change()
            this.send_collection(1, 1)
        },
        score_change(val = 1) {
            if (val === 1) {
                this.form.score_range = 4
            } else {
                this.form.score_range = 7
            }
            this.form.score_change = val
            this.form.page = 1
            console.log(this.form.page)
            this.change()
            this.send_collection(1)
        },
        system_tips() {
            let that = this
            let data = http.get(api.system_tips.list, {
                page: 1,
                step: 10,
                show_all: 1
            })
            data.then(data => {
                console.log(data.data)
                that.system_tips_list = data.data
            })
        },
        change() {
            let that = this
            let obj = {
                ...this.form
            }
            console.log(obj)
            let data = http.post(api.school.brief_list, obj)
            data.then(data => {
                this.loading.close()
                this.form.page = data.data.current_page
                that.currentPage = parseInt(data.data.current_page)
                this.form.total = data.data.total
                this.form.page_size = data.data.per_page
                this.tableData = data.data.data
                this.major_list = data.data.majors

                localStorage.setItem('search_param', JSON.stringify(that.form))
            }).catch(data => {
                this.loading.close()
            })
        },
        getPlanInfo(plan_id = 0, list, major, type) {
            let data = http.get(this.json_api + 'plan/' + plan_id + '.json', {})
            data.then((data) => {
                this.plans[plan_id] = data
                if (type === 1) {
                    this.tableData = list

                    console.log(this.tableData)
                    console.log(this.plans)
                }
                if (type === 2) {
                    this.tableData = list
                    this.major_list = major

                    console.log(this.tableData)
                    console.log(this.plans)
                }
            })
        },
        major_one_change(e) {
            this.majors = []
            for (let i in this.major_two) {
                if (this.major_two[i]['level1_id'] == e) {
                    this.majors.push(this.major_two[i])
                }
            }
        },
        school_input_change(event) {
            console.log(this.form.school_ids)
        },
        handleSizeChange(val) {
            this.form.page_size = val
            this.form.page = 1
            this.change()
            console.log(`每页 ${val} 条`);
        },
        handleCurrentChange(val) {
            this.form.page = val
            this.change()
            console.log(`当前页: ${val}`);
        },
        //行拖拽
        rowDrop() {
            const tbody = document.querySelector('.el-table__body-wrapper tbody')
            const _this = this
            Sortable.create(tbody, {
                onEnd({
                          newIndex,
                          oldIndex
                      }) {
                    const currRow = _this.tableData.splice(oldIndex, 1)[0]
                    _this.tableData.splice(newIndex, 0, currRow)
                }
            })
        },
        handleSelectionChange(val) {
            this.multipleSelection = val;
        },
        clearFilter() {
            this.$refs.filterTable.clearFilter();
        },
        tableRowClassName({
                              row,
                              rowIndex
                          }) {
            if (rowIndex === 1) {
                return 'warning-row';
            } else if (rowIndex === 3) {
                return 'success-row';
            }
            return '';
        },
        handleClick(tab, event) {
            // console.log(tab, event);
        },
        listHandleClick(tab, event) {
            //console.log(tab, event);
            if (tab.props.name === '') {
                return false;
            }
        },
        onSubmit() {
            console.log('submit!');
        },
        schoolAreas() {
            let data = http.get(api.school.area, {})
            data.then(data => {
                console.log(data.data)
                this.provinces = data.data

                for (let i in this.provinces) {
                    if (this.provinces[i]['isShow'] == 1) {
                        this.tags[i] = {
                            name: this.provinces[i]['province'],
                            type: this.provinces[i]['province_id'],
                            class: 'provinces'
                        }
                    }
                }
            })
        },
        schoolLabel() {
            let data = http.get(api.school.label, {})
            data.then(data => {
                console.log(data.data)
                //this.educational_type = data.data.educational_type

                //this.recruit_type = data.data.recruit_type
                //this.school_level = data.data.school_level
                this.level = data.data.school_level
                this.types = data.data.recruit_type

                for (let i in this.educational_type) {
                    if (this.educational_type[i]['isShow'] == 1) {
                        this.tags[i] = {
                            name: this.educational_type[i]['name'],
                            type: this.educational_type[i]['id'],
                            class: 'educational_type'
                        }
                    }
                }

                for (let i in this.level) {
                    if (this.level[i]['isShow'] == 1) {
                        this.tags[i] = {
                            name: this.level[i]['name'],
                            type: this.level[i]['id'],
                            class: 'school_level'
                        }
                    }
                }

                for (let i in this.nature_type) {
                    if (this.nature_type[i]['isShow'] == 1) {
                        this.tags[i] = {
                            name: this.nature_type[i]['name'],
                            type: this.nature_type[i]['id'],
                            class: 'nature_type'
                        }
                    }
                }

                for (let i in this.recruit_type) {
                    if (this.recruit_type[i]['isShow'] == 1) {
                        this.tags[i] = {
                            name: this.recruit_type[i]['name'],
                            type: this.recruit_type[i]['id'],
                            class: 'recruit_type'
                        }
                    }
                }

            })
        },
        schoolType() {
            let data = http.get(api.school.type, {})
            data.then(data => {
                this.types = data.data
            })
        },
        schoolLevel() {
            let that = this
            let data = http.get(api.school.level, {})
            data.then(data => {
                that.level = data.data
            })
        },
        school() {
            let that = this
            let data = http.get(api.school.list, {})
            data.then(data => {
                console.log(data.data)
                that.schools = data.data
                that.school_list = Array.from({
                    length: that.schools.length
                }).map((_, idx) => ({
                    value: `${that.schools[idx]['id']}`,
                    label: `${that.schools[idx]['name']}`,
                }))

                for (let i in that.schools) {
                    if (that.schools[i]['isShow'] == 1) {
                        that.tags[i] = {
                            name: this.schools[i]['name'],
                            type: this.schools[i]['id'],
                            class: 'school_list'
                        }
                    }
                }
            })
        },
        majorCode() {
            let that = this
            let data = http.get(api.major.list, {})
            data.then(data => {
                console.log(data)
                that.major_one = data.data.level_one
                that.major_two = data.data.level_two
                that.major_three = data.data.level_three
                that.major_three_list = Array.from({
                    length: that.major_three.length
                }).map((_, idx) => ({
                    key: `${that.major_three[idx]['id']}`,
                    value: `${that.major_three[idx]['code']}`,
                    label: `${that.major_three[idx]['name']}`,
                }))

                if (that.form.level_one > 0) {
                    that.majors = that.major_two[that.form.level_one]
                }

                for (let i in that.major_two) {
                    for (let j in that.major_two[i]) {
                        if (that.major_two[i][j]['isShow'] == 1) {
                            that.tags[i] = {
                                name: that.major_two[i][j]['name'],
                                type: that.major_two[i][j]['code'],
                                class: 'major_two'
                            }
                        }
                    }
                }

                for (let i in that.major_three) {
                    if (that.major_three[i]['isShow']) {
                        this.tags[i] = {
                            name: that.major_three[i]['name'],
                            type: that.major_three[i]['code'],
                            class: 'major_three'
                        }
                    }
                }

            })
        },
        collect() {
            let data = http.get(api.algorithm.collect, {})
            data.then(data => {
                this.score = data.data.score
                this.all_num = data.data.all_num
                this.top_num = data.data.top_num
                this.top = data.data.top
                this.middle_num = data.data.middle_num
                this.bottom_num = data.data.bottom_num
                this.bottom = data.data.bottom
                this.label_num = data.data.label_num

                let score = data.data.score
                let top = data.data.top
                let bottom = data.data.bottom

                this.marks = {
                    top: top + '',
                    score: {
                        style: {
                            color: '#1989FA'
                        },
                        label: score + ''
                    },
                    bottom: bottom + '',
                }

                let legend_data = Object.keys(this.label_num);
                let series_data = [{
                    value: this.top_num,
                    name: '冲刺'
                },
                    {
                        value: this.middle_num,
                        name: '稳妥',
                        selected: true
                    },
                    {
                        value: this.bottom_num,
                        name: '保底'
                    }
                ]
                let info = []
                for (let i in this.label_num) {
                    info.push({
                        'name': i,
                        'value': this.label_num[i]
                    })
                }

                let label_num_data = info
                let dom = document.getElementById('myChart')

                if (dom != null) {
                    let myChart = echarts.init(dom);
                    // 绘制图表
                    myChart.setOption({
                        tooltip: {
                            trigger: 'item',
                            formatter: '{a} <br/>{b}: {c} ({d}%)'
                        },
                        legend: {
                            data: legend_data
                        },
                        series: [{
                            name: '统计',
                            type: 'pie',
                            selectedMode: 'single',
                            radius: [0, '30%'],
                            label: {
                                formatter: '{b}: {c}',
                                position: 'inner',
                                fontSize: 14,
                            },
                            labelLine: {
                                show: false
                            },
                            data: series_data
                        },
                            {
                                name: '学校数量',
                                type: 'pie',
                                radius: ['45%', '60%'],
                                labelLine: {
                                    length: 30,
                                },
                                label: {
                                    formatter: ' {b|{b}}:{c} ',
                                    backgroundColor: '#F6F8FC',
                                    borderColor: '#8C8D8E',
                                    borderWidth: 1,
                                    borderRadius: 4,

                                    rich: {
                                        a: {
                                            color: '#6E7079',
                                            lineHeight: 22,
                                            align: 'center'
                                        },
                                        hr: {
                                            borderColor: '#8C8D8E',
                                            width: '100%',
                                            borderWidth: 1,
                                            height: 0
                                        },
                                        b: {
                                            color: '#4C5058',
                                            fontSize: 14,
                                            fontWeight: 'bold',
                                            lineHeight: 33
                                        },
                                        per: {
                                            color: '#fff',
                                            backgroundColor: '#4C5058',
                                            padding: [3, 4],
                                            borderRadius: 4
                                        }
                                    }
                                },
                                data: label_num_data
                            }
                        ]
                    });
                    window.onresize = () => {
                        myChart.resize()
                    }
                }
            })
        },
        school_button_press(e) {
            this.form.search_type = 2
            this.search_type = 'school'
            this.school_first = true
            this.major_first = false
            this.change()
        },
        school_info(school_id, school_name) {
            let page = this.$router.resolve({
                path: '/school_info',
                query: {
                    school_id: school_id,
                    school_name: school_name,
                    from: 1
                }
            })

            window.open(page.href, '_blank')
        },
        school_map(school_name) {
            //this.$router.push({path: '/school_map', query: {school_name: school_name}})

            const routeData = this.$router.resolve({path: '/school_map', query: {school_name: school_name}})
            window.open(routeData.href, '_blank')
        },
        major_button_press(e) {
            this.form.search_type = 1
            this.search_type = 'major'
            this.school_first = false
            this.major_first = true
            this.change()
        },
        sign_out() {
            localStorage.clear()
            this.$router.push('/')
        },
        approval_report() {
            if (this.collection_total === 0) {
                ElMessageBox.alert('请先选择志愿志愿，然后在进行提交', '提醒', {
                    confirmButtonText: '好的',
                    callback: () => {
                    },
                })
                return false
            }
            this.rank_status = false

            let that = this
            axios.get(
                api.user.send_2_instructor, {
                    params: {
                        type: 2
                    }
                }
            ).then(function (res) {
                console.log(res)
                console.log(res.data.code)
                if (res.data.code === 10000) {
                    that.submit_times--
                    ElNotification({
                        title: '操作成功',
                        message: '您选择的志愿已经提交审核',
                        duration: 0,
                        offset: 150,
                    })
                    that.$router.push('/home_page')
                } else {
                    ElNotification({
                        title: '操作提醒',
                        message: res.data.data.join(','),
                        duration: 0,
                        offset: 150,
                    })
                }
            }).catch(function (err) {
                that.$notify.error({
                    title: '错误',
                    message: err.response.data.msg
                });
            });
        },
    }
}
</script>
<style>
.el-tabs__item {
    color: #000000;
}

.el-tabs__item.is-active {
    color: #C23B1E;
}

.el-tabs__active-bar {
    background-color: #C23B1E;
}

.el-button--primary {
    --el-button-background-color: #C23B1E;
}

.el-button--primary:focus, .el-button--primary:hover {
    background-color: #C23B1E;
    color: #FFFFFF;
    border: 0px #FFFFFF;
}

.el-button:focus, .el-button:hover {
    background-color: #C23B1E;
    color: #FFFFFF;
    border: 0px #FFFFFF;
}

.el-button {
    border: 1px #999999 solid;
}
</style>
<style scoped="scoped">
* {
    font-family: 'Liberation Sans', 'Helvetica Neue', Helvetica, 'PingFang SC', 'Hiragino Sans GB',
    'Microsoft YaHei', '微软雅黑', Arial, sans-serif;
}

.container {
    background-color: #FFFFFF;
    width: 1200px;
    margin: 70px auto 0;
}

#top_con {
    position: sticky;
    top: 0px;
    background-color: #ffffff;
    z-index: 99;

}

#top_con2 {
    position: sticky;
    top: 76px;
    border: 1px solid #FFFFFF;
    background-color: #ffffff;
    z-index: 99;
    box-shadow: 0px 4px 5px #000000;

}

.box-card {
    margin-bottom: 8px;
    padding: 5px;
}

.box-card >>> .el-card__body {
    padding: 0;
}

.box-card >>> .el-card__header {
    padding: 0;
}

.major_sprint {
    display: inline-block;
    border-top: 60px solid #F3AB50;
    border-right: 18px solid transparent;
    height: 0;
    width: 40px;
}

.major_floors {
    display: inline-block;
    border-top: 60px solid #24C599;
    border-right: 18px solid transparent;
    height: 0;
    width: 40px;
}

.major_stable {
    display: inline-block;
    border-top: 60px solid #4D94F1;
    border-right: 18px solid transparent;
    height: 0;
    width: 40px;
}

.major_info_desc {
    background-color: #F8F8F8;
    border-radius: 5px;
    margin-top: 10px;
    height: 30px;
    line-height: 30px;
    text-align: center;
    font-size: 12px;
}

.major_remarks {
    background-color: #F8F8F8;
    border-radius: 5px;
    margin-top: 10px;
    min-height: 30px;
    line-height: 30px;
    text-align: left;
    font-size: 12px;
}

.school_info {
    margin-top: 30px;
    float: left;
    height: 285px;
    width: 40%;
    border-right: 1px #EBEBEB solid;
}

.majors_list {
    float: right;
    margin-top: 30px;
    height: 285px;
    width: 57%;
    overflow-y: scroll;
    scrollbar-color: #DBDBDB #F8F8F8;
    /* 滑块颜色  滚动条背景颜色 */
    scrollbar-width: thin;
    /* 滚动条宽度有三种：thin、auto、none */
}

.major_list {
    float: left;
    margin-top: 30px;
    margin-bottom: 20px;
    padding-left: 20px;
    width: 57%;
    height: 230px;
    overflow-y: auto;
}

/*.majors_list::-webkit-scrollbar {*/
/*    !*滚动条整体样式*!*/
/*    width: 5px; !*高宽分别对应横竖滚动条的尺寸*!*/
/*    height: 1px;*/
/*}*/

/*.majors_list::-webkit-scrollbar-thumb {*/
/*    !*滚动条里面小方块*!*/
/*    border-radius: 10px;*/
/*    background-color: skyblue;*/
/*    background-image: -webkit-linear-gradient(#e70909, #DBDBDB);*/
/*}*/

/*.majors_list::-webkit-scrollbar-track {*/
/*    !*滚动条里面轨道*!*/
/*    box-shadow: inset 0 0 5px rgba(248, 248, 248, 1);*/
/*    background: #000000;*/
/*    border-radius: 10px;*/
/*}*/

.school_tag {
    text-align: left;
    margin-top: 10px;
}

.school_tag >>> .el-tag--light {
    margin-left: 0px;
    margin-right: 5px;
    margin-bottom: 5px;
}

.recruit_tag >>> .el-tag--light {
    margin-left: 0px;
    margin-right: 5px;
    margin-bottom: 5px;
}


.el-checkbox >>> .el-checkbox__input {
    display: none;
}

.el-form-item >>> .el-form-item__label {
    line-height: 30px;
}

.el-radio-group >>> .el-radio__label {
    line-height: 30px;
}

.el-checkbox-group >>> .is-checked {
    background-color: #C23B1E;
    border-radius: 4px;
}

.el-radio-group >>> .is-checked {
    background-color: #C23B1E;
    border-radius: 4px;
}

.el-radio-group >>> .el-radio__label {
    color: #999999;
    padding: 8px;
    text-align: center;
}

.el-radio-group >>> .is-checked .el-radio__label {
    color: #FFFFFF;
    padding: 8px;
    text-align: center;
}

.el-checkbox-group >>> .el-checkbox__label {
    color: #999999;
    padding-left: 8px;
    padding-right: 8px;
    text-align: center;
}

.el-checkbox-group >>> .is-checked .el-checkbox__label {
    color: #FFFFFF;
    padding-left: 8px;
    padding-right: 8px;
    text-align: center;
}

.el-form-item >>> .el-form-item__content {
    line-height: 30px;
}

.el-radio >>> .el-radio__input {
    display: none;
}

.el-tag >>> .el-tag__close {
    color: #333333
}

.selected {
    color: #d01414;
    font-size: 24px;
    height: 60px;
    line-height: 60px;
    cursor: pointer
}

.unselected {
    color: #919090;
    font-size: 20px;
    cursor: pointer
}

.collection {
    height: 60px;
    width: 100%;
    background-color: #C23B1E;
}

.un_collection {
    height: 60px;
    width: 100%;
    background-color: #F2F2F2;
}

.major_collection {
    background-color: #C23B1E;
    color: #F2F2F2;
}

.major_un_collection {
    background-color: #F2F2F2;
}

.collection_new {
    color: #F2F2F2;
    font-size: 16px;
}

.un_collection_new {
    color: #e8320a;
    font-size: 16px;
}

.collection_major {
    font-size: 20px;
    margin-right: 10px;
    color: #F2F2F2;
}

.un_collection_major {
    font-size: 20px;
    margin-right: 10px;
    color: #333333;
}

a {
    color: #666666;
    text-decoration: none;
}

a:link {
    text-decoration: none;
}

a:visited {
    text-decoration: none;
}

a:hover {
    text-decoration: none;
}

a:active {
    text-decoration: none;
}

a:focus {
    text-decoration: none;
}

#collection_list {
    position: fixed;
    bottom: 0;
    width: 100%;
    background-color: white;
}

.collection_info {
    box-shadow: 0 0 15px #A8ABB2;
    display: flex;
    margin: 0 auto;
}

.show_detail {
    padding: 5px;
    margin: 5px 20px;
    border-radius: 5px;
}

.submit_detail {
    cursor: pointer;
    background-color: #C23B1E;
    color: white;
    padding: 5px 10px;
    margin: 5px 20px;
    border-radius: 5px;
}

.show_collection {
    cursor: pointer;
    background-color: #C23B1E;
    color: white;
    padding: 5px 10px;
    margin: 5px 20px;
    border-radius: 5px;
}

.no_show_collection {
    cursor: pointer;
    background-color: #999999;
    color: white;
    padding: 5px 10px;
    margin: 5px 20px;
    border-radius: 5px;
}
</style>
<style>
.demo-tabs .el-tabs__item {
    color: #666666;
    border: 0;
    text-align: center;
    padding: 0;
    width: 120px;
    font-size: 16px;
}

.demo-tabs .is-active {
    color: #ffffff;
    background-color: #ba0806;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    text-align: center;
    width: 120px;
    padding: 0;
    font-size: 16px;
}

.demo-tabs .el-tabs__active-bar {
    background-color: #ba0806;
    width: 120px;
}

.demo-tabs .el-tabs__nav-wrap::after {
    background-color: #ba0806;
}
</style>

