<template>
    <Header chose_index="11"></Header>
    <article class="container">
        <el-tabs v-model="major_level" class="demo-tabs" @tab-click="handleClick">
            <el-tab-pane label="本科" name="本科">
                <div style="display: flex;align-items: flex-start;justify-content: flex-start;">
                    <div class="left_content">
                        <el-tree :data="list" :props="defaultProps" @node-click="handleNodeClick"/>
                    </div>
                    <div class="right_content">
                        <div style="margin-bottom: 20px;position: relative;">
                            <div class="title_info">{{ chose_major_name }}</div>
                            <div class="introduce">
                                <div class="introduce_content">
                                    <div :class="major_info_btn === 'info' ?'ming_2':'no_chose_btn'"
                                         style="display: inline-block;margin-right: 20px;"
                                         @click="major_info_change('info')">简介
                                    </div>
                                    <!-- <div :class="major_info_btn === 'jobs' ?'ming_2':'no_chose_btn'" style="display: inline-block;margin-right: 20px;" @click="major_info_change('jobs')">就业</div>-->
                                    <div :class="major_info_btn === 'schools' ?'ming_2':'no_chose_btn'"
                                         style="display: inline-block;"
                                         @click="major_info_change('schools')">
                                        院校
                                    </div>
                                </div>
                                <div v-if="major_info_btn === 'info'">
                                    <div class="ming_2">培养目标</div>
                                    <div class="ming_2_content">
                                        <div v-html="major_info.info.introduction"></div>
                                    </div>
                                    <div class="ming_2">选科建议</div>
                                    <div class="ming_2_content">
                                        <div v-html="major_info.job_suggest"></div>
                                    </div>
                                    <div class="ming_2">发展前景</div>
                                    <div class="ming_2_content">
                                        <div v-html="major_info.job_orientation"></div>
                                    </div>
                                    <div class="ming_2">核心课程</div>
                                    <div class="ming_2_content">
                                        <div v-html="major_info.info.master"></div>
                                    </div>
                                    <div class="ming_2">男女比例</div>
                                    <div class="ming_2_content"
                                         style="display: flex;align-items: center;justify-content: center;">
                                        <span>男: {{ sex_ratio[0] }}% </span>
                                        <el-slider
                                            style="display: inline-block;width:500px;text-indent: 0;margin:0 10px;"
                                            v-model="major_info.info.sex_rate" :marks="major_info.info.sex_rate"/>
                                        <span style="text-align: left;text-indent: 0;">女: {{ sex_ratio[1] }}% </span>
                                    </div>
                                    <!--                            <div class="ming_2">发展方向</div>-->
                                    <!--                            <div class="ming_2_content">-->
                                    <!--                                <el-tag type="danger" style="text-indent: 0;margin-right: 20px;"-->
                                    <!--                                        v-if="major_info.major.postgraduate == 1">研究生-->
                                    <!--                                </el-tag>-->
                                    <!--                                <el-tag type="success" style="text-indent: 0;"-->
                                    <!--                                        v-if="major_info.major.civil_service == 1">公务员-->
                                    <!--                                </el-tag>-->
                                    <!--                            </div>-->
                                    <!--                            <div class="ming_2">开设课程</div>-->
                                    <!--                            <div class="ming_2_content">-->
                                    <!--                                <el-tag v-for="item in major_info.subjects"-->
                                    <!--                                        style="text-align: center;margin: 5px;text-indent: 0;"-->
                                    <!--                                        type="danger"-->
                                    <!--                                        size="small"-->
                                    <!--                                        effect="light"-->
                                    <!--                                        round>{{ item }}-->
                                    <!--                                </el-tag>-->
                                    <!--                            </div>-->
                                </div>
                                <div v-else-if="major_info_btn === 'jobs'">
                                    <div class="ming_2">岗位去向</div>
                                    <div class="ming_2_content">
                                        <div style="width: 850px;height: 500px;" id="major_detail"></div>
                                    </div>
                                    <div class="ming_2">行业分布</div>
                                    <div class="ming_2_content">
                                        <el-tag v-for="item in major_info.trade"
                                                style="text-align: center;margin: 5px;text-indent: 0;"
                                                type="danger"
                                                size="small"
                                                effect="light"
                                                round>{{ item.trade_name }}:{{ item.trade_rate }}
                                        </el-tag>
                                    </div>
                                    <div class="ming_2">地区分布</div>
                                    <div class="ming_2_content">
                                        <div style="width: 850px;height: 400px;" id="major_area"></div>
                                    </div>
                                </div>
                                <div v-else>
                                    <div class="ming_2">学校列表</div>
                                    <div class="ming_2_content">
                                        <div class="school_info" v-for="(item, index) in schools">
                                            <img style="width: 80px;height: 80px;margin-left: 10px;"
                                                 @click="school_info(item.id, item.name)"
                                                 :src="item['img_url'] + item['logo']"/>
                                            <div style="text-align: left;text-indent:0;margin-left:10px;width: 700px;">
                                                <div style="font-size: 14px;font-weight: bold;"
                                                     @click="school_info(item.id, item.name)">
                                                    {{ item.name }}
                                                    <span v-if="item.level.length > 0">[{{ item.level }}]</span>
                                                    <span v-if="item['subject_attribute'].length > 0">({{
                                                            item['subject_attribute']
                                                        }})</span>
                                                </div>
                                                <div>
                                                    <el-tag v-for="info in item.label"
                                                            style="text-align: center;margin: 3px 3px 3px 0;text-indent: 0;height:20px;line-height: 20px;"
                                                            type="danger"
                                                            size="small"
                                                            effect="light"
                                                            round>{{ info }}
                                                    </el-tag>
                                                </div>
                                                <div
                                                    style="margin-top:3px;font-size: 12px;width:100%;overflow:hidden;line-height: 120%;">
                                                    {{ item.address }}
                                                </div>
                                            </div>
                                            <div style="height:85px;line-height:85px;width:85px;margin-right: 10px;">
                                                <el-button v-if="item.collection == 1"
                                                           size="small"
                                                           style="padding: 0 10px;background-color: #ba0806;border: 1px #ba0806 solid; color: white;"
                                                           type="danger"
                                                           @click="collection(item.id, index, 'd')" plain>
                                                    已选择
                                                </el-button>
                                                <el-button v-else
                                                           style="padding: 0 10px;border: 1px #ba0806 solid; color: #ba0806;"
                                                           type="text"
                                                           size="small"
                                                           @click="collection(item.id, index, 'c')" plain>
                                                    选择
                                                </el-button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </el-tab-pane>
            <el-tab-pane label="专科（高职）" name="专科">
                <div style="display: flex;align-items: flex-start;justify-content: flex-start;">
                    <div class="left_content">
                        <el-tree :data="junior_major_list" :props="defaultProps" @node-click="handleJuniorNodeClick"/>
                    </div>
                    <div class="right_content">
                        <div style="margin-bottom: 20px;position: relative;">
                            <div class="title_info">{{ chose_junior_major_name }}</div>
                            <div class="introduce">
                                <div class="introduce_content" style="display: none;">
                                    <div :class="major_info_btn === 'info' ?'ming_2':'no_chose_btn'"
                                         style="display: inline-block;margin-right: 20px;"
                                         @click="major_info_change('info')">简介
                                    </div>
                                    <!-- <div :class="major_info_btn === 'jobs' ?'ming_2':'no_chose_btn'" style="display: inline-block;margin-right: 20px;" @click="major_info_change('jobs')">就业</div>-->
                                    <div :class="major_info_btn === 'schools' ?'ming_2':'no_chose_btn'"
                                         style="display: inline-block;"
                                         @click="major_info_change('schools')">
                                        院校
                                    </div>
                                </div>
                                <div v-if="junior_major_info_btn === 'info'">
                                    <div class="ming_2"
                                         v-if="junior_major.major_target && junior_major.major_target.length > 0">培养目标
                                    </div>
                                    <div class="ming_2_content"
                                         v-if="junior_major.major_target && junior_major.major_target.length > 0">
                                        <div v-html="junior_major.major_target"></div>
                                    </div>
                                    <div class="ming_2"
                                         v-if="junior_major.job_orientation && junior_major.job_orientation.length > 0">
                                        就业方向
                                    </div>
                                    <div class="ming_2_content"
                                         v-if="junior_major.job_orientation && junior_major.job_orientation.length > 0">
                                        <div v-html="junior_major.job_orientation"></div>
                                    </div>
                                    <div class="ming_2"
                                         v-if="junior_major.major_subject && junior_major.major_subject.length > 0">核心课程
                                    </div>
                                    <div class="ming_2_content"
                                         v-if="junior_major.major_subject && junior_major.major_subject.length > 0">
                                        <div v-html="junior_major.major_subject"></div>
                                    </div>
                                    <div class="ming_2"
                                         v-if="junior_major.major_talents && junior_major.major_talents.length > 0">专业能力
                                    </div>
                                    <div class="ming_2_content"
                                         v-if="junior_major.major_talents && junior_major.major_talents.length > 0">
                                        <div v-html="junior_major.major_talents"></div>
                                    </div>
                                    <div class="ming_2"
                                         v-if="junior_major.major_diploma && junior_major.major_diploma.length > 0">
                                        职业证书举例
                                    </div>
                                    <div class="ming_2_content"
                                         v-if="junior_major.major_diploma && junior_major.major_diploma.length > 0">
                                        <div v-html="junior_major.major_diploma"></div>
                                    </div>
                                    <div class="ming_2"
                                         v-if="junior_major.major_upgrade && junior_major.major_upgrade.length > 0">
                                        接续专业举例
                                    </div>
                                    <div class="ming_2_content"
                                         v-if="junior_major.major_upgrade && junior_major.major_upgrade.length > 0">
                                        <div v-html="junior_major.major_upgrade"></div>
                                    </div>
                                </div>
                                <div v-else-if="junior_major_info_btn === 'jobs'">
                                    <div class="ming_2">岗位去向</div>
                                    <div class="ming_2_content">
                                        <div style="width: 850px;height: 500px;" id="major_detail"></div>
                                    </div>
                                    <div class="ming_2">行业分布</div>
                                    <div class="ming_2_content">
                                        <el-tag v-for="item in major_info.trade"
                                                style="text-align: center;margin: 5px;text-indent: 0;"
                                                type="danger"
                                                size="small"
                                                effect="light"
                                                round>{{ item.trade_name }}:{{ item.trade_rate }}
                                        </el-tag>
                                    </div>
                                    <div class="ming_2">地区分布</div>
                                    <div class="ming_2_content">
                                        <div style="width: 850px;height: 400px;" id="major_area"></div>
                                    </div>
                                </div>
                                <div v-else>
                                    <div class="ming_2">学校列表</div>
                                    <div class="ming_2_content">
                                        <div class="school_info" v-for="(item, index) in schools">
                                            <img style="width: 80px;height: 80px;margin-left: 10px;"
                                                 @click="school_info(item.id, item.name)"
                                                 :src="item['img_url'] + item['logo']"/>
                                            <div style="text-align: left;text-indent:0;margin-left:10px;width: 700px;">
                                                <div style="font-size: 14px;font-weight: bold;"
                                                     @click="school_info(item.id, item.name)">
                                                    {{ item.name }}
                                                    <span v-if="item.level.length > 0">[{{ item.level }}]</span>
                                                    <span v-if="item['subject_attribute'].length > 0">({{
                                                            item['subject_attribute']
                                                        }})</span>
                                                </div>
                                                <div>
                                                    <el-tag v-for="info in item.label"
                                                            style="text-align: center;margin: 3px 3px 3px 0;text-indent: 0;height:20px;line-height: 20px;"
                                                            type="danger"
                                                            size="small"
                                                            effect="light"
                                                            round>{{ info }}
                                                    </el-tag>
                                                </div>
                                                <div
                                                    style="margin-top:3px;font-size: 12px;width:100%;overflow:hidden;line-height: 120%;">
                                                    {{ item.address }}
                                                </div>
                                            </div>
                                            <div style="height:85px;line-height:85px;width:85px;margin-right: 10px;">
                                                <el-button v-if="item.collection == 1"
                                                           size="small"
                                                           style="padding: 0 10px;background-color: #ba0806;border: 1px #ba0806 solid; color: white;"
                                                           type="danger"
                                                           @click="collection(item.id, index, 'd')" plain>
                                                    已选择
                                                </el-button>
                                                <el-button v-else
                                                           style="padding: 0 10px;border: 1px #ba0806 solid; color: #ba0806;"
                                                           type="text"
                                                           size="small"
                                                           @click="collection(item.id, index, 'c')" plain>
                                                    选择
                                                </el-button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </el-tab-pane>
        </el-tabs>
    </article>
    <Footer></Footer>
</template>

<script>
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer'
import axios from "axios";
import api from "@/api";
import * as echarts from 'echarts';

export default {
    name: 'Majors',
    components: {Header, Footer},
    data() {
        return {
            filterText: '',
            defaultProps: {
                children: 'children',
                label: 'label',
            },
            list: [
                {
                    value: '',
                    label: '',
                    children: [],
                },
            ],
            chose_major_name: '哲学',
            chose_junior_major_name: '',
            major_level: '本科',
            major_info_btn: 'info',
            junior_major_info_btn: 'info',
            major_info: {
                area: [],
                detail: [],
                info: [],
                logo: [],
                major: [],
                schools: [],
                subjects: [],
                trade: [],
            },
            sex_ratio: ['0', '0'],
            schools: [],
            junior_major: [],
            junior_major_list: [
                {
                    value: '',
                    label: '',
                    children: [],
                },
            ]
        }
    },
    created() {
        let query = this.$route.query;
        console.log(query)
        if (query.type !== undefined && query.type === 'zhuan') {
            if (query.major_name !== undefined) {
                this.chose_junior_major_name = query.major_name
            }
            if (query.major_code !== undefined) {
                this.get_junior_major(query.major_code)
            } else {
                this.get_junior_major()
            }
            this.get_majors()
            this.major_level = '专科'
        } else {
            if (query.major_name !== undefined) {
                this.chose_major_name = query.major_name
            }
            if (query.major_code !== undefined) {
                this.get_majors(query.major_code)
            } else {
                this.get_majors()
            }
            this.get_junior_major()
            this.major_level = '本科'
        }
    },
    methods: {
        handleClick(e) {
            console.log(e)
        },
        handleNodeClick(e) {
            console.log(e)
            if (typeof e.children === "object" && e.children.length === 0) {
                this.chose_major_name = e.label
                this.major_info_btn = 'info'
                this.get_major_info(e.id, e.value)
            }
        },
        handleJuniorNodeClick(e) {
            console.log(e)
            if (typeof e.children === "object" && e.children.length === 0) {
                this.chose_junior_major_name = e.label
                this.junior_major_info_btn = 'info'
                this.get_junior_major_info(e.id, e.value)
            }
        },
        major_info_change(e) {
            console.log(e)
            let that = this
            this.major_info_btn = e
            if (this.major_info_btn === 'jobs') {
                setTimeout(function () {
                    that.init_major_detail()
                    that.init_major_area()
                }, 300)
            } else if (this.major_info_btn === 'schools') {
                if (this.major_info.schools.length > 0) {
                    this.get_schools()
                }
            }
        },
        get_majors(major_code = '') {
            let that = this
            axios.get(api.open_major.list, {}).then(function (res) {
                console.log(res)
                if (res.data.code === 10000) {
                    that.list = res.data.data

                    if (major_code === '') {
                        let id = that.list[0]['children'][0]['children'][0]['id'] || 0
                        let code = that.list[0]['children'][0]['children'][0]['value'] || ''
                        that.get_major_info(id, code)
                    } else {
                        that.get_major_info(0, major_code)
                    }
                }
            }).catch(function (err) {
            });
        },
        get_junior_major(major_code = '') {
            let that = this
            axios.get(api.junior_major.list, {}).then(function (res) {
                console.log(res)
                if (res.data.code === 10000) {
                    that.junior_major_list = res.data.data
                    console.log(that.junior_major_list)
                    console.log(typeof major_code)
                    console.log(major_code)
                    if (major_code === '') {
                        let id = that.junior_major_list[0]['children'][0]['children'][0]['id'] || 0
                        let code = that.junior_major_list[0]['children'][0]['children'][0]['value'] || ''
                        that.get_junior_major_info(id, code)
                    } else {
                        that.get_junior_major_info(0, major_code)
                    }
                }
            }).catch(function (err) {
            });
        },
        get_schools() {
            let that = this
            axios.post(api.open_major.school_info, {'school_ids': this.major_info.schools}).then(function (res) {
                console.log(res)
                if (res.data.code === 10000) {
                    that.schools = res.data.data
                }
            }).catch(function (err) {
            });
        },
        get_major_info(major_id, major_code) {
            let that = this
            axios.get(api.open_major.major_info, {
                params: {
                    'major_id': major_id,
                    'major_code': major_code
                }
            }).then(function (res) {
                console.log(res)
                if (res.data.code === 10000) {
                    console.log(res.data.data)
                    that.major_info = res.data.data
                    if (that.major_info.info.sex_rate.indexOf(':') > -1) {
                        that.sex_ratio = that.major_info.info.sex_rate.split(':')
                        that.major_info.info.sex_rate = parseInt(that.sex_ratio[0])
                        if (that.sex_ratio.length < 2) {
                            that.sex_ratio[1] = ''
                        }
                    } else {
                        that.major_info.info.sex_rate = 0
                    }

                    console.log(that.major_info.info.sex_rate)
                }
            }).catch(function (err) {
            });
        },
        get_junior_major_info(major_id, major_code) {
            let that = this
            axios.get(api.junior_major.major_info, {
                params: {
                    'major_id': major_id,
                    'major_code': major_code
                }
            }).then(function (res) {
                console.log(res)
                if (res.data.code === 10000) {
                    that.junior_major = res.data.data
                    that.chose_junior_major_name = that.junior_major.major_name
                }
            }).catch(function (err) {
            });
        },
        school_info(school_id, school_name) {
            let page = this.$router.resolve({
                path: '/school_info',
                query: {
                    school_id: school_id,
                    school_name: school_name,
                    from: 1
                }
            })

            window.open(page.href, '_blank')
        },
        collection(id, index, type = 'c') {
            let that = this
            axios.get(api.user.collection_school, {
                params: {
                    'school_id': id,
                    'c_type': type,
                }
            }).then(function (res) {
                console.log(res)
                if (res.data.code === 10000) {
                    that.schools[index]['collection'] = !that.schools[index]['collection']
                    that.$notify({
                        title: '成功',
                        message: '信息录入成功',
                        type: 'success',
                        offset: 50,
                    });
                } else {
                    that.$notify.error({
                        title: '错误',
                        message: res.data.data.msg,
                        offset: 50,
                    });
                }
            }).catch(function (err) {
            });
        },
        init_major_detail() {
            if (this.major_info.detail.length < 1) {
                return false;
            }
            let chartDom = document.getElementById('major_detail');
            let myChart = echarts.init(chartDom);
            let option;

            let detail = [];
            for (let i in this.major_info.detail) {
                detail.push({
                    'name': this.major_info.detail[i]['detail_pos'],
                    'value': +this.major_info.detail[i]['detail_rate']
                })
            }

            option = {
                title: {
                    text: '',
                    subtext: '',
                    left: 'center'
                },
                tooltip: {
                    trigger: 'item'
                },
                legend: {
                    top: '5%',
                    left: 'center'
                },
                series: [
                    {
                        name: '',
                        type: 'pie',
                        radius: ['40%', '70%'],
                        data: detail,
                        emphasis: {
                            itemStyle: {
                                shadowBlur: 10,
                                shadowOffsetX: 0,
                                shadowColor: 'rgba(0, 0, 0, 0.5)'
                            }
                        },
                        itemStyle: {
                            borderRadius: 10,
                            borderColor: '#fff',
                            borderWidth: 2
                        },
                        label: {
                            alignTo: 'edge',
                            formatter: '{name|{b}}\n{time|{c} %}',
                            minMargin: 5,
                            edgeDistance: 10,
                            lineHeight: 15,
                            rich: {
                                time: {
                                    fontSize: 10,
                                    color: '#999'
                                }
                            }
                        },
                    }
                ]
            };

            option && myChart.setOption(option);

        },
        init_major_area() {
            if (this.major_info.area.length < 1) {
                return false;
            }

            let names = [];
            let numbers = [];
            for (let i in this.major_info.area) {
                names.push(this.major_info.area[i]['area_name'])
                numbers.push(this.major_info.area[i]['area_rate'])
            }

            console.log(names)
            console.log(numbers)

            let chartDom = document.getElementById('major_area');
            let myChart = echarts.init(chartDom);
            let option;

            option = {
                xAxis: {
                    type: 'category',
                    data: names,
                    axisLabel: {
                        formatter: '{value}'
                    }
                },
                yAxis: {
                    type: 'value'
                },
                series: [
                    {
                        type: 'bar',
                        data: numbers,
                        itemStyle: {
                            normal: {
                                label: {
                                    show: true,
                                    position: 'top',
                                    textStyle: {
                                        color: '#ba0806',
                                        fontSize: 14
                                    }
                                }
                            }
                        }
                    }
                ]
            };

            option && myChart.setOption(option);
        }
    }
}
</script>
<style scoped>
* {
    font-family: 'Microsoft YaHei', 'Helvetica Neue', Helvetica, 'PingFang SC', 'Hiragino Sans GB',
    '微软雅黑', Arial, sans-serif;
}

.container {
    background-color: #FFFFFF;
    width: 1210px;
    margin: 70px auto 0;
    padding: 0;
}

.left_content {
    width: 280px;
    padding-top: 33px;
}

.right_content {
    margin-left: 10px;
    min-height: 630px;
    width: 900px;
    text-align: center;
}

.title_info {
    position: relative;
    top: 15px;
    margin: 0 auto;
    width: 400px;
    max-width: 600px;
    height: 20%;
    background-color: #ba0806;
    line-height: 34px;
    border-radius: 5px;
    color: white;
    text-align: center;
    letter-spacing: 3px;
    z-index: 99;
}

.introduce {
    width: 900px;
    margin: 0 auto;
    border: 2px dashed tomato;
    border-radius: 5px;
    position: relative;
}

.introduce_content {
    padding: 30px 30px 0 30px;
}

.ming_2 {
    height: 20%;
    background-color: #ba0806;
    line-height: 30px;
    border-radius: 5px;
    color: white;
    text-align: center;
    letter-spacing: 5px;
    width: 200px;
    cursor: pointer;
    margin: 50px auto 10px;
}

.ming_2_content {
    margin: 20px auto;
    padding: 0 20px;
    line-height: 180%;
    font-size: 14px;
    text-indent: 30px;
    color: #666666;
}

.school_info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px #eae9e9 solid;
    box-shadow: 5px 5px 0 0 #f5f3f3;
    border-radius: 5px;
    margin-bottom: 15px;
    height: 100px;
}

.no_chose_btn {
    padding: 0 10px;
    border: 1px #ba0806 solid;
    color: #ba0806;
    height: 20%;
    line-height: 30px;
    border-radius: 5px;
    text-align: center;
    letter-spacing: 5px;
    width: 200px;
    cursor: pointer;
    margin: 50px auto 10px;
}

.left_content >>> .el-tree-node__label {
    font-size: 16px;
}
</style>
<style>
.demo-tabs .el-tabs__item {
    color: #666666;
    border: 0;
    text-align: center;
    padding: 0;
    width: 120px;
    font-size: 16px;
}

.demo-tabs .is-active {
    color: #ffffff;
    background-color: #ba0806;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    text-align: center;
    width: 120px;
    padding: 0;
    font-size: 16px;
}

.demo-tabs .el-tabs__active-bar {
    background-color: #ba0806;
    width: 120px;
}

.demo-tabs .el-tabs__nav-wrap::after {
    background-color: #ba0806;
}
</style>
